import React, { FC, useEffect, useMemo, useState } from "react";
import { Button, ModalBody, ModalCloseButton, ModalFooter, ModalHeader, Text } from "@chakra-ui/react";
import { useLoader } from "@shared/hooks/LoaderHook";
import { FileUpload } from "@shared/components";
import { actions } from "@containers/Cases/store";
import { useDispatch } from "react-redux";
import { updateModal } from "@shared/store/actions";

interface CreateCaseModalProps {
  onClose: () => void;
  onSubmit: (fileBase64: string) => void;
}

const MAX_FILE_SIZE = 15;
export const ACCEPT_FILE_TYPES = ["pdf"];

const CreateCaseModal: FC<CreateCaseModalProps> = ({ onClose, onSubmit }) => {
  const [fileBase64, setFileBase64] = useState<string | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (fileBase64) {
      dispatch(updateModal({ size: undefined }));
    } else {
      dispatch(updateModal({ size: "uploadPopup" }));
    }
  }, [dispatch, fileBase64]);

  const { isLoading } = useLoader({
    name: "createCase",
    actionTypes: useMemo(() => [actions.createCase], []),
  });

  return (
    <>
      <ModalHeader>
        <Text textStyle="modalTitle" mt={2}>
          Upload Presentence Investigation Report
        </Text>
        <ModalCloseButton color="grey.10" />
      </ModalHeader>
      <ModalBody mb={!fileBase64 ? 4 : undefined}>
        <FileUpload types={ACCEPT_FILE_TYPES} maxFileSize={MAX_FILE_SIZE} onUploadFile={setFileBase64} />
      </ModalBody>
      {fileBase64 && (
        <ModalFooter>
          <Button variant="cancel" mr={3} onClick={() => onClose()} isDisabled={isLoading}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            isDisabled={isLoading || !fileBase64}
            isLoading={isLoading}
            onClick={() => (fileBase64 ? onSubmit(fileBase64) : undefined)}
          >
            Save
          </Button>
        </ModalFooter>
      )}
    </>
  );
};

export default CreateCaseModal;
