import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectors, actions } from "@shared/store";
import { MODAL_TYPES, OVERLAY_TYPES } from "@shared/constants";
import { Drawer, DrawerOverlay, DrawerContent, DrawerBody, CloseButton, Flex, Text } from "@chakra-ui/react";
import { OverlaySize } from "@shared/interfaces";
import { CaseFormView } from "@containers/Cases/components/CaseViewContainer";
import { CasePrisonMigrationAnalysisView } from "@containers/Cases/components/CaseViewContainer/CasePrisonMigrationAnalysisView";

import { PdfView } from "../PdfView";

function OverlayWrapper() {
  const dispatch = useDispatch();

  const overlay = useSelector(selectors.getOverlay());

  const hideOverlayAction = useCallback(() => {
    dispatch(actions.hideOverlay());
    if (overlay?.props.onClose) {
      overlay.props.onClose();
    }
  }, [dispatch, overlay]);

  const onCloseOverlay = useCallback(() => {
    if (overlay?.leaveConfirm) {
      dispatch(
        actions.showModal({
          type: MODAL_TYPES.CONFIRM,
          props: {
            heading: "You have unsaved changes",
            content: "Are you sure you want to leave? If you leave this page now your progress will be lost.",
            cancelBtnText: "Cancel",
            successBtnText: "Leave",
            onSuccess: hideOverlayAction,
          },
        }),
      );
      return;
    }

    hideOverlayAction();
  }, [overlay, dispatch, hideOverlayAction]);

  const Component = useMemo(() => {
    if (!overlay) return;
    return overlayStrategy(overlay.type);
  }, [overlay]);

  const overlayTitle = overlay?.title;

  return overlay ? (
    <>
      <Drawer
        isOpen={Boolean(overlay)}
        placement="right"
        onClose={onCloseOverlay}
        closeOnOverlayClick={overlay.closeOnOverlayClick}
      >
        <DrawerOverlay />
        <DrawerContent
          w={overlay?.size === OverlaySize.FULLSCREEN ? "calc(100% - 65px)" : "550px"}
          maxW={overlay?.size === OverlaySize.FULLSCREEN ? "calc(100% - 65px)" : "550px"}
          bg="white"
        >
          <Flex justify={overlayTitle ? "space-between" : "flex-end"} align="center" px={5} py={2.5}>
            {overlayTitle && <Text textStyle="overlayTitle">{overlayTitle}</Text>}
            <CloseButton onClick={hideOverlayAction} />
          </Flex>
          <DrawerBody>{Component ? <Component {...overlay.props} onClose={onCloseOverlay} /> : null}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  ) : null;
}

export default OverlayWrapper;

const overlayStrategy = (type: string) => {
  switch (type) {
    case OVERLAY_TYPES.SHOW_PDF: {
      return PdfView;
    }
    case OVERLAY_TYPES.SHOW_CASE_FORM: {
      return CaseFormView;
    }
    case OVERLAY_TYPES.SHOW_PRISON_MIGRATION_ANALYSIS: {
      return CasePrisonMigrationAnalysisView;
    }
    default: {
      return null;
    }
  }
};
