import React, { FC } from "react";
import { Box, Button } from "@chakra-ui/react";
import { InputSearch } from "@shared/components";
import { CaseListFilter } from "@shared/interfaces";
import { REPORT_STATUS, RoleName } from "@shared/models";
import { RolePermission } from "@shared/components/RolePermission";
import { useSelector } from "react-redux";
import { getProcessingCaseId } from "@containers/Cases/store/selectors";

import CaseListSelectReportStatusFilter from "../CaseListSelectReportStatusFilter/CaseListSelectReportStatusFilter";
import CaseListCreatedByFilter from "../CaseListCreatedByFilter/CaseListCreatedByFilter";

export interface CasesListHeaderProps {
  onChangeSearch: (search: string) => void;
  handleChangeReportStatus: (report_status?: REPORT_STATUS) => void;
  handleSelectUser: (user_id?: number) => void;
  onAddNewCase: () => void;
  filter: CaseListFilter;
  isSomeCasesUploaded: boolean;
}

const CasesListHeader: FC<CasesListHeaderProps> = (props) => {
  const { onChangeSearch, filter, onAddNewCase, handleChangeReportStatus, handleSelectUser, isSomeCasesUploaded } =
    props;
  const processingId = useSelector(getProcessingCaseId());

  return (
    <Box w="100%" display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <Box mr="12px" textStyle="title">
          Cases
        </Box>
      </Box>
      {isSomeCasesUploaded && (
        <Box display="flex" alignItems="center" justifyContent="flex-end" gap="16px">
          <RolePermission allowedRoleNames={[RoleName.admin]}>
            <Box w="232px">
              <CaseListSelectReportStatusFilter handleChangeReportStatus={handleChangeReportStatus} />
            </Box>
            <Box w="232px">
              <CaseListCreatedByFilter onSelectUser={handleSelectUser} />
            </Box>
          </RolePermission>
          <Box w="264px">
            <InputSearch onChangeSearch={onChangeSearch} value={filter.search} />
          </Box>
          <Button isDisabled={!!processingId} variant="primary" w="176px" h="48px" onClick={onAddNewCase}>
            + Add New Case
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default CasesListHeader;
