import React, { FC } from "react";
import { Flex, Image, Text } from "@chakra-ui/react";
import ChatAnswerExample from "@assets/files/images/cases/chatAnswerExample.jpg";

const NoSelectedScore: FC = () => {
  return (
    <Flex alignItems="center" justifyContent="center" flexDirection="column" width="100%" height="80%">
      <Image src={ChatAnswerExample} alt="logo" />
      <Text maxW="332px" pt={3} textStyle="subtitle" color="blue.700" textAlign="center">
        Please click on a score to see the machine reasoning here
      </Text>
    </Flex>
  );
};

export default NoSelectedScore;
