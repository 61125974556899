export const ACCEPT_IMAGE_TYPES = ["jpg", "jpeg", "png"];

export enum MODAL_TYPES {
  INVALID_DOCUMENT = "INVALID_DOCUMENT",
  CONFIRM = "CONFIRM",
  CREATE_CASE = "CREATE_CASE",
  UPLOAD_CASE_SUCCESS = "UPLOAD_CASE_SUCCESS",
  PAYMENT_SUCCESS = "PAYMENT_SUCCESS",
  PAYMENT_ERROR = "PAYMENT_ERROR",
  DELETE_CASE = "DELETE_CASE",
  PREVIEW_CASE = "PREVIEW_CASE",
}

export enum OVERLAY_TYPES {
  SHOW_PDF = "SHOW_PDF",
  SHOW_CASE_FORM = "SHOW_CASE_FORM",
  SHOW_PRISON_MIGRATION_ANALYSIS = "SHOW_PRISON_MIGRATION_ANALYSIS",
}

export const PROCESSING_CASE_ID = "processing_case_id";
export const PROCESSING_CASE_CANCELED = "processing_case_canceled";
