import { AppState } from "@shared/interfaces";
import { createSelector } from "reselect";

const selectUsers = (state: AppState) => state.users;

export const getShortUsersList = () => createSelector(selectUsers, (state) => state.usersShort.rows);
export const getShortUsersTotalCount = () => createSelector(selectUsers, (state) => state.usersShort.totalCount);
export const getShortUsersListFilter = () => createSelector(selectUsers, (state) => state.usersShort.filter);
export const getSummaryUserList = () => createSelector(selectUsers, (state) => state.usersSummary.rows);
export const getSummaryUserTotalCount = () => createSelector(selectUsers, (state) => state.usersSummary.totalCount);
export const getSummaryUsersListFilter = () => createSelector(selectUsers, (state) => state.usersSummary.filter);
