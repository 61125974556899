import { FC } from "react";
import { Flex } from "@chakra-ui/react";

import MachineAnalysisPreviewModalFooterControls, {
  MachineAnalysisPreviewModalFooterControlsProps,
} from "./MachineAnalysisPreviewModalFooterControls";

interface MachineAnalysisPreviewModalFooterProps extends MachineAnalysisPreviewModalFooterControlsProps {
  test?: string;
}

const MachineAnalysisPreviewModalFooter: FC<MachineAnalysisPreviewModalFooterProps> = ({
  onAddPage,
  onSubtractPage,
  maxPage,
  minPage,
  currentPage,
  scale,
  onClose,
  caseObjUpdatedValues,
}) => {
  return (
    <Flex py={3} justify="center">
      <MachineAnalysisPreviewModalFooterControls
        onAddPage={onAddPage}
        onSubtractPage={onSubtractPage}
        maxPage={maxPage}
        minPage={minPage}
        currentPage={currentPage}
        scale={scale}
        onClose={onClose}
        caseObjUpdatedValues={caseObjUpdatedValues}
      />
    </Flex>
  );
};

export default MachineAnalysisPreviewModalFooter;
