import React, { FC } from "react";
import { Flex, Divider, Text, Box } from "@chakra-ui/react";
import { CaseModel } from "@shared/models";
import { FONT_WEIGHT } from "@assets/theme/foundations/textStyle";
import { ArrowForwardIcon } from "@chakra-ui/icons";

import { getCaseTotalScore } from "../../../utils/functions";
import CaseResultValue from "./CaseResultValue";
import CaseResultSection from "./CaseResultSection";

interface CaseResultViewProps {
  caseObj?: CaseModel;
  onSelectScore: (field: string) => void;
}

const CaseResultView: FC<CaseResultViewProps> = ({ caseObj, onSelectScore }) => {
  const handleClickScore = (field: string) => () => onSelectScore(field);

  const totalScore = getCaseTotalScore(caseObj);
  return (
    <Flex w="100%" flexDir="column">
      <CaseResultSection title="Inmate Load">
        <Flex direction="column" gap="16px">
          <CaseResultValue value={caseObj?.usm_number} label="1. Register Number" />
          <Flex gap="20px" wrap="wrap">
            <CaseResultValue
              value={caseObj?.case_person_details?.last_name}
              label="2. Last Name"
              onClickScore={handleClickScore("personal_data")}
            />
            <CaseResultValue
              value={caseObj?.case_person_details?.first_name}
              label="3. First Name"
              onClickScore={handleClickScore("personal_data")}
            />
            <CaseResultValue
              value={caseObj?.case_person_details?.middle_name}
              label="4. Middle"
              onClickScore={handleClickScore("personal_data")}
            />
            <CaseResultValue
              value={caseObj?.case_person_details?.suffix}
              label="5. Suffix"
              onClickScore={handleClickScore("personal_data")}
            />
          </Flex>
          <Flex gap="20px" wrap="wrap">
            <CaseResultValue
              value={caseObj?.case_person_details?.race}
              label="6. Race"
              onClickScore={handleClickScore("personal_data")}
            />
            <CaseResultValue
              value={caseObj?.case_person_details?.sex}
              label="7. Gender"
              onClickScore={handleClickScore("personal_data")}
            />
            <CaseResultValue
              value={caseObj?.case_person_details?.ethnic_origin}
              label="8. Ethnic Origin"
              onClickScore={handleClickScore("personal_data")}
            />
            <CaseResultValue
              value={caseObj?.case_person_details?.date_of_birth}
              label="9. Date of Birth"
              onClickScore={handleClickScore("personal_data")}
            />
          </Flex>
          <CaseResultValue
            label="10. Offense Level"
            value={caseObj?.total_offense_level}
            onClickScore={handleClickScore("total_offense_level")}
          />
          <Flex gap="20px" wrap="wrap">
            <CaseResultValue
              value={caseObj?.fbi_number}
              label="11. FBI Number"
              onClickScore={handleClickScore("personal_data")}
            />
            <CaseResultValue
              value={caseObj?.case_person_details?.ssn}
              label="12. SSN Number"
              onClickScore={handleClickScore("personal_data")}
            />
          </Flex>
          <Flex gap="20px" wrap="wrap">
            <CaseResultValue
              value={caseObj?.case_person_details?.state_of_birth}
              label="13. State of Birth"
              onClickScore={handleClickScore("personal_data")}
            />
            <CaseResultValue
              value={caseObj?.case_person_details?.country_of_birth}
              label="14. Or Country of Birth"
              onClickScore={handleClickScore("personal_data")}
            />
            <CaseResultValue
              value={caseObj?.case_person_details?.citizenship}
              label="15. Citizenship"
              onClickScore={handleClickScore("personal_data")}
            />
          </Flex>
          <CaseResultValue
            value={caseObj?.case_person_details?.street}
            label="16. Address Street"
            onClickScore={handleClickScore("personal_data")}
          />
          <Flex gap="20px" wrap="wrap">
            <CaseResultValue
              value={caseObj?.case_person_details?.city}
              label="17. City"
              onClickScore={handleClickScore("personal_data")}
            />
            <CaseResultValue
              value={caseObj?.case_person_details?.state}
              label="18. State"
              onClickScore={handleClickScore("personal_data")}
            />
            <CaseResultValue
              value={caseObj?.case_person_details?.zip}
              label="19. Zip"
              onClickScore={handleClickScore("personal_data")}
            />
            <CaseResultValue value={caseObj?.foreign_country} label="20. Or Foreign Country" />
          </Flex>
          <Flex gap="20px" wrap="wrap">
            <CaseResultValue
              value={caseObj?.case_person_details?.height}
              label="21. Height"
              onClickScore={handleClickScore("personal_data")}
            />
            <CaseResultValue
              value={caseObj?.case_person_details?.weight}
              label="22. Weight"
              onClickScore={handleClickScore("personal_data")}
            />
            <CaseResultValue
              value={caseObj?.case_person_details?.hair_color}
              label="23. Hair Color"
              onClickScore={handleClickScore("personal_data")}
            />
            <CaseResultValue
              value={caseObj?.case_person_details?.eyes_color}
              label="24. Eye Color"
              onClickScore={handleClickScore("personal_data")}
            />
          </Flex>
          <CaseResultValue value={caseObj?.ars_assignment} label="25. ARS Assignment" />
        </Flex>
      </CaseResultSection>
      <Divider />
      <CaseResultSection title="Security Designation">
        <Flex direction="column" gap="16px">
          <Flex gap="20px" wrap="wrap">
            <CaseResultValue value={caseObj?.judge} label="1. Judge" onClickScore={handleClickScore("judge")} />
            <CaseResultValue label="2. Rec facility" value={caseObj?.rec_facility} />
          </Flex>
          <Flex gap="20px" wrap="wrap">
            <CaseResultValue label="3. Rec program" value={caseObj?.rec_program} />
            <CaseResultValue label="4. USM office" value={caseObj?.usm_office} />
          </Flex>
          <Divider my={0} />
          <Box>
            <CaseResultValue
              value={caseObj?.voluntary_surrender_status}
              label="5. Voluntary surrender status"
              onClickScore={handleClickScore("voluntary_surrender_status")}
            />
            <CaseResultValue mt={2} value={caseObj?.vs_date} label="5a. Voluntary surrender date" />
            <CaseResultValue mt={2} value={caseObj?.vs_location} label="5b. Voluntary surrender location" />
          </Box>
          <Divider my={0} />
          <Flex gap="20px" wrap="wrap">
            <CaseResultValue label="6. Months to release" value={caseObj?.month_to_release} />
            <CaseResultValue
              value={caseObj?.level_of_severity}
              label="7. Severity of the current offence"
              onClickScore={handleClickScore("level_of_severity")}
            />
          </Flex>
          <Divider my={0} />
          <Box>
            <Flex gap={1} align="center">
              <Text textStyle="label">8. Criminal history score</Text>
              <Text fontSize="14px" fontWeight={FONT_WEIGHT.NORMAL}>
                {caseObj?.criminal_history_points}
              </Text>
              <ArrowForwardIcon
                onClick={handleClickScore("criminal_history_points")}
                cursor="pointer"
                color="blue.300"
              />
            </Flex>
            <Flex gap={1} align="center">
              <Text textStyle="label">Criminal history total points</Text>
              <Text fontSize="14px" fontWeight={FONT_WEIGHT.NORMAL}>
                {caseObj?.criminal_history_total_points}
              </Text>
            </Flex>
            <CaseResultValue value={caseObj?.source_doc_date} label="8 (a). source doc date" />
            <CaseResultValue value={caseObj?.source_of_doc} label="8 (b). Source of Doc" />
          </Box>
          <Divider my={0} />
          <Flex gap="20px" wrap="wrap">
            <CaseResultValue
              value={caseObj?.history_of_violence}
              label="9. History of violence"
              onClickScore={handleClickScore("history_of_violence")}
            />
            <CaseResultValue
              value={`${caseObj?.history_of_escape}${
                caseObj?.history_of_escape_status ? ` - ${caseObj?.history_of_escape_status}` : ""
              }`}
              label="10. History of escape or attempts"
              onClickScore={handleClickScore("history_of_escape")}
            />
          </Flex>
          <Flex gap="20px" wrap="wrap">
            <CaseResultValue
              value={caseObj?.type_of_detainer}
              label="11. Type of detainer"
              onClickScore={handleClickScore("type_of_detainer")}
            />
            <CaseResultValue value={caseObj?.age} label="12. Age" />
            <CaseResultValue
              value={caseObj?.case_person_details?.education_level}
              label="13. Education level"
              onClickScore={handleClickScore("education_level")}
            />
          </Flex>
          <Flex gap="20px" wrap="wrap">
            <CaseResultValue
              value={caseObj?.substance_abuse}
              label="14. Drug/alcohol abuse"
              onClickScore={handleClickScore("substance_abuse")}
            />
            <CaseResultValue value={totalScore} label="15. Security point total" />
            <CaseResultValue
              value={caseObj?.public_safety_factors}
              label="16. Public safety factors"
              onClickScore={handleClickScore("public_safety_factors")}
            />
          </Flex>
          <CaseResultValue value={caseObj?.remarks} label="17. Remarks" />
          <CaseResultValue value={caseObj?.omdt_referral} label="18. Omdt referral" />
        </Flex>
      </CaseResultSection>
    </Flex>
  );
};

export default CaseResultView;
