import { FONT_FAMILY, FONT_WEIGHT } from "@assets/theme/foundations/textStyle";
import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem as ChakraBreadcrumbItem,
  BreadcrumbLink as ChakraBreadcrumbLink,
} from "@chakra-ui/react";
import { BreadcrumbItemInterface } from "@shared/interfaces";
import { FC } from "react";
import { Link } from "react-router-dom";

interface BreadcrumbProps {
  items: BreadcrumbItemInterface[];
}

const Breadcrumb: FC<BreadcrumbProps> = ({ items }) => {
  return (
    <ChakraBreadcrumb spacing="0px" separator={<ChevronRightIcon color="gray.500" />}>
      {items.map((item) => (
        <ChakraBreadcrumbItem key={item.name} isCurrentPage={item.isCurrentPage}>
          <ChakraBreadcrumbLink
            as={Link}
            fontSize={item.isCurrentPage ? "26px" : undefined}
            fontWeight={item.isCurrentPage ? FONT_WEIGHT.HEAVY : undefined}
            fontFamily={item.isCurrentPage ? FONT_FAMILY.SECONDARY : undefined}
            {...item}
          >
            {item.name}
          </ChakraBreadcrumbLink>
        </ChakraBreadcrumbItem>
      ))}
    </ChakraBreadcrumb>
  );
};

export default Breadcrumb;
