import React, { FC, useCallback, useState } from "react";
import { Select } from "@shared/components";
import { REPORT_STATUS } from "@shared/models";
import { ALL_OPTIONS, COLOR_PALETTE } from "@containers/Cases/constants";
import "./index.scss";
import { Option } from "@shared/interfaces";
interface CaseListSelectReportStatusFilterProps {
  handleChangeReportStatus: (report_status?: REPORT_STATUS) => void;
}

const CaseListSelectReportStatusFilter: FC<CaseListSelectReportStatusFilterProps> = ({ handleChangeReportStatus }) => {
  const [selectValue, setSelectValue] = useState<Option<string>>({ label: "All", value: "all" });

  const onChange = useCallback(
    (option: Option<string> | null) => {
      option && setSelectValue(option);
      if (option?.value === "all") {
        handleChangeReportStatus();
        return;
      } else {
        handleChangeReportStatus(option?.value as REPORT_STATUS);
      }
    },
    [handleChangeReportStatus],
  );

  return (
    <Select
      color={COLOR_PALETTE[selectValue.value as REPORT_STATUS] || "black"}
      className="cases-list-select"
      classNamePrefix="report-status-filter"
      value={selectValue}
      options={ALL_OPTIONS}
      name="report_status"
      onChange={onChange}
    />
  );
};

export default CaseListSelectReportStatusFilter;
