import { TextProps, Text } from "@chakra-ui/react";
import { FC, PropsWithChildren } from "react";

interface BoldTextProps extends TextProps {
  index?: number;
  color?: string;
}

const BoldText: FC<PropsWithChildren<BoldTextProps>> = ({ children, index = 0.1, color = "black", ...restProps }) => (
  <Text
    {...restProps}
    color={color}
    textShadow={`-${index}px 0 ${color},
        0 ${index}px ${color},
        ${index}px 0 ${color},
        0 -${index}px ${color},
        -${index}px -${index}px ${color},
        ${index}px ${index}px ${color},
        -${index}px ${index}px ${color},
        ${index}px -${index}px ${color};`}
  >
    {children}
  </Text>
);

export default BoldText;
