import React, { FC } from "react";
import { Button, Flex, ModalBody, Text } from "@chakra-ui/react";

interface CasePaymentErrorModal {
  onClose: () => void;
}

const CasePaymentError: FC<CasePaymentErrorModal> = ({ onClose }) => {
  return (
    <>
      <ModalBody p={5}>
        <Text textStyle="subtitle">Payment Failed</Text>
        <Text fontSize="14px" color="brand.grey" mt={3} textAlign="left">
          Payment was unsuccessful. Please try again.
        </Text>
        <Flex mt={6} justify="flex-end">
          <Button variant="primary" onClick={onClose}>
            Got it
          </Button>
        </Flex>
      </ModalBody>
    </>
  );
};

export default CasePaymentError;
