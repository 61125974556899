import { ActionWithPayload, PaginatedResponse, UsersListFilter, WithLoaderFlag } from "@shared/interfaces";
import { call, put, takeLatest } from "redux-saga/effects";
import { startLoading, stopLoading } from "@shared/store/actions";
import { UserShortModel, UserSummaryModel } from "@shared/models";
import { getShortUsers, getSummaryUsers } from "@containers/Users/store/actions";

import api from "../api";

function* getShortUsersListSaga({ payload }: ActionWithPayload<WithLoaderFlag<UsersListFilter>>) {
  const { isLoadingBlocked, ...restPayload } = payload;
  try {
    if (!isLoadingBlocked) yield put(startLoading());
    const response: PaginatedResponse<UserShortModel> = yield call(api.getUsersList, restPayload);
    yield put(
      getShortUsers.success({
        ...response,
        clear: !payload.page,
      }),
    );
  } catch (error) {
    yield put(getShortUsers.failure(error as Error));
  } finally {
    if (!isLoadingBlocked) yield put(stopLoading());
  }
}

function* getSummaryUsersListSaga({ payload }: ActionWithPayload<UsersListFilter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<UserSummaryModel> = yield call(api.getUsersListSummary, payload);
    yield put(
      getSummaryUsers.success({
        ...response,
        clear: !payload.page,
      }),
    );
  } catch (error) {
    yield put(getSummaryUsers.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* usersSaga() {
  yield takeLatest(getShortUsers.request, getShortUsersListSaga);
  yield takeLatest(getSummaryUsers.request, getSummaryUsersListSaga);
}

export default usersSaga;
