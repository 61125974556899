export enum FieldItemType {
  PASSWORD = "password",
  EMAIL = "email",
  NUMBER = "number",
  TEXT = "text",
  CHECKBOX = "checkbox",
  SELECT = "select",
  TEXTAREA = "textarea",
  RENDER = "render",
  BLOCK = "block",
}
