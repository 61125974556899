import { AppState } from "@shared/interfaces";
import { createSelector } from "reselect";
import { CaseMessageModel } from "@shared/models";

const selectCases = (state: AppState) => state.cases;

export const getCasesList = () => createSelector(selectCases, (state) => state.cases);
export const getCasesTotal = () => createSelector(selectCases, (state) => state.casesTotal);
export const getCase = () => createSelector(selectCases, (state) => state.case);

export const getCaseGroupedMessages = () =>
  createSelector(selectCases, (state) =>
    [...(state.case?.messages || [])]
      .sort((aScore, bScore) => aScore.sequence - bScore.sequence)
      .reduce<Record<string, CaseMessageModel[]>>((acc, value) => {
        acc[value.case_field] = [...(acc[value.case_field] || []), value];
        return acc;
      }, {}),
  );

export const getFilter = () => createSelector(selectCases, (state) => state.filter);

export const getProcessingCaseId = () => createSelector(selectCases, (state) => state.processingCaseId);
