import { FC } from "react";
import { Text } from "@chakra-ui/react";

interface ErrorMessageProps {
  isTouched?: boolean;
  error?: string;
}

export const ErrorMessage: FC<ErrorMessageProps> = ({ isTouched, error }) => {
  return isTouched && error ? (
    <Text h="16px" color="brand.error" mt="1" fontSize="12px" fontWeight="400">
      {error}
    </Text>
  ) : (
    <Text h="20px" />
  );
};
