export const IconButton = {
  variants: {
    transparent: {
      bg: "transparent",
      _hover: {
        bg: "transparent",
      },
    },
  },
};
