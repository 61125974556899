import React, { FC } from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { showModal } from "@shared/store/actions";
import { MODAL_TYPES } from "@shared/constants";
import { ReactComponent as TrashIcon } from "@assets/files/images/icons/trashIcon.svg";
import { CaseModel } from "@shared/models";

interface DeleteCaseProps extends BoxProps {
  item: CaseModel;
}

const DeleteCase: FC<DeleteCaseProps> = ({ onClick, item, ...restProps }) => {
  const dispatch = useDispatch();

  const handleDeleteCase = (e: React.MouseEvent<HTMLDivElement>) => {
    onClick?.(e);
    dispatch(showModal({ type: MODAL_TYPES.DELETE_CASE, props: { item } }));
  };

  return (
    <Box {...restProps} onClick={handleDeleteCase}>
      <TrashIcon />
    </Box>
  );
};

export default DeleteCase;
