import { FC } from "react";
import { GridItem, GridItemProps } from "@chakra-ui/react";
import { FONT_WEIGHT } from "@assets/theme/foundations/textStyle";
import { BoldText } from "@shared/components";

interface CaseFormViewHeadProps extends GridItemProps {
  label: string;
}

const CaseFormViewHead: FC<CaseFormViewHeadProps> = ({ label, ...restProps }) => {
  return (
    <GridItem
      backgroundColor="grey.50"
      textAlign="center"
      borderRight="2px solid"
      borderRightColor="grey.100"
      borderBottom="2px solid"
      borderBottomColor="grey.100"
      p={2}
      fontWeight={FONT_WEIGHT.BOLD}
      className="caseFormHeadContainer"
      {...restProps}
    >
      <BoldText index={0.2}>{label}</BoldText>
    </GridItem>
  );
};

export default CaseFormViewHead;
