import { REPORT_STATUS } from "@shared/models";

export const COLOR_PALETTE = {
  [REPORT_STATUS.NEW]: "brand.tableBodyText",
  [REPORT_STATUS.IN_PROGRESS]: "brand.primary",
  [REPORT_STATUS.COMPLETED]: "brand.activeColor",
};
export const OPTIONS = [
  { label: "New", value: REPORT_STATUS.NEW },
  { label: "In Progress", value: REPORT_STATUS.IN_PROGRESS },
  { label: "Completed", value: REPORT_STATUS.COMPLETED },
];

export const OPTIONS_DICTIONARY = OPTIONS.reduce<Partial<Record<REPORT_STATUS, string>>>((acc, option) => {
  acc[option.value] = option.label;
  return acc;
}, {});

export const ALL_OPTIONS = [{ label: "All", value: "all" }, ...OPTIONS];
