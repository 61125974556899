import React, { FC } from "react";
import { Flex, IconButton } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

import { MachineAnalysisPreviewHeader } from "../MachineAnalysisPreview";

interface MachineAnalysisPreviewModalHeaderProps {
  onClose: () => void;
  name: string;
}

const MachineAnalysisPreviewModalHeader: FC<MachineAnalysisPreviewModalHeaderProps> = ({ onClose, name }) => {
  return (
    <Flex px={8} py={3} justifyContent="space-between" alignItems="center">
      <MachineAnalysisPreviewHeader name={name} />
      <IconButton aria-label="" onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Flex>
  );
};

export default MachineAnalysisPreviewModalHeader;
