import { FC, useCallback, useMemo } from "react";
import { Select } from "@shared/components";
import { useField } from "formik";
import { SelectInputProps } from "@shared/components/Field/Select/Select";
import { Option } from "@shared/interfaces";
import { FormControl, FormLabel } from "@chakra-ui/react";
import { MultiValue } from "react-select";

interface FormikSelectProps extends Omit<SelectInputProps, "value" | "onChange"> {
  classNamePrefix?: string;
}

const FormikSelect: FC<FormikSelectProps> = ({
  name,
  options,
  label,
  classNamePrefix = "basic-select",
  isMulti,
  ...restProps
}) => {
  const [field] = useField(name);

  const selectValue = useMemo(() => {
    if (isMulti) {
      return options.filter((option) => field.value.includes(option.value));
    }
    const currentOption = options.find((option) => option.value === field.value);
    return currentOption || null;
  }, [field.value, isMulti, options]);

  const onChange = useCallback(
    (option: Option<string> | null | MultiValue<Option<string, unknown>>) => {
      if (Array.isArray(option)) {
        const value = option ? option.map((item) => item.value) : [];
        field.onChange({ target: { name, value } });
        return;
      } else {
        const optionSingle = option as Option<string, unknown>;
        field.onChange({ target: { name, value: optionSingle.value } });
      }
    },
    [field, name],
  );

  return (
    <FormControl mt={4}>
      {label && <FormLabel>{label}</FormLabel>}
      <Select
        options={options}
        name={name}
        value={selectValue}
        onChange={onChange}
        className="cases-list-select"
        classNamePrefix={classNamePrefix}
        isMulti={isMulti}
        {...restProps}
      />
    </FormControl>
  );
};

export default FormikSelect;
