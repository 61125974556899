import React, { FC } from "react";
import { Box, Button, Center, Divider, HStack, Icon, Image, Text, VStack } from "@chakra-ui/react";
import uploadPSR from "@assets/files/images/cases/uploadPsr.png";
import psrAnalysis from "@assets/files/images/cases/psrAnalysis.png";
import downloadPsr from "@assets/files/images/cases/downloadPsr.png";
import secureUpload from "@assets/files/images/cases/secureUpload.png";
import { ReactComponent as Bag } from "@assets/files/images/icons/bag.svg";
import { FONT_WEIGHT } from "@assets/theme/foundations/textStyle";

import { EmptyStateInstructionCard } from "..";

interface EmptyStateProps {
  onAddCase: () => void;
}

const EmptyState: FC<EmptyStateProps> = ({ onAddCase }) => {
  return (
    <VStack borderRadius="8px" bgColor="brand.white" h="full" p={6} spacing={0} overflowY="auto">
      <Box
        mb={6}
        sx={{
          "@media screen and (min-height: 600px)": {
            mt: "24px",
          },
          "@media screen and (min-height: 850px)": {
            mt: "48px",
          },
        }}
      >
        <Text
          textStyle="noMatchesTitle"
          color="blue.900"
          textAlign="center"
          w="full"
          mx="auto"
          sx={{
            "@media screen and (min-height: 830px)": {
              w: "400px",
            },
          }}
        >
          Welcome to Prisonology’s Secure Document Portal
        </Text>
        <Text textStyle="listWithIcons" color="brand.emptyStateText" textAlign="center" mt={2}>
          Securely upload your documentation to get a Bureau of Prison’s expert analysis
          <br />
          on critical information your client needs for sentencing.
        </Text>
      </Box>
      <Center h="full" minH="392px" w="full">
        <HStack
          alignItems="stretch"
          h="392px"
          w="full"
          maxW="1200px"
          divider={<Divider orientation="vertical" color="grey.5" w="3px" />}
          spacing="60px"
          sx={{
            "@media screen and (min-height: 800px)": {
              h: "500px",
            },
          }}
        >
          <VStack w="50%" spacing={4}>
            <EmptyStateInstructionCard num={1} text="Upload Your Documentation" imgSrc={uploadPSR} />
            <EmptyStateInstructionCard
              num={2}
              text={
                <>
                  Receive a Personalized Analysis
                  <br />
                  from Our Experts in Less
                  <br />
                  Than 48 Hours
                </>
              }
              imgSrc={psrAnalysis}
            />
            <EmptyStateInstructionCard num={3} text="Download and Share" imgSrc={downloadPsr} />
          </VStack>
          <Center w="50%" flexDirection="column">
            <Image src={secureUpload} alt="secure upload" />
            <Text
              textStyle="subtitle"
              lineHeight="mormal"
              color="brand.emptyStateText"
              maxW="350px"
              textAlign="center"
              letterSpacing="normal"
              mt={6}
            >
              Upload your Presentence Investigation Report
            </Text>
            <Button variant="primary" w="176px" h="48px" mt={8} onClick={onAddCase}>
              + Add First Case
            </Button>
            <Text fontSize="12px" fontWeight={FONT_WEIGHT.NORMAL} color="brand.grey" mt={4}>
              <Icon as={Bag} w="18px" h="18px" verticalAlign="text-bottom" />
              &nbsp;
              <Text as="span" fontWeight={FONT_WEIGHT.BOLD}>
                FULLY SECURED
              </Text>{" "}
              SSL Upload
            </Text>
          </Center>
        </HStack>
      </Center>
    </VStack>
  );
};

export default EmptyState;
