import React, { FC, PropsWithChildren } from "react";
import { Badge, Box } from "@chakra-ui/react";

interface MachineAnalysisPreviewBlockProps {
  title?: string;
}

const MachineAnalysisPreviewBlock: FC<PropsWithChildren<MachineAnalysisPreviewBlockProps>> = ({ title, children }) => {
  return (
    <Box
      p={3}
      boxShadow="0px 4px 13px -1px rgba(45, 54, 86, 0.07), 0px 2px 9px -1px rgba(45, 54, 86, 0.06)"
      borderRadius={8}
      bg="brand.white"
      w="100%"
    >
      {title && (
        <Badge className="block_title" width="max-content" variant="subtle" mb={3}>
          {title}
        </Badge>
      )}
      <Box w="100%">{children}</Box>
    </Box>
  );
};

export default MachineAnalysisPreviewBlock;
