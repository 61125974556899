export enum UsersActionTypes {
  GET_SHORT_USERS_LIST = "@@USERS/GET_SHORT_USERS_LIST",
  GET_SHORT_USERS_LIST_SUCCESS = "@@USERS/GET_SHORT_USERS_LIST_SUCCESS",
  GET_SHORT_USERS_LIST_FAILURE = "@@USERS/GET_SHORT_USERS_LIST_FAILURE",

  GET_SUMMARY_USERS_LIST = "@@USERS/GET_SUMMARY_USERS_LIST",
  GET_SUMMARY_USERS_LIST_SUCCESS = "@@USERS/GET_SUMMARY_USERS_LIST_SUCCESS",
  GET_SUMMARY_USERS_LIST_FAILURE = "@@USERS/GET_SUMMARY_USERS_LIST_FAILURE",

  SET_SHORT_USERS_LIST_FILTER = "@@USERS/SET_SHORT_USERS_LIST_FILTER",
  SET_SUMMARY_USERS_LIST_FILTER = "@@USERS/SET_SUMMARY_USERS_LIST_FILTER",
}
