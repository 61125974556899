export const Button = {
  baseStyle: {
    height: "40px",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
  },
  variants: {
    primary: {
      backgroundColor: "brand.primary",
      color: "brand.white",
      _disabled: {
        backgroundColor: "brand.disabledBtnBg",
        opacity: 1,
      },
      _hover: {
        backgroundColor: "brand.primary",
        _disabled: {
          backgroundColor: "brand.disabledBtnBg",
        },
      },
    },
    outline: {
      backgroundColor: "transparent",
      borderWidth: "2px",
      borderColor: "brand.primary",
      color: "brand.primary",
      _disabled: {
        opacity: 0.6,
      },
      _hover: {
        backgroundColor: "transparent",
      },
      _active: {
        backgroundColor: "transparent",
      },
    },
    ghost: {
      backgroundColor: "transparent",
      color: "brand.primary",
      _disabled: {
        opacity: 0.6,
      },
      _hover: {
        backgroundColor: "transparent",
      },
      _active: {
        backgroundColor: "transparent",
      },
    },
    secondary: {
      backgroundColor: "grey.5",
      color: "brand.chatMessageText",
      _disabled: {
        opacity: 0.6,
      },
      _hover: {
        backgroundColor: "transparent",
      },
      _active: {
        backgroundColor: "transparent",
      },
    },
  },
};
