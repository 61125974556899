import { extendTheme, theme as defaultTheme } from "@chakra-ui/react";
import foundations from "@assets/theme/foundations";
import breakpoints from "@assets/theme/breakpoints";
import components from "@assets/theme/components";

import { FONT_FAMILY } from "./foundations/textStyle";

export const theme = extendTheme(
  {
    ...foundations,
  },
  {
    config: defaultTheme.config,
    direction: defaultTheme.direction,
    transition: defaultTheme.transition,
    breakpoints,
    zIndices: defaultTheme.zIndices,
    components: { ...defaultTheme.components, ...components },
    styles: {
      ...defaultTheme.styles,
      global: {
        ...defaultTheme.styles.global,
        "html, body": {
          fontFamily: FONT_FAMILY.PRIMARY,
        },
      },
    },
    colors: {},
    radii: foundations.borderRadius.radii,
    fonts: {
      heading: FONT_FAMILY.PRIMARY,
      body: FONT_FAMILY.PRIMARY,
    },
    fontSizes: {},
    fontWeights: {},
    lineHeights: {},
    space: {
      blockMargin: "16px",
    },
  },
);
