import { CaseModel } from "@shared/models";
import { getCaseTotalScore } from "@containers/Cases/utils/functions";

const CASE_PERSON_DETAILS_FIELDS = [
  "first_name",
  "last_name",
  "date_of_birth",
  "sex",
  "race",
  "citizenship",
  "middle_name",
  "suffix",
  "ssn",
  "ethnic_origin",
  "state_of_birth",
  "country_of_birth",
  "street",
  "city",
  "state",
  "zip",
  "height",
  "weight",
  "hair_color",
  "eyes_color",
  "education_level",
] as const;

const FIELDS = [
  "usm_number",
  "district",
  "docket_number",
  "total_offense_level",
  "fbi_number",
  "foreign_country",
  "ars_assignment",
  "judge",
  "rec_facility",
  "rec_program",
  "usm_office",
  "voluntary_surrender_status",
  "level_of_severity",
  "criminal_history_points",
  "criminal_history_category",
  "history_of_violence",
  "history_of_escape",
  "type_of_detainer",
  "age",
  "education_level",
  "substance_abuse",
  "public_safety_factors",
  "security_classification",
  "remarks",
  "criminal_history_info",
  "criminal_history_total_points",

  "type_of_prison",
  "general_recidivism",
  "first_step_act_eligible",
  "second_step_act_eligible",
  "risk_of_violence_recidivism",
  "initial_pattern_score",
  "recidivism",
  "mental_health_notes",
  "program_recommendations",
  "drug_program",
  "vs_date",
  "vs_location",
  "month_to_release",
  "source_doc_date",
  "source_of_doc",
  "highest_grade",
  "omdt_referral",
  "history_of_escape_status",
  "total",
  "plea",
  "5k1",
  "offense_sentence",
  ...CASE_PERSON_DETAILS_FIELDS,
] as const;

type CriminalHistoryInfo = {
  count: string;
  description: string;
};

type ExcludeCriminalHistoryInfo = Exclude<(typeof FIELDS)[number], "criminal_history_info">;

export type CaseModelFormValues = {
  [key in ExcludeCriminalHistoryInfo]: string | undefined;
} & { criminal_history_info: CriminalHistoryInfo[] };

export const generateFormInitialValues = (caseObj?: CaseModel): CaseModelFormValues => {
  const data = Object.keys(caseObj || {}).reduce((acc, field) => {
    if (caseObj) {
      if (field === "history_of_escape" && caseObj.history_of_escape_status) {
        acc.history_of_escape = `${caseObj.history_of_escape}-${caseObj.history_of_escape_status}`;
        return acc;
      }

      if (field === "messages") {
        return acc;
      }

      if (field === "criminal_history_info") {
        acc.criminal_history_info = Object.keys(caseObj.criminal_history_info || {}).map((key) => ({
          count: key,
          description: caseObj.criminal_history_info?.[key] || "",
        }));
        return acc;
      }

      if (field === "case_person_details" && caseObj.case_person_details) {
        acc = {
          ...acc,
          ...Object.keys(caseObj.case_person_details || {})?.reduce((acc, field) => {
            acc[field] = String(caseObj?.case_person_details?.[field as keyof CaseModel["case_person_details"]] ?? "");
            return acc;
          }, {} as Record<string, string>),
        };

        return acc;
      }

      acc[field as keyof Omit<CaseModelFormValues, "criminal_history_info">] = String(
        caseObj[field as keyof CaseModel] ?? "",
      );
    }
    return acc;
  }, {} as CaseModelFormValues);

  data.total = `${getCaseTotalScore(caseObj)}`;

  return data;
};

export const parseValuesToCaseModel = (values: CaseModelFormValues) => {
  const copyValues = { ...values, case_person_details: {} };
  delete copyValues.total;
  copyValues.case_person_details = CASE_PERSON_DETAILS_FIELDS.reduce((acc, field) => {
    acc[field] = copyValues[field as keyof Omit<CaseModelFormValues, "criminal_history_info">] || "";
    if (field === "education_level") {
      acc[field] = Number(copyValues[field as keyof Omit<CaseModelFormValues, "criminal_history_info">]);
    }
    delete copyValues[field as keyof CaseModelFormValues];
    return acc;
  }, {} as Record<(typeof CASE_PERSON_DETAILS_FIELDS)[number], string | number>);

  const public_safety_factors = Array.isArray(copyValues.public_safety_factors)
    ? copyValues.public_safety_factors.join(",")
    : copyValues.public_safety_factors;
  return {
    ...copyValues,
    level_of_severity: Number(copyValues.level_of_severity),
    voluntary_surrender_status: Number(copyValues.voluntary_surrender_status),
    criminal_history_points: Number(copyValues.criminal_history_points),
    criminal_history_category: Number(copyValues.criminal_history_category),
    history_of_violence: Number(copyValues.history_of_violence),
    history_of_escape: Number(copyValues.history_of_escape?.split("-")[0]),
    history_of_escape_status: copyValues.history_of_escape?.split("-")[1] || null,
    type_of_detainer: Number(copyValues.type_of_detainer),
    age: Number(copyValues.age),
    criminal_history_total_points: Number(copyValues.criminal_history_total_points),
    substance_abuse: Number(copyValues.substance_abuse),
    public_safety_factors,
    criminal_history_info: Array.isArray(copyValues.criminal_history_info)
      ? copyValues.criminal_history_info?.reduce<Record<string, string>>((acc, values) => {
          acc[values.count] = values.description;
          return acc;
        }, {})
      : copyValues.criminal_history_info,
  } as unknown as Partial<CaseModel>;
};
