import React, { FC } from "react";
import { Button, Flex, Image, ModalBody, ModalCloseButton, ModalHeader, Text, VStack } from "@chakra-ui/react";
import psr from "@assets/files/images/cases/psr.png";
import { FONT_WEIGHT } from "@assets/theme/foundations/textStyle";

interface UploadCaseModalSuccessProps {
  onSubmit: () => void;
}

const UploadCaseModalSuccessModal: FC<UploadCaseModalSuccessProps> = ({ onSubmit }) => {
  return (
    <>
      <ModalHeader pt={6}>
        <Flex justify="center">
          <Image src={psr} alt="PSR" />
        </Flex>
        <ModalCloseButton color="grey.10" />
      </ModalHeader>
      <ModalBody pb={6}>
        <Text
          textStyle="subtitle"
          textAlign="center"
          color="brand.modalHeaderText"
          letterSpacing="unset"
          lineHeight="normal"
        >
          Your document was received!
        </Text>
        <VStack mt={2} textAlign="center" spacing={2} fontSize="14px">
          <Text color="brand.activeColor" fontWeight={FONT_WEIGHT.BOLD}>
            Prisonology’s BOP experts are reviewing your file.
          </Text>
          <Text pb={4}>You will receive an email confirmation when your report is ready for download.</Text>
          <Button w="100%" variant="primary" onClick={onSubmit}>
            Got It
          </Button>
        </VStack>
      </ModalBody>
    </>
  );
};

export default UploadCaseModalSuccessModal;
