export const SEX_OPTIONS = [
  { label: "M", value: "M" },
  { label: "F", value: "F" },
];

export const RACE_OPTIONS = [
  { label: "A", value: "A" },
  { label: "B", value: "B" },
  { label: "I", value: "I" },
  { label: "W", value: "W" },
];

export const EDUCATION_LEVEL_OPTIONS = [
  { label: "Verified", value: "0" },
  { label: "Enrolled", value: "1" },
  { label: "No verified", value: "2" },
];

export const RADIO_OPTIONS = [
  { title: "Yes", value: "yes" },
  { title: "No", value: "no" },
];

export const ETHNIC_ORIGIN_OPTIONS = [
  { label: "O", value: "O" },
  { label: "H", value: "H" },
];

export const RADIO_DESTIGNATION_OPTIONS = [
  { title: "Yes", value: "1" },
  { title: "No", value: "0" },
];

export const RADIO_SURRENDER_OPTIONS = [
  { title: "Yes", value: "-3" },
  { title: "No", value: "0" },
];

export const SEVERITY_OPTIONS = [
  { label: "Lowest", value: "0" },
  { label: "Low moderate", value: "1" },
  { label: "Moderate", value: "3" },
  { label: "High", value: "5" },
  { label: "Greatest", value: "7" },
];

export const CRIMINAL_OPTIONS = [
  { label: "0-1", value: "0" },
  { label: "2-3", value: "2" },
  { label: "4-6", value: "4" },
  { label: "7-9", value: "6" },
  { label: "10-12", value: "8" },
  { label: "13+", value: "10" },
];

export const CRIMINAL_OPTIONS_MAP = new Map<[number, number | undefined], string>([
  [[0, 1], "0"],
  [[2, 3], "2"],
  [[4, 6], "4"],
  [[7, 9], "6"],
  [[10, 12], "8"],
  [[13, undefined], "10"],
]);

export const CRIMINAL_TOTAL_OPTIONS = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13+", value: "13" },
];

export const HISTORY_OF_VIOLENCE_OPTIONS = [
  { label: "None", value: "0" },
  { label: "Minor, >10 years", value: "1" },
  { label: "Minor, 5-10 years", value: "3" },
  { label: "Minor, <5 years", value: "5" },
  { label: "Serious, >15 years", value: "2" },
  { label: "Serious, 10-15 years", value: "4" },
  { label: "Serious, 5-10 years", value: "6" },
  { label: "Serious, <5 years", value: "7" },
];

export const TYPE_OF_DETAINER_OPTIONS = [
  { label: "None", value: "0" },
  { label: "Lowest/Low Moderate", value: "1" },
  { label: "Moderate", value: "3" },
  { label: "High", value: "5" },
  { label: "Greatest", value: "7" },
];

export const AGE_OPTIONS = [
  { label: "55 and over", value: "0" },
  { label: "36 thru 54.", value: "2" },
  { label: "25 thru 35", value: "4" },
  { label: "24 or less", value: "8" },
];

export const AGE_OPTIONS_MAP: Map<[number, number], [string, string]> = new Map([
  [
    [55, Infinity],
    ["0", "55 and over"],
  ],
  [
    [36, 54],
    ["2", "36 thru 54"],
  ],
  [
    [25, 35],
    ["4", "25 thru 35"],
  ],
  [
    [0, 24],
    ["8", "24 or less"],
  ],
]);

export const DRUG_ALCOHOL_OPTIONS = [
  { label: "Never/>5 years", value: "0" },
  { label: "<5 years ", value: "1" },
];

export const HISTORY_OF_ESCAPE_OPTIONS = [
  { label: "None", value: "0" },
  { label: "Minor, >10 years", value: "1-m" },
  { label: "Minor, 5-10 years", value: "2-m" },
  { label: "Minor, <5 years", value: "3-m" },
  { label: "Serious", value: "3-s" },
];
