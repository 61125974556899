import React, { FC, useCallback } from "react";
import { CaseModel } from "@shared/models";
import { IconButton, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { getCaseFileSignedUrl } from "@containers/Cases/store/actions";
import { ReactComponent as MenuIcon } from "@assets/files/images/icons/menuIcon.svg";
import { ViewIcon } from "@chakra-ui/icons";

interface CaseViewHeaderMenuProps {
  caseObj?: CaseModel | null;
}

const CaseViewHeaderMenu: FC<CaseViewHeaderMenuProps> = ({ caseObj }) => {
  const dispatch = useDispatch();

  const handleWatchDocument = useCallback(() => {
    caseObj?.id && dispatch(getCaseFileSignedUrl.request(caseObj.id));
  }, [caseObj?.id, dispatch]);

  return (
    <>
      <Menu>
        <MenuButton border="none" as={IconButton} aria-label="Options" icon={<MenuIcon />} variant="outline" />
        <MenuList
          sx={{
            "& .chakra-menu__icon-wrapper": {
              m: 0,
            },
            "&.chakra-menu__menuitem": {
              px: 4,
            },
            button: {
              p: 4,
            },
          }}
        >
          <MenuItem icon={<ViewIcon boxSize={5} color="brand.primary" />} onClick={handleWatchDocument}>
            <Text ml={2} color="brand.primary" textStyle="overlayTitle">
              View PSR
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export default CaseViewHeaderMenu;
