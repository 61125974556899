export const Breadcrumb = {
  baseStyle: {
    list: {
      color: "brand.breadcrumbText",
    },
    link: {
      fontSize: "16px",
      fontWeight: 400,
      color: "brand.breadcrumbText",
    },
    separator: {
      marginTop: "-1px",
    },
  },
};
