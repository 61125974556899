import { TableProperty } from "@shared/components/Table";
import { CaseModel } from "@shared/models";
import { getFullName, getUSFormattedDate } from "@shared/utils";
import UserInfo from "@shared/components/UserInfo/UserInfo";

import { DeleteCase, DownloadCase } from "../components";
import { CaseListSelectReportStatus } from "../components/CasesListContainer/CaseListSelectReportStatus";

const fieldKeysSecuredForAdmin = ["created_by", "is_deleted"];

export const casesTablePropertiesAdmin: TableProperty<CaseModel>[] = [
  {
    title: "CREATION DATE",
    key: "created_at",
    get: (item) => getUSFormattedDate(item.created_at),
  },
  {
    title: "Inmate's Name",
    key: "case_person_details",
    tdStyle: {
      maxW: "200px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    get: (item) => getFullName<CaseModel>(item.case_person_details, "-"),
  },
  {
    title: "Report status",
    key: "report_status",
    get: (item) => <CaseListSelectReportStatus item={item} onClick={(e) => e.stopPropagation()} />,
  },
  {
    title: "SCORE STATUS",
    key: "status",
    get: (item) => item.status || "-",
  },
  {
    title: "Created by",
    key: "created_by",
    get: (item) => <UserInfo name={item.created_by} email={item.user?.email || ""} />,
  },
  {
    title: "",
    key: "is_deleted",
    get: (item) => <DeleteCase item={item} onClick={(e) => e.stopPropagation()} />,
  },
  {
    title: "",
    key: "docket_number",
    get: (item) => <DownloadCase item={item} onClick={(e) => e.stopPropagation()} />,
  },
];

export const casesTablePropertiesUser: TableProperty<CaseModel>[] = casesTablePropertiesAdmin.filter(
  (property) => !fieldKeysSecuredForAdmin.includes(property.key),
);
