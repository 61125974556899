import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as CasesAction, selectors } from "@containers/Cases/store";
import { deleteCase } from "@containers/Cases/store/actions";
import { Button, Flex, ModalBody, ModalHeader, Text } from "@chakra-ui/react";
import { CaseModel } from "@shared/models";
import { getFullName } from "@shared/utils";
import { defaultFilter } from "@containers/Cases/store/reducer";

interface DeleteCaseModalProps {
  onClose: () => void;
  item: CaseModel;
}

const DeleteCaseModal: FC<DeleteCaseModalProps> = ({ item, onClose }) => {
  const dispatch = useDispatch();
  const filter = useSelector(selectors.getFilter());

  const handleDeleteCase = () => {
    dispatch(
      deleteCase.request({
        caseId: item.id,
        cb: () => {
          dispatch(
            CasesAction.getCasesList.request({ ...filter, page: defaultFilter.page, limit: defaultFilter.limit }),
          );
          onClose();
        },
      }),
    );
  };

  return (
    <>
      <ModalHeader>
        <Text textStyle="subtitle">Remove Case</Text>
      </ModalHeader>
      <ModalBody p={5} pt={4}>
        <Text fontSize="14px" color="#9b9591" textAlign="left">
          {`Are you sure you want to remove ${getFullName<CaseModel>(item, "-")} case?`}
        </Text>
        <Flex mt={6} justify="flex-end" gap={3}>
          <Button onClick={onClose}>Cancel</Button>
          <Button variant="primary" onClick={handleDeleteCase}>
            Delete
          </Button>
        </Flex>
      </ModalBody>
    </>
  );
};

export default DeleteCaseModal;
