import { createAction, createAsyncAction } from "typesafe-actions";
import { UserModel } from "@shared/models";
import { WithCallback } from "@shared/interfaces";

import { AuthActionTypes } from "./constants";
import {
  LoginShape,
  RegistrationShape,
  RestoreShape,
  ResetPasswordPayload,
  GoToBillingPortalShape,
} from "../interface";

export const login = createAsyncAction(
  AuthActionTypes.LOGIN,
  AuthActionTypes.LOGIN_SUCCESS,
  AuthActionTypes.LOGIN_FAILURE,
)<LoginShape, undefined, Error>();

export const registration = createAsyncAction(
  AuthActionTypes.REGISTRATION,
  AuthActionTypes.REGISTRATION_SUCCESS,
  AuthActionTypes.REGISTRATION_FAILURE,
)<RegistrationShape, { token: string }, Error>();

export const logout = createAsyncAction(
  AuthActionTypes.LOGOUT,
  AuthActionTypes.LOGOUT_SUCCESS,
  AuthActionTypes.LOGOUT_FAILURE,
)<undefined, undefined, Error>();

export const forgotPassword = createAsyncAction(
  AuthActionTypes.FORGOT_PASSWORD,
  AuthActionTypes.FORGOT_PASSWORD_SUCCESS,
  AuthActionTypes.FORGOT_PASSWORD_FAILURE,
)<RestoreShape, { email: string }, Error>();

export const resetPassword = createAsyncAction(
  AuthActionTypes.RESET_PASSWORD,
  AuthActionTypes.RESET_PASSWORD_SUCCESS,
  AuthActionTypes.RESET_PASSWORD_FAILURE,
)<ResetPasswordPayload, { token: string }, Error>();

export const getUser = createAsyncAction(
  AuthActionTypes.GET_USER,
  AuthActionTypes.GET_USER_SUCCESS,
  AuthActionTypes.GET_USER_FAILURE,
)<undefined, { user: UserModel }, Error>();

export const checkUserPaymentMethod = createAsyncAction(
  AuthActionTypes.CHECK_USER_PAYMENT_METHOD,
  AuthActionTypes.CHECK_USER_PAYMENT_METHOD_SUCCESS,
  AuthActionTypes.CHECK_USER_PAYMENT_METHOD_FAILURE,
)<WithCallback<{ id: number }, boolean>, { exists: boolean }, Error>();

export const getCheckoutUrl = createAsyncAction(
  AuthActionTypes.GET_CHECKOUT_URL,
  AuthActionTypes.GET_CHECKOUT_URL_SUCCESS,
  AuthActionTypes.GET_CHECKOUT_URL_FAILURE,
)<{ id: number; successUrl: string; cancelUrl: string; caseId: number }, { url: string }, Error>();

export const goToBillingPortal = createAction(AuthActionTypes.GO_TO_BILLING_PORTAL)<GoToBillingPortalShape>();
