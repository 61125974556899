import { AnyAction, combineReducers } from "redux";
import { AppState } from "@shared/interfaces";
import { AuthReducer } from "@containers/Auth/store/reducer";
import { SharedReducer } from "@shared/store/reducer";
import { CasesReducer } from "@containers/Cases/store/reducer";
import { UsersReducer } from "@containers/Users/store/reducer";

export default () => {
  const appReducer = combineReducers({
    auth: AuthReducer,
    shared: SharedReducer,
    cases: CasesReducer,
    users: UsersReducer,
  });

  return (state: AppState | undefined, action: AnyAction) => {
    return appReducer(state, action);
  };
};
