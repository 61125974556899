import { FC } from "react";
import { Grid, GridItem } from "@chakra-ui/react";

import MachineAnalysisPreviewBlock from "./MachineAnalysisPreviewBlock";
import MachineAnalysisPreviewContent from "./MachineAnalysisPreviewContent";

type MachineAnalysisPreviewBlockMitigatingFactorsData = Partial<
  Record<"mentalHealthNotes" | "substanceAbuse" | "programRecommendations" | "drugProgram", string | number | undefined>
>;

type MachineAnalysisPreviewBlockMitigatingFactorsProps = MachineAnalysisPreviewBlockMitigatingFactorsData & {
  title?: string;
};

const MachineAnalysisPreviewBlockMitigatingFactors: FC<MachineAnalysisPreviewBlockMitigatingFactorsProps> = ({
  title,
  ...props
}) => {
  return (
    <MachineAnalysisPreviewBlock title={title}>
      <Grid templateColumns="repeat(4, 1fr)" gap={3}>
        {props.mentalHealthNotes && (
          <GridItem colSpan={2}>
            <MachineAnalysisPreviewContent title="1. Mental Health Notes" description={props.mentalHealthNotes} />
          </GridItem>
        )}
        {props.substanceAbuse && (
          <GridItem colSpan={2}>
            <MachineAnalysisPreviewContent title="2. Substance Abuse" description={props.substanceAbuse} />
          </GridItem>
        )}
        {props.programRecommendations && (
          <GridItem colSpan={2}>
            <MachineAnalysisPreviewContent
              title="3. Program Recommendations"
              description={props.programRecommendations}
            />
          </GridItem>
        )}
        {props.drugProgram && (
          <GridItem colSpan={2}>
            <MachineAnalysisPreviewContent
              title="4. Residential or Non-Residential Drug Program"
              description={props.drugProgram}
            />
          </GridItem>
        )}
      </Grid>
    </MachineAnalysisPreviewBlock>
  );
};

export default MachineAnalysisPreviewBlockMitigatingFactors;
