import React, { FC } from "react";
import { Box } from "@chakra-ui/react";
import { CaseMessageModel } from "@shared/models";

import ChatMessage from "./ChatMessage";

interface ChatProps {
  messages: CaseMessageModel[];
}

const Chat: FC<ChatProps> = ({ messages }) => {
  return (
    <Box>
      {messages.map((m) => (
        <ChatMessage key={m.id} message={m} />
      ))}
    </Box>
  );
};

export default Chat;
