import { FC } from "react";
import { BoldText } from "@shared/components";
import { TextProps } from "@chakra-ui/react";

interface CaseFormViewValueProps extends Partial<TextProps> {
  value?: string | number | null;
}

const CaseFormViewValue: FC<CaseFormViewValueProps> = ({ value, ...restProps }) => (
  <BoldText w="100%" index={0.3} {...restProps} color="black">
    {value}
  </BoldText>
);

export default CaseFormViewValue;
