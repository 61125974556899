import { FC, PropsWithChildren } from "react";
import { GridItem, GridItemProps } from "@chakra-ui/react";

const CaseFormViewCeil: FC<PropsWithChildren<GridItemProps>> = ({ colSpan, children, ...restProps }) => {
  return (
    <GridItem
      colSpan={colSpan || 1}
      border="none"
      borderBottom="2px solid"
      borderBottomColor="grey.100"
      borderRight="2px solid"
      borderRightColor="grey.100"
      whiteSpace="pre-wrap"
      alignItems="center"
      p={1}
      flexDir="row"
      display="flex"
      className="caseFormCeilContainer"
      {...restProps}
    >
      {children}
    </GridItem>
  );
};

export default CaseFormViewCeil;
