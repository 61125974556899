export enum CasesActionTypes {
  GET_CASES_LIST = "@@CASES/GET_CASES_LIST",
  GET_CASES_LIST_SUCCESS = "@@CASES/GET_CASES_LIST_SUCCESS",
  GET_CASES_LIST_FAILURE = "@@CASES/GET_CASES_LIST_FAILURE",

  GET_CASE = "@@CASES/GET_CASE",
  GET_CASE_SUCCESS = "@@CASES/GET_CASE_SUCCESS",
  GET_CASE_FAILURE = "@@CASES/GGET_CASE_FAILURE",

  GET_CASE_DATA = "@@CASES/GET_CASE_DATA",
  GET_CASE_DATA_SUCCESS = "@@CASES/GET_CASE_DATA_SUCCESS",
  GET_CASE_DATA_FAILURE = "@@CASES/GET_CASE_DATA_FAILURE",

  RESTART_PROCESSING_CASE = "@@CASES/RESTART_PROCESSING_CASE",
  RESTART_PROCESSING_CASE_SUCCESS = "@@CASES/RESTART_PROCESSING_CASE_SUCCESS",
  RESTART_PROCESSING_CASE_FAILURE = "@@CASES/RESTART_PROCESSING_CASE_FAILURE",

  CREATE_CASE = "@@CASES/CREATE_CASE",
  CREATE_CASE_SUCCESS = "@@CASES/CREATE_CASE_SUCCESS",
  CREATE_CASE_FAILURE = "@@CASES/CREATE_CASE_FAILURE",

  DELETE_CASE = "@@CASES/DELETE_CASE",
  DELETE_CASE_SUCCESS = "@@CASES/DELETE_CASE_SUCCESS",
  DELETE_CASE_FAILURE = "@@CASES/DELETE_CASE_FAILURE",

  UPDATE_CASE_REPORT_STATUS = "@@CASES/UPDATE_CASE_REPORT_STATUS",
  UPDATE_CASE_REPORT_STATUS_SUCCESS = "@@CASES/UPDATE_CASE_REPORT_STATUS_SUCCESS",
  UPDATE_CASE_REPORT_STATUS_FAILURE = "@@CASES/UPDATE_CASE_REPORT_STATUS_FAILURE",

  GET_CASE_FILE_SIGNED_URL = "@@CASES/GET_CASE_FILE_SIGNED_URL",
  GET_CASE_FILE_SIGNED_URL_SUCCESS = "@@CASES/GET_CASE_FILE_SIGNED_URL_SUCCESS",
  GET_CASE_FILE_SIGNED_URL_FAILURE = "@@CASES/GET_CASE_FILE_SIGNED_URL_FAILURE",

  PAY_FOR_CASE = "@@CASES/PAY_FOR_CASE",
  PAY_FOR_CASE_SUCCESS = "@@CASES/PAY_FOR_CASE_SUCCESS",
  PAY_FOR_CASE_FAILURE = "@@CASES/PAY_FOR_CASE_FAILURE",

  CLEAR_CASES_LIST = "@@CASES/CLEAR_CASES_LIST",
  SET_FILTER = "@@CASES/SET_FILTER",
  SET_PROCESSING_CASE_ID = "@@CASES/SET_PROCESSING_CASE_ID",

  UPDATE_CASE = "@@CASES/UPDATE_CASE",
  UPDATE_CASE_SUCCESS = "@@CASES/UPDATE_CASE_SUCCESS",
  UPDATE_CASE_FAILURE = "@@CASES/UPDATE_CASE_FAILURE",
}
