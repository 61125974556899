import { FC } from "react";
import { Divider, Flex, Grid, GridItem, Input, Stack } from "@chakra-ui/react";
import {
  AGE_OPTIONS,
  DRUG_ALCOHOL_OPTIONS,
  EDUCATION_LEVEL_OPTIONS,
  HISTORY_OF_ESCAPE_OPTIONS,
  HISTORY_OF_VIOLENCE_OPTIONS,
  SEVERITY_OPTIONS,
  TYPE_OF_DETAINER_OPTIONS,
} from "@containers/Cases/components/CaseViewContainer/MachineAnalysis/MachineAnalysis.constants";

import MachineAnalysisPreviewBlock from "./MachineAnalysisPreviewBlock";
import MachineAnalysisPreviewContent from "./MachineAnalysisPreviewContent";

type MachineAnalysisPreviewBlockSecurityDesignationData = Partial<
  Record<
    | "judge"
    | "recFacility"
    | "recProgram"
    | "usmOffice"
    | "voluntarySurrenderStatus"
    | "VSDate"
    | "VSLocation"
    | "monthToRelease"
    | "levelOfSeverity"
    | "criminalHistoryPoints"
    | "criminalHistoryTotalPoints"
    | "sourceOfDocDate"
    | "sourceOfDoc"
    | "historyOfViolence"
    | "historyOfEscape"
    | "typeOfDetainer"
    | "age"
    | "educationLevel"
    | "highestGrade"
    | "substanceAbuse"
    | "total"
    | "remarks"
    | "omdtReferral"
    | "publicSafetyFactors",
    string | number | undefined
  >
>;

type MachineAnalysisPreviewBlockSecurityDesignationProps = MachineAnalysisPreviewBlockSecurityDesignationData & {
  title?: string;
};

const MachineAnalysisPreviewBlockSecurityDesignation: FC<MachineAnalysisPreviewBlockSecurityDesignationProps> = ({
  title,
  ...props
}) => {
  return (
    <MachineAnalysisPreviewBlock title={title}>
      <Grid templateColumns="repeat(4, 1fr)" gap={3}>
        {props.judge && (
          <GridItem colSpan={3}>
            <MachineAnalysisPreviewContent title="1. Judge" description={props.judge} />
          </GridItem>
        )}
        {props.recFacility && (
          <GridItem colSpan={1}>
            <MachineAnalysisPreviewContent title="2. Rec Facility" description={props.recFacility} />
          </GridItem>
        )}
        {props.recProgram && (
          <GridItem colSpan={2}>
            <MachineAnalysisPreviewContent title="3. Rec Program" description={props.recProgram} />
          </GridItem>
        )}
        {props.usmOffice && (
          <GridItem colSpan={2}>
            <MachineAnalysisPreviewContent title="4. USM Office" description={props.usmOffice} />
          </GridItem>
        )}
        {props.voluntarySurrenderStatus && (
          <GridItem colSpan={2}>
            <MachineAnalysisPreviewContent
              title="5. Voluntary Surrender Status"
              description={props.voluntarySurrenderStatus == 0 ? "No" : "Yes"}
            />
          </GridItem>
        )}
        {props.VSDate && (
          <GridItem colSpan={1}>
            <MachineAnalysisPreviewContent title="5 (a). V/S Date" description={props.VSDate} />
          </GridItem>
        )}
        {props.VSLocation && (
          <GridItem colSpan={1}>
            <MachineAnalysisPreviewContent title="5 (b). V/S Location" description={props.VSLocation} />
          </GridItem>
        )}
      </Grid>
      <Stack divider={<Divider />} spacing={3}>
        {props.monthToRelease && (
          <MachineAnalysisPreviewContent title="6. Month to Release" description={props.monthToRelease} />
        )}
        {props.levelOfSeverity && (
          <Flex justifyContent="space-between">
            <MachineAnalysisPreviewContent
              title="7.Severity of Current Offense"
              description={SEVERITY_OPTIONS.find(({ value }) => value == props.levelOfSeverity)?.label}
            />
            <Input value={props.levelOfSeverity} isDisabled textAlign="center" className="input_ma_preview" />
          </Flex>
        )}
        {(props.criminalHistoryPoints || props.sourceOfDocDate || props.sourceOfDoc) && (
          <Grid templateColumns="repeat(4, 1fr)" gap={3}>
            {props.criminalHistoryPoints && (
              <GridItem colSpan={2}>
                <Flex justifyContent="space-between">
                  <MachineAnalysisPreviewContent
                    title="8. Criminal History Points"
                    description={props.criminalHistoryTotalPoints}
                  />
                  <Input
                    value={props.criminalHistoryPoints}
                    isDisabled
                    textAlign="center"
                    className="input_ma_preview"
                  />
                </Flex>
              </GridItem>
            )}
            {props.sourceOfDocDate && (
              <GridItem colSpan={1}>
                <MachineAnalysisPreviewContent title="8 (a). Source Doc Date" description={props.sourceOfDocDate} />
              </GridItem>
            )}
            {props.sourceOfDoc && (
              <GridItem colSpan={1}>
                <MachineAnalysisPreviewContent title="8 (b). Source of Doc" description={props.sourceOfDoc} />
              </GridItem>
            )}
          </Grid>
        )}
        {props.historyOfViolence && (
          <Flex justifyContent="space-between">
            <MachineAnalysisPreviewContent
              title="9. History of Violence"
              description={HISTORY_OF_VIOLENCE_OPTIONS.find(({ value }) => value == props.historyOfViolence)?.label}
            />
            <Input value={props.historyOfViolence} isDisabled textAlign="center" className="input_ma_preview" />
          </Flex>
        )}
        {props.historyOfEscape && (
          <Flex justifyContent="space-between">
            <MachineAnalysisPreviewContent
              title="10. History of Escape or Attempts"
              description={HISTORY_OF_ESCAPE_OPTIONS.find(({ value }) => value == props.historyOfEscape)?.label}
            />
            <Input value={props.historyOfEscape} isDisabled textAlign="center" className="input_ma_preview" />
          </Flex>
        )}
        {props.typeOfDetainer && (
          <Flex justifyContent="space-between">
            <MachineAnalysisPreviewContent
              title="11. Type of Detainer"
              description={TYPE_OF_DETAINER_OPTIONS.find(({ value }) => value == props.typeOfDetainer)?.label}
            />
            <Input value={props.typeOfDetainer} isDisabled textAlign="center" className="input_ma_preview" />
          </Flex>
        )}
        {props.age && (
          <Flex justifyContent="space-between">
            <MachineAnalysisPreviewContent
              title="12. Age"
              description={AGE_OPTIONS.find(({ value }) => value == props.age)?.label}
            />
            <Input value={props.age} isDisabled textAlign="center" className="input_ma_preview" />
          </Flex>
        )}
        {props.educationLevel && (
          <Grid templateColumns="repeat(5, 1fr)" gap={3}>
            <GridItem colSpan={3}>
              <Flex justifyContent="space-between">
                <MachineAnalysisPreviewContent
                  title="13. Education Level"
                  description={EDUCATION_LEVEL_OPTIONS.find(({ value }) => value == props.educationLevel)?.label}
                />
                <Input value={props.educationLevel} isDisabled textAlign="center" className="input_ma_preview" />
              </Flex>
            </GridItem>
            <GridItem colSpan={2}>
              <MachineAnalysisPreviewContent title="13 (a). Highest Grade Compl." description={props.highestGrade} />
            </GridItem>
          </Grid>
        )}
        {props.substanceAbuse && (
          <Flex justifyContent="space-between">
            <MachineAnalysisPreviewContent
              title="14. Substance Abuse"
              description={DRUG_ALCOHOL_OPTIONS.find(({ value }) => value == props.substanceAbuse)?.label}
            />
            <Input value={props.substanceAbuse} isDisabled textAlign="center" className="input_ma_preview" />
          </Flex>
        )}
        {props.total && (
          <Flex justifyContent="space-between">
            <MachineAnalysisPreviewContent title="15. Total Score" />
            <Input value={props.total} isDisabled textAlign="center" className="input_ma_preview" />
          </Flex>
        )}
        {props.publicSafetyFactors && (
          <Flex justifyContent="space-between">
            <MachineAnalysisPreviewContent title="16. Public Safety Factors" />
            <Input value={props.publicSafetyFactors} isDisabled textAlign="center" className="input_ma_preview" />
          </Flex>
        )}
        {props.remarks && <MachineAnalysisPreviewContent title="17. Remarks" description={props.remarks} />}
        {props.omdtReferral && (
          <MachineAnalysisPreviewContent title="18. OMDT Referral" description={props.omdtReferral} />
        )}
      </Stack>
    </MachineAnalysisPreviewBlock>
  );
};

export default MachineAnalysisPreviewBlockSecurityDesignation;
