import React, { FC } from "react";
import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { FONT_WEIGHT } from "@assets/theme/foundations/textStyle";
import { ReactComponent as LogoIcon } from "@assets/files/images/icons/logoIcon.svg";

interface MachineAnalysisPreviewHeaderProps {
  name: string;
}

const MachineAnalysisPreviewHeader: FC<MachineAnalysisPreviewHeaderProps> = ({ name }) => (
  <Flex>
    <Box display="flex" alignItems="center" p={3} bg="brand.primary" borderRadius={8} mr={3}>
      <Icon boxSize={5} color="white" as={LogoIcon} />
    </Box>
    <Flex flexDir="column" justifyContent="space-between" className="block_title">
      <Text fontSize={20} fontWeight={FONT_WEIGHT.HEAVY} fontFamily="heading">
        {`Prisonology - ${name} Preview`}
      </Text>
      <Text fontSize={14}>Mitigation Analysis</Text>
    </Flex>
  </Flex>
);

export default MachineAnalysisPreviewHeader;
