export const Modal = {
  sizes: {
    popup: {
      dialog: { maxWidth: "400px" },
    },
    uploadPopup: {
      dialog: { maxWidth: "584px" },
    },
  },
};
