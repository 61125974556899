import React, { FC } from "react";
import { Box, Flex, keyframes, Text } from "@chakra-ui/react";
import { FONT_WEIGHT } from "@assets/theme/foundations/textStyle";

const rotate = keyframes`
  100% {
    transform: rotate(1turn)
  }
`;

const Loader: FC = () => {
  return (
    <Flex flexDirection="column" zIndex={2} bg="white" p={6} borderRadius={8}>
      <Box
        sx={{
          display: "grid",
          width: "32px",
          margin: "0 auto",
          background:
            "linear-gradient(0deg, rgb(224 110 65 / 50%) 30%, transparent 0 70%, rgb(224 110 65 / 100%) 0) 50% / 8% 100% no-repeat, linear-gradient(90deg, rgb(224 110 65 / 25%) 30%, transparent 0 70%, rgb(224 110 65 / 75%) 0) 50% / 100% 8% no-repeat",
          borderRadius: "50%",
          animation: `${rotate} 1s infinite steps(12)`,
          aspectRatio: "1",
          "&::before, &::after": {
            content: '""',
            background: "inherit",
            borderRadius: "50%",
            opacity: "0.915",
            transform: "rotate(30deg)",
            gridArea: "1/1",
          },
          "&::after": {
            opacity: "0.83",
            transform: "rotate(60deg)",
          },
        }}
      />
      <Text textAlign="center" mt={4} fontSize="12px" fontWeight={FONT_WEIGHT.NORMAL} color="brand.disabledBtnBg">
        Please wait a moment...
      </Text>
    </Flex>
  );
};

export default Loader;
