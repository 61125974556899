import React, { FC, useCallback, useState } from "react";
import { Box, Button, Flex, Image, Text, VStack, Icon } from "@chakra-ui/react";
import { showNotification } from "@shared/store/actions";
import secureUpload from "@assets/files/images/cases/secureUpload.png";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch } from "react-redux";
import { fileToBase64 } from "@shared/utils";
import { FONT_WEIGHT } from "@assets/theme/foundations/textStyle";
import { ReactComponent as Bag } from "@assets/files/images/icons/bag.svg";

import FileUploadFileInfo from "./FileUploadFileInfo";

import "./index.scss";

interface FileUploadProps {
  types?: string[];
  onUploadFile: (fileBase64: string) => void;
  maxFileSize?: number; //MB
}

const FileUpload: FC<FileUploadProps> = ({ types, maxFileSize, onUploadFile }) => {
  const dispatch = useDispatch();

  const [file, setFile] = useState<File | null>();

  const handleError = useCallback(
    (errorMessage: string) => {
      dispatch(
        showNotification({
          message: errorMessage,
          appearance: "error",
        }),
      );
    },
    [dispatch],
  );

  const handleChange = useCallback(
    async (newFile: File) => {
      if (!newFile) return;

      const convertedFile = await fileToBase64(newFile);
      setFile(newFile);
      onUploadFile(convertedFile);
    },
    [onUploadFile],
  );

  return (
    <Flex w="100%" flexDir="column">
      <FileUploader
        classes="file-upload"
        handleChange={handleChange}
        name="file"
        types={types}
        fileOrFiles={file}
        onTypeError={handleError}
        onSizeError={handleError}
        multiple={false}
        disabled={file}
        maxSize={maxFileSize}
      >
        <Flex
          cursor="pointer"
          flexDir="column"
          borderRadius="8px"
          borderStyle="dashed"
          borderWidth="2px"
          p={6}
          borderColor="gray.50"
          background="#F8F9FA"
          alignItems="center"
          justifyContent="center"
          w="100%"
          h={file ? "280px" : "unset"}
        >
          {!file ? (
            <>
              <Box>
                <Image src={secureUpload} alt="secure upload" />
              </Box>
              <Flex flexDir="column" alignItems="center" mt={6}>
                <Text fontSize="16px" fontWeight={FONT_WEIGHT.NORMAL}>
                  Drag & Drop file
                </Text>
                <Text fontSize="12px" fontWeight={FONT_WEIGHT.NORMAL} mt={2} color="brand.grey">
                  Supported format:{" "}
                  <Text as="span" fontWeight={FONT_WEIGHT.HEAVY}>
                    PDF
                  </Text>
                </Text>
                <VStack
                  fontSize="12px"
                  fontWeight={FONT_WEIGHT.NORMAL}
                  color="brand.grey"
                  spacing={2}
                  mt={4}
                  lineHeight="normal"
                >
                  <Text mb={2}>
                    <Text as="span" fontWeight={FONT_WEIGHT.HEAVY}>
                      Please Note:
                    </Text>{" "}
                    Only PSRs in PDF format will be handled. Scanned documents or any other files that contain images or
                    anything else besides text will not be processed.
                  </Text>
                  <Text>
                    The information provided on this website{" "}
                    <Text as="span" fontWeight={FONT_WEIGHT.HEAVY}>
                      does not, and is not intended to, constitute legal advice;
                    </Text>
                    &nbsp;instead, all information, content, and materials available on this site are for{" "}
                    <Text as="span" fontWeight={FONT_WEIGHT.HEAVY}>
                      general informational purposes only
                    </Text>
                    . Submission of a PSR for analysis is for planning purposes and should not be used for submission to
                    Court or as part of a declaration.
                  </Text>
                  <Text>
                    In the course of performing services hereunder, Prisonology acknowledges that it may come into
                    contact with or become familiar with information that the Client may consider confidential.
                    Prisonology agrees to keep the name and identity of the Client and the nature and scope of the work
                    provided by Prisonology to the Client confidential at all times.
                  </Text>
                </VStack>
                <Button type="submit" variant="primary" mt={6} w="176px" h="48px">
                  Browse
                </Button>
                <Text fontSize="12px" fontWeight={FONT_WEIGHT.NORMAL} color="brand.grey" mt={2}>
                  Maximum size: 15Mb
                </Text>
                <Text fontSize="12px" fontWeight={FONT_WEIGHT.NORMAL} color="brand.grey" mt={4}>
                  <Icon as={Bag} w="18px" h="18px" verticalAlign="text-bottom" />
                  &nbsp;
                  <Text as="span" fontWeight={FONT_WEIGHT.BOLD}>
                    FULLY SECURED
                  </Text>{" "}
                  SSL Upload
                </Text>
              </Flex>
            </>
          ) : (
            <Flex flexDir="column" alignItems="center">
              <Text fontSize="16px" fontWeight={FONT_WEIGHT.HEAVY}>
                Success!
              </Text>
              <Text
                fontSize="12px"
                textAlign="center"
                fontWeight={FONT_WEIGHT.NORMAL}
                mt="10px"
                mb="50px"
                color="brand.grey"
                w="250px"
              >
                Your PDF file is uploaded, now you can save it or remove and select another file
              </Text>
              <FileUploadFileInfo file={file} onDeleteFile={() => setFile(null)} />
            </Flex>
          )}
        </Flex>
      </FileUploader>
    </Flex>
  );
};

export default FileUpload;
