import config from "../../config";

export default {
  AUTH: {
    LOGIN: `${config.baseApiUrl}auth/login`,
    REGISTRATION: `${config.baseApiUrl}auth/registration`,
    FORGOT_PASSWORD: `${config.baseApiUrl}auth/password/recovery`,
    RESET_PASSWORD: `${config.baseApiUrl}auth/password`,
  },
  USER: {
    GET_USER_INFO: `${config.baseApiUrl}users/details`,
    GET_USERS_LIST: `${config.baseApiUrl}users`,
    GET_USERS_LIST_SUMMARY: `${config.baseApiUrl}users/summary`,
    CHECK_USER_PAYMENT_METHOD: (id: number) => `${config.baseApiUrl}users/${id}/payment-method`,
    GET_CHECKOUT_URL: (id: number) => `${config.baseApiUrl}users/${id}/checkout-url`,
    GET_BILLING_PORTAL_URL: (id: number) => `${config.baseApiUrl}users/${id}/billing-portal-url`,
  },
  CASE: {
    GET_CASES_LIST: `${config.baseApiUrl}cases`,
    GET_CASE: (id: number) => `${config.baseApiUrl}cases/${id}`,
    CREATE_CASE: `${config.baseApiUrl}cases`,
    DELETE_CASE: (id: number) => `${config.baseApiUrl}cases/${id}`,
    RESTART_PROCESSING_CASE: (id: number) => `${config.baseApiUrl}cases/${id}/restart-processing`,
    UPDATE_CASE_REPORT_STATUS: (id: number) => `${config.baseApiUrl}cases/${id}/report-status`,
    GET_CASE_FILE_SIGNED_URL: (id: number) => `${config.baseApiUrl}cases/${id}/input-file`,
    PAY_FOR_CASE: (id: number) => `${config.baseApiUrl}cases/${id}/payment`,
    UPDATE_CASE: (id: number) => `${config.baseApiUrl}cases/${id}`,
  },
};
