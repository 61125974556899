import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { tokenHandler } from "@shared/utils";

import { AuthStateType } from "../interface";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

export const initialState: AuthStateType = {
  authentificated: !!tokenHandler.get(),
  emailForRecoveryPassword: "",
};

const reducer = createReducer<AuthStateType, Action>(initialState)
  .handleAction(actions.login.success, (state) =>
    produce(state, (nextState) => {
      nextState.authentificated = true;
    }),
  )
  .handleAction(actions.logout.success, (state) =>
    produce(state, (nextState) => {
      nextState.authentificated = false;
      nextState.user = undefined;
    }),
  )
  .handleAction(actions.forgotPassword.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.emailForRecoveryPassword = action.payload.email;
    }),
  )
  .handleAction(actions.getUser.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.user = action.payload.user;
    }),
  );

export { reducer as AuthReducer };
