import { FC } from "react";
import { Grid, GridItem } from "@chakra-ui/react";

import MachineAnalysisPreviewBlock from "./MachineAnalysisPreviewBlock";
import MachineAnalysisPreviewContent from "./MachineAnalysisPreviewContent";

type MachineAnalysisPreviewBlockPersonalInfoData = Partial<
  Record<"date_of_birth" | "age" | "sex" | "race" | "educationLevel" | "city" | "state", string | number | undefined>
>;

type MachineAnalysisPreviewBlockPersonalInfoProps = MachineAnalysisPreviewBlockPersonalInfoData & { title?: string };

const fields = {
  date_of_birth: "1. Date of Birth",
  age: "2. Age",
  sex: "3. Gender",
  race: "4. Race",
  educationLevel: "5. Education Level",
  city: "6. City",
  state: "7. State",
};

const MachineAnalysisPreviewBlockPersonalInfo: FC<MachineAnalysisPreviewBlockPersonalInfoProps> = ({
  title,
  ...props
}) => {
  return (
    <MachineAnalysisPreviewBlock title={title}>
      <Grid templateColumns="repeat(4, 1fr)" gap={3} w="100%">
        {Object.entries(fields).map(([key, title]) => {
          let value = props[key as keyof MachineAnalysisPreviewBlockPersonalInfoData];
          if (key === "age") {
            const date_of_birth = props["date_of_birth"];
            value = date_of_birth ? `${new Date().getFullYear() - new Date(date_of_birth).getFullYear()}` : "";
          }
          return (
            value && (
              <GridItem colSpan={1} key={key}>
                <MachineAnalysisPreviewContent title={title} description={value} />
              </GridItem>
            )
          );
        })}
      </Grid>
    </MachineAnalysisPreviewBlock>
  );
};

export default MachineAnalysisPreviewBlockPersonalInfo;
