import React, { useCallback, useMemo } from "react";
import { Select } from "@shared/components";
import { FieldItemType, Option } from "@shared/interfaces";
import { Box, FormLabel } from "@chakra-ui/react";

import { RenderField, GenerateRenderField } from "../../interfaces";
import { onChangeFieldType } from "../../FormGenerator";

export interface SelectFieldItem extends RenderField {
  type: FieldItemType.SELECT;
  label?: string;
  options: Option<string>[];
  isClearable?: boolean;
  onChangeField?: onChangeFieldType;
}

export const GenerateSelect: GenerateRenderField<SelectFieldItem> = ({
  formikProps,
  options,
  name,
  wrapperClass,
  label,
  isClearable,
  onChangeField,
}) => {
  const currentOptionValue = useMemo(() => {
    return options.find((option) => option.value === formikProps.values[name]) || null;
  }, [options, name, formikProps.values]);

  const handleChange = useCallback(
    (option: Option<string> | null) => {
      const value = option?.value || null;
      formikProps.setFieldValue(name, value);
      onChangeField?.(name, value, null);
    },
    [onChangeField, formikProps, name],
  );

  return (
    <Box>
      <FormLabel textStyle="label">{label}</FormLabel>
      <Select
        name={name}
        options={options}
        value={currentOptionValue}
        className={wrapperClass}
        onChange={handleChange}
        isClearable={isClearable}
      />
    </Box>
  );
};
