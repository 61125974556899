import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import { BaseFilter } from "@shared/interfaces";
import { CaseModel } from "@shared/models";

import { CasesReportStatusDtoInterface } from "../interface/CasesReportStatusDto.interface";
import { CreateCaseDto } from "../interface";

export default {
  getCasesList: (payload: BaseFilter) => request(METHODS.GET, API.CASE.GET_CASES_LIST)({ params: payload }),
  getCase: (id: number) => request(METHODS.GET, API.CASE.GET_CASE(id))(),
  deleteCase: (id: number) => request(METHODS.DELETE, API.CASE.DELETE_CASE(id))(),
  restartProcessingCase: (id: number) => request(METHODS.POST, API.CASE.RESTART_PROCESSING_CASE(id))(),
  createCase: (payload: CreateCaseDto) => request(METHODS.POST, API.CASE.CREATE_CASE)(payload),
  updateCaseReportStatus: (id: number, payload: Pick<CasesReportStatusDtoInterface, "report_status">) =>
    request(METHODS.PATCH, API.CASE.UPDATE_CASE_REPORT_STATUS(id))(payload),
  getCaseFileSignedUrl: (id: number) => request(METHODS.GET, API.CASE.GET_CASE_FILE_SIGNED_URL(id))(),
  payForCase: (id: number) => request(METHODS.POST, API.CASE.PAY_FOR_CASE(id))(),
  updateCase: (id: number, payload: Omit<CaseModel, "id">) => request(METHODS.PATCH, API.CASE.UPDATE_CASE(id))(payload),
};
