import { FC, PropsWithChildren } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { FONT_WEIGHT } from "@assets/theme/foundations/textStyle";

interface MachineAnalysisPreviewContentProps {
  title: string;
  description?: string | number;
}

const MachineAnalysisPreviewContent: FC<PropsWithChildren<MachineAnalysisPreviewContentProps>> = ({
  title,
  description,
  children,
}) => {
  return (
    <Flex flexDir="column" justifyContent="center">
      <Text color="blue.700" textStyle="label" fontWeight={FONT_WEIGHT.NORMAL}>
        {title}
      </Text>
      <Text color="black" textStyle="label" whiteSpace="pre-wrap" fontWeight={FONT_WEIGHT.NORMAL}>
        {description}
      </Text>
      <Box>{children}</Box>
    </Flex>
  );
};

export default MachineAnalysisPreviewContent;
