import React, { FC, ReactNode } from "react";
import { Flex, FlexProps, Text } from "@chakra-ui/react";
import { FONT_WEIGHT } from "@assets/theme/foundations/textStyle";
import { ArrowForwardIcon } from "@chakra-ui/icons";

interface CaseResultValueProps extends FlexProps {
  value?: ReactNode;
  label: string;
  onClickScore?: () => void;
}

const CaseResultValue: FC<CaseResultValueProps> = ({ value, label, onClickScore, ...restProps }) => {
  return (
    <Flex direction="column" minW="170px" {...restProps}>
      <Flex align="center">
        <Text textStyle="label" mb="3px">
          {label}
        </Text>
        {value !== undefined && onClickScore && (
          <ArrowForwardIcon onClick={onClickScore} cursor="pointer" color="blue.300" />
        )}
      </Flex>
      <Text fontSize="14px" fontWeight={FONT_WEIGHT.NORMAL} whiteSpace="pre-wrap">
        {!value && value !== 0 ? "-" : value}
      </Text>
    </Flex>
  );
};

export default CaseResultValue;
