import { FC } from "react";
import { Grid, GridItem, Text } from "@chakra-ui/react";
import MachineAnalysisPreviewContent from "@containers/Cases/components/CaseViewContainer/MachineAnalysis/MachineAnalysisPreview/MachineAnalysisPreviewContent";
import { FONT_WEIGHT } from "@assets/theme/foundations/textStyle";

import MachineAnalysisPreviewBlock from "./MachineAnalysisPreviewBlock";

type MachineAnalysisPreviewBlockLegalInfoData = Partial<
  Record<
    | "usm_number"
    | "district"
    | "docket_number"
    | "judge"
    | "total_offense_level"
    | "criminalHistoryPoints"
    | "criminalHistoryTotalPoints"
    | "criminalHistoryCategory"
    | "countsOfConviction"
    | "plea"
    | "5k1"
    | "total"
    | "securityClassification"
    | "remarks",
    string | number | undefined
  >
> & { statusOfConviction: { description: string }[] };

type MachineAnalysisPreviewBlockLegalInfoProps = MachineAnalysisPreviewBlockLegalInfoData & { title?: string };

const MachineAnalysisPreviewBlockLegalInfo: FC<MachineAnalysisPreviewBlockLegalInfoProps> = ({ title, ...props }) => {
  return (
    <MachineAnalysisPreviewBlock title={title}>
      <Grid templateColumns="repeat(4, 1fr)" gap={3}>
        {props.usm_number && (
          <GridItem colSpan={1}>
            <MachineAnalysisPreviewContent title="1. Register No." description={props.usm_number} />
          </GridItem>
        )}
        {props.district && (
          <GridItem colSpan={2}>
            <MachineAnalysisPreviewContent title="2. District" description={props.district} />
          </GridItem>
        )}
        {props.docket_number && (
          <GridItem colSpan={1}>
            <MachineAnalysisPreviewContent title="3. Docket No" description={props.docket_number} />
          </GridItem>
        )}
        {props.judge && (
          <GridItem colSpan={3}>
            <MachineAnalysisPreviewContent title="4. Judge" description={props.judge} />
          </GridItem>
        )}
        {props.total_offense_level && (
          <GridItem colSpan={1}>
            <MachineAnalysisPreviewContent title="5. Offense Level" description={props.total_offense_level} />
          </GridItem>
        )}
        {props.criminalHistoryTotalPoints && (
          <GridItem colSpan={2}>
            <MachineAnalysisPreviewContent
              title="6. Criminal History Points"
              description={props.criminalHistoryTotalPoints}
            />
          </GridItem>
        )}
        {props.criminalHistoryCategory && (
          <GridItem colSpan={2}>
            <MachineAnalysisPreviewContent
              title="7. Criminal History Category"
              description={props.criminalHistoryCategory}
            />
          </GridItem>
        )}
        {props.statusOfConviction && (
          <GridItem colSpan={4}>
            <MachineAnalysisPreviewContent title="8. Statutes of Conviction">
              {props.statusOfConviction.map((item, index) => (
                <Text whiteSpace="pre-wrap" color="black" textStyle="label" fontWeight={FONT_WEIGHT.NORMAL} key={index}>
                  {item.description}
                </Text>
              ))}
            </MachineAnalysisPreviewContent>
          </GridItem>
        )}
        {props.countsOfConviction && (
          <GridItem colSpan={4}>
            <MachineAnalysisPreviewContent title="9. Counts of Conviction" description={props.countsOfConviction} />
          </GridItem>
        )}
        {props.plea && (
          <GridItem colSpan={2}>
            <MachineAnalysisPreviewContent title="10. Plea" description={props.plea} />
          </GridItem>
        )}
        {props["5k1"] && (
          <GridItem colSpan={2}>
            <MachineAnalysisPreviewContent title="11. 5k1.1" description={props["5k1"]} />
          </GridItem>
        )}
        {props.total && (
          <GridItem colSpan={2}>
            <MachineAnalysisPreviewContent title="12. Classification Points" description={props.total} />
          </GridItem>
        )}
        {props.securityClassification && (
          <GridItem colSpan={2}>
            <MachineAnalysisPreviewContent
              title="13. Security Classification"
              description={props.securityClassification}
            />
          </GridItem>
        )}
        {props.remarks && (
          <GridItem colSpan={4}>
            <MachineAnalysisPreviewContent title="14. Remarks" description={props.remarks} />
          </GridItem>
        )}
      </Grid>
    </MachineAnalysisPreviewBlock>
  );
};

export default MachineAnalysisPreviewBlockLegalInfo;
