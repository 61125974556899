import { DependencyList, useEffect, useRef } from "react";

export const useDidUpdate = (effect: () => void, dependencies: DependencyList = []) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      effect();
    } else {
      didMountRef.current = true;
    }
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useDidUpdate;
