import { UserShortModel } from "@shared/models/user";

import { CaseMessageModel } from "./CaseMessage";

export enum CASE_STATUS {
  RUNNING = "Running",
  SUCCESS = "Success",
  FAILURE = "Failure",
  PENDING = "Pending",
  INVALID = "Invalid",
}

export enum REPORT_STATUS {
  NEW = "new",
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
}

export enum PAID_STATUS {
  NOT_PAID = "not_paid",
  PENDING = "pending",
  ERROR = "error",
  PAID = "paid",
}

export interface CaseModel {
  id: number;
  pdf_url: string;
  pdf_s3_url: string;
  status: CASE_STATUS;
  level_of_severity: number | null;
  usm_number: string | null;
  fbi_number: string | null;
  judge: string | null;
  voluntary_surrender_status: number | null;
  criminal_history_points: number | null;
  criminal_history_total_points: number | null;
  history_of_violence: number | null;
  history_of_escape: number | null;
  type_of_detainer: number | null;
  age: number | null;
  substance_abuse: number | null;
  public_safety_factors: string | null;
  remarks: string | null;
  med_history: string | null;
  drug_history: string | null;
  messages: CaseMessageModel[];
  report_status: REPORT_STATUS;

  is_deleted: boolean;

  created_at: Date;
  updated_at: Date;
  created_by: string;
  updated_by: string;

  user?: UserShortModel;

  paid_status: PAID_STATUS;

  docket_number: string | null;
  district: string | null;

  ssn_number: string | null;
  total_offense_level: string | null;
  foreign_country: string | null;
  ars_assignment: string | null;
  rec_facility: string | null;
  rec_program: string | null;
  usm_office: string | null;
  criminal_history_info: Record<string, string> | null;
  criminal_history_category: number | null;
  security_classification: string | null;

  type_of_prison: string | null;
  general_recidivism: string | null;
  first_step_act_eligible: string | null;
  second_step_act_eligible: string | null;
  risk_of_violence_recidivism: string | null;
  initial_pattern_score: string | null;
  recidivism: string | null;
  mental_health_notes: string | null;
  program_recommendations: string | null;
  drug_program: string | null;
  vs_date: string | null;
  vs_location: string | null;
  month_to_release: string | null;
  source_doc_date: string | null;
  source_of_doc: string | null;
  highest_grade: string | null;
  omdt_referral: string | null;
  history_of_escape_status: string | null;

  case_person_details: {
    first_name: string | null;
    last_name: string | null;
    date_of_birth: string | null;
    sex: string | null;
    race: string | null;
    citizenship: string | null;
    middle_name: string | null;
    suffix: string | null;
    ssn: string;
    ethnic_origin: string | null;
    state_of_birth: string | null;
    country_of_birth: string | null;
    street: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
    height: string | null;
    weight: string | null;
    hair_color: string | null;
    eyes_color: string | null;
    education_level: number | null;
  } | null;
}
