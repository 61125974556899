import { FC, useCallback, useMemo, useState } from "react";
import { Select } from "@shared/components";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "@containers/Users/store";
import { useDebounce, useDidUpdate, useGetPaginateHandlers } from "@shared/hooks";
import { getFullName } from "@shared/utils";
import { UserShortModel } from "@shared/models";
import "./index.scss";
import { Option } from "@shared/interfaces";

interface CaseListCreatedByFilterProps {
  onSelectUser: (user_id?: number) => void;
}

const CaseListCreatedByFilter: FC<CaseListCreatedByFilterProps> = ({ onSelectUser }) => {
  const [selectedUser, setSelectedUser] = useState<Option<string> | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const debounceValue = useDebounce<string>(inputValue, 500);

  const dispatch = useDispatch();
  const usersList = useSelector(selectors.getShortUsersList());
  const filter = useSelector(selectors.getShortUsersListFilter());

  const usersTotal = useSelector(selectors.getShortUsersTotalCount());

  const { onNextPage, onChangeSearch } = useGetPaginateHandlers({
    filter,
    changeFilterAction: actions.setShortUsersListFilter,
    total: usersTotal,
  });

  const onMenuOpen = useCallback(() => dispatch(actions.getShortUsers.request(filter)), [dispatch, filter]);

  useDidUpdate(() => {
    dispatch(actions.getShortUsers.request({ ...filter, isLoadingBlocked: true }));
  }, [filter]);

  useDidUpdate(() => {
    onChangeSearch(debounceValue);
  }, [debounceValue]);

  const options = useMemo(() => {
    return (
      usersList?.map((user) => ({
        label: getFullName<UserShortModel>(user, "first_name"),
        value: user.id.toString(),
      })) || []
    );
  }, [usersList]);

  const onClearClick = useCallback(() => {
    setSelectedUser(null);
    onSelectUser();
    dispatch(actions.setShortUsersListFilter({ ...filter, page: 0, search: "" }));
  }, [dispatch, filter, onSelectUser]);

  const onChange = useCallback(
    (value: Option<string> | null) => {
      if (value?.value) {
        onSelectUser(Number(value?.value));
      } else {
        onClearClick();
      }
      setSelectedUser(value);
    },
    [onClearClick, onSelectUser],
  );

  const onChangeInputValue = useCallback((value: string) => setInputValue(value), []);

  return (
    <Select
      className="cases-list-select"
      classNamePrefix="created-by"
      onMenuOpen={onMenuOpen}
      placeholder="Filter by users"
      isClearable
      onMenuScrollToBottom={onNextPage}
      onChange={onChange}
      inputValue={inputValue}
      onInputChange={onChangeInputValue}
      value={selectedUser}
      options={options}
      name="created_by"
    />
  );
};

export default CaseListCreatedByFilter;
