import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { UsersListFilter } from "@shared/interfaces";

import * as actions from "./actions";
import { UsersStateType } from "../interface";

type Action = ActionType<typeof actions>;

const defaultUserShortFilter: UsersListFilter = {
  page: 0,
  limit: 8,
  search: "",
};

const defaultUserSummaryFilter: UsersListFilter = {
  ...defaultUserShortFilter,
  limit: 50,
};

export const initialState: UsersStateType = {
  usersShort: {
    rows: null,
    totalCount: 0,
    filter: { ...defaultUserShortFilter },
  },
  usersSummary: {
    rows: null,
    totalCount: 0,
    filter: { ...defaultUserSummaryFilter },
  },
};

const reducer = createReducer<UsersStateType, Action>(initialState)
  .handleAction(actions.getShortUsers.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, clear, count } = action.payload;
      const stateRows = nextState.usersShort.rows || [];
      nextState.usersShort.rows = !clear ? [...stateRows, ...rows] : [...rows];
      nextState.usersShort.totalCount = count;
    }),
  )
  .handleAction(actions.setShortUsersListFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.usersShort.filter = action.payload || { ...defaultUserShortFilter };
    }),
  )
  .handleAction(actions.setSummaryUsersListFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.usersSummary.filter = action.payload || { ...defaultUserSummaryFilter };
    }),
  )
  .handleAction(actions.getSummaryUsers.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, clear, count } = action.payload;
      const stateRows = nextState.usersSummary.rows || [];
      nextState.usersSummary.rows = !clear ? [...stateRows, ...rows] : [...rows];
      nextState.usersSummary.totalCount = count;
    }),
  );

export { reducer as UsersReducer };
