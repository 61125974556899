import { createSelector } from "reselect";
import { AppState } from "@shared/interfaces/State.interface";
import { RoleName } from "@shared/models";

const selectAuth = (state: AppState) => state.auth;

export const authentificated = () => createSelector(selectAuth, (state) => state.authentificated);

export const getEmailForRecoveryPassword = () => createSelector(selectAuth, (state) => state.emailForRecoveryPassword);

export const getUser = () => createSelector(selectAuth, (state) => state.user);

export const getUserRoles = () => createSelector(getUser(), (user) => user?.roles);

export const isAdmin = () =>
  createSelector(getUserRoles(), (roles) => roles?.some((role) => role.name === RoleName.admin));
