import React, { FC } from "react";
import { Box, HStack, IconButton, Text } from "@chakra-ui/react";
import { AddIcon, ChevronLeftIcon, DownloadIcon, EditIcon, MinusIcon } from "@chakra-ui/icons";
import { useControls } from "react-zoom-pan-pinch";
import { generateFormInitialValues } from "@containers/Cases/components/CaseViewContainer/MachineAnalysis/MachineAnalysis.utils";
import { useDownloadCaseForm } from "@shared/hooks";
import { getFullName } from "@shared/utils";
import { MachineAnalysisDownload } from "@containers/Cases/components/CaseViewContainer/MachineAnalysis/MachineAnalysisDownload";

export interface MachineAnalysisPreviewModalFooterControlsProps {
  onAddPage: () => void;
  onSubtractPage: () => void;
  maxPage: number;
  minPage: number;
  currentPage: number;
  scale: number;
  onClose: () => void;
  caseObjUpdatedValues: ReturnType<typeof generateFormInitialValues>;
}

const MachineAnalysisPreviewModalFooterControls: FC<MachineAnalysisPreviewModalFooterControlsProps> = ({
  onAddPage,
  onSubtractPage,
  maxPage,
  minPage,
  currentPage,
  scale,
  onClose,
  caseObjUpdatedValues,
}) => {
  const { zoomIn, zoomOut } = useControls();

  const { handleDownloadPaf, isDownloading, targetRef } = useDownloadCaseForm(getFullName(caseObjUpdatedValues, ""));

  return (
    <HStack px={4} py={2.5} bg="blue.900" borderRadius={8} divider={<Box w={0.2} h="100%" />}>
      <HStack color="white" textStyle="label">
        <IconButton
          h="auto"
          aria-label={"zoom-in"}
          icon={<MinusIcon color="white" boxSize={2} />}
          onClick={() => zoomOut()}
          variant="transparent"
        />
        <Text>{scale}%</Text>
        <IconButton
          h="auto"
          aria-label={"zoom-in"}
          icon={<AddIcon color="white" boxSize={2} />}
          onClick={() => zoomIn()}
          variant="transparent"
        />
      </HStack>

      <HStack spacing={2}>
        <IconButton
          h="auto"
          aria-label={"Previous"}
          icon={<ChevronLeftIcon color="white" boxSize={4} />}
          onClick={onSubtractPage}
          isDisabled={currentPage === minPage}
          variant="transparent"
        />
        <HStack spacing={1} textStyle="label" color="white">
          <Text>{currentPage}</Text>
          <Text>/</Text>
          <Text>{maxPage}</Text>
        </HStack>
        <IconButton
          h="auto"
          aria-label={"Next"}
          icon={<ChevronLeftIcon boxSize={4} color="white" transform="rotate(180deg)" />}
          onClick={onAddPage}
          isDisabled={currentPage === maxPage}
          variant="transparent"
        />
      </HStack>

      <HStack spacing={1}>
        <IconButton
          h="auto"
          aria-label={"edit"}
          icon={<EditIcon color="white" />}
          onClick={onClose}
          variant="transparent"
        />
        <IconButton
          h="auto"
          aria-label={"download"}
          icon={<DownloadIcon color="white" />}
          onClick={handleDownloadPaf}
          variant="transparent"
        />
      </HStack>
      {!!caseObjUpdatedValues && isDownloading && (
        <MachineAnalysisDownload targetRef={targetRef} caseObjUpdatedValues={caseObjUpdatedValues} />
      )}
    </HStack>
  );
};

export default MachineAnalysisPreviewModalFooterControls;
