import { FC, ReactNode } from "react";
import { Center, HStack, Image, Text } from "@chakra-ui/react";
import { FONT_FAMILY, FONT_WEIGHT } from "@assets/theme/foundations/textStyle";

interface EmptyStateInstructionCardProps {
  num: number;
  text: ReactNode;
  imgSrc: string;
}

const EmptyStateInstructionCard: FC<EmptyStateInstructionCardProps> = ({ num, text, imgSrc }) => {
  return (
    <HStack
      justifyContent="space-between"
      position="relative"
      h="120px"
      bgColor="#F2F4F6"
      borderRadius="8px"
      p="16px 16px 16px 48px"
      w="full"
      sx={{
        "@media screen and (min-height: 800px)": {
          h: "156px",
        },
      }}
    >
      <Center
        borderRadius="50%"
        borderWidth="3px"
        borderColor="brand.white"
        position="absolute"
        left="-24px"
        top="calc (50% - 24px)"
        bgColor="#F2F4F6"
        w="48px"
        h="48px"
        fontFamily={FONT_FAMILY.SECONDARY}
        fontSize="24px"
        fontWeight={FONT_WEIGHT.HEAVY}
      >
        {num}
      </Center>
      <Text fontSize="16px" fontFamily={FONT_FAMILY.PRIMARY} fontWeight={FONT_WEIGHT.HEAVY} color="#404040">
        {text}
      </Text>
      <Image src={imgSrc} h="full" />
    </HStack>
  );
};

export default EmptyStateInstructionCard;
