import { all, fork } from "redux-saga/effects";

import { saga as sharedSaga } from "../shared/store";
import { saga as authSaga } from "../containers/Auth/store";
import { saga as casesSaga } from "../containers/Cases/store";
import { saga as usersSaga } from "../containers/Users/store";

const allSagas = [sharedSaga, authSaga, casesSaga, usersSaga];

export default function* appSagas() {
  yield all(allSagas.map(fork));
}
