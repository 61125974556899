import { CaseModel } from "@shared/models";
import { Flex, Text } from "@chakra-ui/react";
import { formatDateToLongUSFormat, getFullName } from "@shared/utils";

import CasePrisonMigrationAnalysisViewSimpleRow from "./CasePrisonMigrationAnalysisViewSimpleRow";

interface CasePrisonMigrationAnalysisViewProps {
  caseObj?: CaseModel;
}

const currentDate = formatDateToLongUSFormat(new Date());

const CasePrisonMigrationAnalysisView = ({ caseObj }: CasePrisonMigrationAnalysisViewProps) => {
  return (
    <Flex direction="column" gap={2} px={16}>
      <Flex justify="center">
        <Text textStyle="title" px={5} py={3} bg="brand.modalHeaderText" color="white">
          Prisonology
        </Text>
      </Flex>
      <CasePrisonMigrationAnalysisViewSimpleRow
        justifyContent="space-between"
        field="Prison Mitigation Analysis"
        value={`Date: ${currentDate}`}
      />
      <CasePrisonMigrationAnalysisViewSimpleRow field="Defendant:" value={getFullName(caseObj, "")} />
      <CasePrisonMigrationAnalysisViewSimpleRow
        field="Date of Birth:"
        value={caseObj?.case_person_details?.date_of_birth}
      />
      <CasePrisonMigrationAnalysisViewSimpleRow field="Age:" value={caseObj?.age} />
      <CasePrisonMigrationAnalysisViewSimpleRow field="Gender:" value={caseObj?.case_person_details?.sex} />
      <CasePrisonMigrationAnalysisViewSimpleRow field="Race:" value={caseObj?.case_person_details?.race} />
      <CasePrisonMigrationAnalysisViewSimpleRow
        field="Education Level:"
        value={caseObj?.case_person_details?.education_level === 0 ? "Yes" : "No"}
      />
      <CasePrisonMigrationAnalysisViewSimpleRow field="District:" value={caseObj?.district} />
      <CasePrisonMigrationAnalysisViewSimpleRow field="Docket No:" value={caseObj?.docket_number} />
    </Flex>
  );
};

export default CasePrisonMigrationAnalysisView;
