import { FC } from "react";
import { Flex, FlexProps, Text, TextProps } from "@chakra-ui/react";

interface CasePrisonMigrationAnalysisViewSimpleRowProps extends FlexProps {
  field: string;
  value: string | undefined | null | number;
  fieldProps?: TextProps;
  valueProps?: TextProps;
}

const CasePrisonMigrationAnalysisViewSimpleRow: FC<CasePrisonMigrationAnalysisViewSimpleRowProps> = ({
  field,
  value,
  fieldProps,
  valueProps,
  ...restProps
}) => {
  return (
    <Flex {...restProps}>
      <Text mr={2} {...fieldProps}>
        {`${field} `}
      </Text>
      <Text {...valueProps}>{value}</Text>
    </Flex>
  );
};

export default CasePrisonMigrationAnalysisViewSimpleRow;
