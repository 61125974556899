import {
  ActionWithPayload,
  PaginatedResponse,
  BaseFilter,
  CreateEditResponse,
  WithCallback,
  WithLoaderFlag,
} from "@shared/interfaces";
import { call, put, takeLatest } from "redux-saga/effects";
import { navigate, startLoading, stopLoading } from "@shared/store/actions";
import { CASE_STATUS, CaseModel } from "@shared/models";
import { NameOfRoutes } from "@shared/constants";

import { CasesReportStatusDtoInterface } from "../interface/CasesReportStatusDto.interface";
import api from "../api";
import {
  createCase,
  deleteCase,
  getCase,
  getCaseData,
  getCaseFileSignedUrl,
  getCasesList,
  payForCase,
  restartProcessingCase,
  updateCase,
  updateCaseReportStatus,
} from "./actions";
import { CreateCaseDto } from "../interface";

function* getCasesListSaga({ payload }: ActionWithPayload<WithLoaderFlag<BaseFilter>>) {
  const { isLoadingBlocked, ...restPayload } = payload;
  try {
    if (!isLoadingBlocked) yield put(startLoading());
    const response: PaginatedResponse<CaseModel> = yield call(api.getCasesList, restPayload);
    yield put(
      getCasesList.success({
        ...response,
        clear: !payload.page,
      }),
    );
  } catch (error) {
    yield put(getCasesList.failure(error as Error));
  } finally {
    if (!isLoadingBlocked) yield put(stopLoading());
  }
}

function* createCaseSaga({ payload }: ActionWithPayload<WithCallback<CreateCaseDto, CaseModel>>) {
  const { cb, ...rest } = payload;
  try {
    yield put(startLoading());
    const response: CreateEditResponse<CaseModel> = yield call(api.createCase, rest);
    if (response.element.status !== CASE_STATUS.INVALID && response.element.status !== CASE_STATUS.FAILURE) {
      yield put(createCase.success(response));
    }
    cb?.(response.element);
  } catch (error) {
    yield put(createCase.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* deleteCaseSaga({ payload }: ActionWithPayload<WithCallback<{ caseId: number }, undefined>>) {
  const { cb, caseId } = payload;
  try {
    yield put(startLoading());
    yield call(api.deleteCase, caseId);
    cb?.();
  } catch (error) {
    yield put(getCasesList.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* updateCaseSaga({ payload }: ActionWithPayload<WithCallback<CaseModel, CaseModel>>) {
  try {
    yield put(startLoading());
    const { id, cb, ...rest } = payload;
    const response: CreateEditResponse<CaseModel> = yield call(api.updateCase, id, rest);
    yield put(updateCase.success(response));
    cb?.(response.element);
  } catch (error) {
    yield put(updateCase.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* getCaseSaga({ payload }: ActionWithPayload<WithLoaderFlag<{ caseId: number }>>) {
  try {
    if (!payload.isLoadingBlocked) yield put(startLoading());
    const response: CaseModel = yield call(api.getCase, payload.caseId);
    yield put(getCase.success(response));
  } catch (error) {
    yield put(getCase.failure(error as Error));
    yield put(navigate(NameOfRoutes.CASES_LIST));
  } finally {
    if (!payload.isLoadingBlocked) yield put(stopLoading());
  }
}

function* getCaseDataSaga({ payload }: ActionWithPayload<WithCallback<{ caseId: number }, CaseModel>>) {
  try {
    const { caseId, cb } = payload;
    yield put(startLoading());
    const response: CaseModel = yield call(api.getCase, caseId);
    cb?.(response);
    yield put(getCaseData.success(response));
  } catch (error) {
    yield put(getCaseData.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* restartProcessingCaseSaga({ payload }: ActionWithPayload<number>) {
  try {
    yield put(startLoading());
    const response: CreateEditResponse<CaseModel> = yield call(api.restartProcessingCase, payload);
    yield put(restartProcessingCase.success(response));
  } catch (error) {
    yield put(restartProcessingCase.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* updateCaseReportStatusSaga({
  payload,
}: ActionWithPayload<WithCallback<CasesReportStatusDtoInterface, CasesReportStatusDtoInterface>>) {
  const { cb, id, ...rest } = payload;
  try {
    yield put(startLoading());
    const response: CreateEditResponse<CaseModel> = yield call(api.updateCaseReportStatus, id, rest);
    yield put(updateCaseReportStatus.success(response.element));
    cb?.(response.element);
  } catch (error) {
    yield put(updateCaseReportStatus.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* getCaseFileSignedUrlSaga({ payload }: ActionWithPayload<number>) {
  try {
    yield put(startLoading());
    const response: { url: string } = yield call(api.getCaseFileSignedUrl, payload);
    window.open(response.url, "_blank");
  } catch (error) {
    yield put(getCaseFileSignedUrl.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* payForCaseSaga({ payload }: ActionWithPayload<number>) {
  try {
    yield put(startLoading());
    yield call(api.payForCase, payload);
    yield put(getCase.request({ caseId: payload }));
  } catch (error) {
    yield put(getCase.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* casesSaga() {
  yield takeLatest(getCasesList.request, getCasesListSaga);
  yield takeLatest(createCase.request, createCaseSaga);
  yield takeLatest(getCase.request, getCaseSaga);
  yield takeLatest(restartProcessingCase.request, restartProcessingCaseSaga);
  yield takeLatest(updateCaseReportStatus.request, updateCaseReportStatusSaga);
  yield takeLatest(getCaseFileSignedUrl.request, getCaseFileSignedUrlSaga);
  yield takeLatest(payForCase.request, payForCaseSaga);
  yield takeLatest(deleteCase.request, deleteCaseSaga);
  yield takeLatest(updateCase.request, updateCaseSaga);
  yield takeLatest(getCaseData.request, getCaseDataSaga);
}

export default casesSaga;
