export const FONT_WEIGHT = {
  BOLD: 700,
  HEAVY: 600,
  NORMAL: 400,
};

export const FONT_FAMILY = {
  PRIMARY: "Open Sans",
  SECONDARY: "Crimson Text",
};

const textStyles = {
  label: {
    fontWeight: FONT_WEIGHT.NORMAL,
    fontSize: "12px",
    color: "blue.700",
  },
  title: {
    fontSize: "32px",
    fontFamily: FONT_FAMILY.SECONDARY,
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: "41px",
    color: "brand.black",
  },
  subtitle: {
    fontSize: "26px",
    fontFamily: FONT_FAMILY.SECONDARY,
    fontWeight: FONT_WEIGHT.HEAVY,
    lineHeight: "33.79px",
    color: "brand.black",
    letterSpacing: "1px",
  },
  noMatchesTitle: {
    fontSize: "32px",
    fontFamily: FONT_FAMILY.SECONDARY,
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: "41px",
    color: "brand.emptyStateText",
  },
  noMatchesSubtitle: {
    fontSize: "14px",
    fontFamily: FONT_FAMILY.PRIMARY,
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: "19px",
    color: "brand.emptyStateText",
  },
  modalTitle: {
    fontSize: "14px",
    fontFamily: FONT_FAMILY.PRIMARY,
    fontWeight: FONT_WEIGHT.HEAVY,
    lineHeight: "32px",
    color: "brand.modalHeaderText",
  },
  overlayTitle: {
    fontSize: "16px",
    fontFamily: FONT_FAMILY.PRIMARY,
    fontWeight: FONT_WEIGHT.HEAVY,
    lineHeight: "normal",
    color: "blue.900",
  },
  listWithIcons: {
    fontSize: "16px",
    fontFamily: FONT_FAMILY.PRIMARY,
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: "28px",
  },
};

export type TextStyles = typeof textStyles;

export default textStyles;
