import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  FormHelperText,
  InputLeftElement,
  FormControlProps,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useState, FC } from "react";
import { Field } from "formik";

import "./index.scss";

interface InputProps extends ChakraInputProps {
  label?: string;
  isShowPasswordIcon?: boolean;
  inputLeftElement?: ChakraInputProps["children"];
  inputRightElement?: ChakraInputProps["children"];
  showValidCheckmark?: boolean;
  isRequiredField?: boolean;
  helpText?: string;
  formControlProps?: FormControlProps;
}

const Input: FC<InputProps> = ({
  label,
  isShowPasswordIcon,
  inputLeftElement,
  inputRightElement,
  showValidCheckmark,
  isRequiredField,
  helpText,
  formControlProps,
  ...inputProps
}) => {
  const [isShowPassword, setShowPassword] = useState(false);

  return (
    <FormControl isRequired={isRequiredField} mt={4} className="input" {...formControlProps}>
      {label && <FormLabel>{label}</FormLabel>}
      <InputGroup>
        <Field
          as={ChakraInput}
          _focusVisible={{ boxShadow: "transparent" }}
          variant="outline"
          bg="brand.white"
          borderRadius="base"
          {...inputProps}
          type={isShowPassword ? "text" : inputProps.type}
        />
        {isShowPasswordIcon && (
          <InputRightElement
            h="100%"
            cursor="pointer"
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={() => setShowPassword(!isShowPassword)}
          >
            {!isShowPassword ? <ViewOffIcon color="brand.white" /> : <ViewIcon color="brand.white" />}
          </InputRightElement>
        )}

        {inputLeftElement && (
          <InputLeftElement display="flex" h="100%" w="35px" alignItems="center" justifyContent="center">
            {inputLeftElement}
          </InputLeftElement>
        )}

        {inputRightElement && (showValidCheckmark === undefined || showValidCheckmark) && (
          <InputRightElement display="flex" h="100%" w="35px" alignItems="center" justifyContent="center">
            {inputRightElement}
          </InputRightElement>
        )}
      </InputGroup>
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  );
};

export default Input;
