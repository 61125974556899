import { FC } from "react";
import { RadioGroup, Stack, Radio as ChakraRadio, FormControl, FormLabel, chakra } from "@chakra-ui/react";
import { Field, useField } from "formik";

interface RadioProps {
  options: { title: string; value: string }[];
  name: string;
  label: string;
}

const Radio: FC<RadioProps> = ({ options, name, label }) => {
  const [field] = useField(name);

  return (
    <FormControl mt={4} className="input" width="100%">
      {label && <FormLabel>{label}</FormLabel>}
      <RadioGroup name={name} width="100%" value={field.value}>
        <Stack direction="row" w="100%">
          {options.map((option) => (
            <chakra.label key={option.value} display="flex" alignItems="center" gap={2}>
              <Field as={ChakraRadio} value={option.value} type="radio" />
              {option.title}
            </chakra.label>
          ))}
        </Stack>
      </RadioGroup>
    </FormControl>
  );
};

export default Radio;
