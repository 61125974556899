import React, { FC, PropsWithChildren } from "react";
import { Badge, Collapse, Flex, useDisclosure } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

interface CaseResultSectionProps {
  title: string;
}

const CaseResultSection: FC<PropsWithChildren<CaseResultSectionProps>> = ({ title, children }) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  return (
    <Flex flexDirection="column" w="full">
      <Flex justifyContent="space-between">
        <Badge variant="subtle" mb={isOpen ? 5 : 0}>
          {title}
        </Badge>
        {isOpen ? (
          <ChevronUpIcon boxSize={6} color="brand.primary" onClick={onToggle} cursor="pointer" />
        ) : (
          <ChevronDownIcon boxSize={6} color="brand.primary" onClick={onToggle} cursor="pointer" />
        )}
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        {children}
      </Collapse>
    </Flex>
  );
};

export default CaseResultSection;
