import { FC } from "react";
import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import { ReactComponent as PrisonologyIcon } from "@assets/files/images/icons/prisonologyIcon.svg";

const MachineAnalysisPreviewFooter: FC = () => {
  return (
    <Flex justify="space-between">
      <Box flex={1}>
        <PrisonologyIcon />
      </Box>
      <Box flex={1}>
        <Stack spacing={2}>
          <Text textStyle="label" color="brand.authBg">
            (617) 858-5008
          </Text>
          <Text textStyle="label" color="brand.primary">
            info@prisonology.com
          </Text>
          <Text textStyle="label" color="brand.authBg">
            11231 US Highway 1 #310, North Palm Beach, FL 33408
          </Text>
        </Stack>
      </Box>
    </Flex>
  );
};

export default MachineAnalysisPreviewFooter;
