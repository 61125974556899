import React, { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authentificated } from "@containers/Auth/store/selectors";
import { RoleName } from "@shared/models";
import { selectors } from "@containers/Auth/store";
import { logout as logoutAction } from "@containers/Auth/store/actions";

import { NamesOfParentRoutes, NameOfChildRoutes } from "../constants";

interface AuthGuardProps {
  children: ReactNode;
  allowedRoleNames?: RoleName[];
}

const AuthGuard: FC<AuthGuardProps> = ({ children, allowedRoleNames }) => {
  const isAuthenticated = useSelector(authentificated());
  const userRoles = useSelector(selectors.getUserRoles());
  const dispatch = useDispatch();

  const isAccessDenied =
    allowedRoleNames &&
    userRoles &&
    !allowedRoleNames.some((roleName) => userRoles.find((role) => role.name === roleName));

  const userShouldBeLoggedOut = !isAuthenticated || isAccessDenied;

  if (userShouldBeLoggedOut) {
    dispatch(logoutAction.request());
    return <Navigate to={`${NamesOfParentRoutes.AUTH}${NameOfChildRoutes.AUTH.LOGIN}`} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
