import React, { FC } from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import { BoldText } from "@shared/components";
import { generateFormInitialValues } from "@containers/Cases/components/CaseViewContainer/MachineAnalysis/MachineAnalysis.utils";

import CaseFormViewHead from "./CaseFormViewHead";
import CaseFormViewCeil from "./CaseFormViewCeil";
import CaseFormViewValue from "./CaseFormViewValue";

interface CaseFormViewProps {
  caseObj?: ReturnType<typeof generateFormInitialValues>;

  columnsRatio?: number;
}

const PADDING_SPACE = 1.5;

export const CaseFormView: FC<CaseFormViewProps> = ({ caseObj, columnsRatio = 1 }) => {
  if (!caseObj) return null;

  const columnWidth = 40 / columnsRatio;

  return (
    <>
      <Grid
        templateColumns="repeat(24, 1fr)"
        gap={0}
        textTransform="uppercase"
        sx={{
          borderColor: "grey.100",
          borderRight: "unset",
          borderBottom: "unset",
          borderLeft: "2px solid",
          borderTop: "2px solid",
        }}
      >
        <CaseFormViewHead label="Inmate Load Data" colSpan={24} />
        <CaseFormViewCeil colSpan={24}>
          <Grid templateColumns={`${columnWidth}px ${200 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1} pl={PADDING_SPACE}>
              1.
            </GridItem>
            <GridItem gridColumn={2}>Register number:</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue value={caseObj.usm_number} />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>

        <CaseFormViewCeil colSpan={6}>
          <Grid templateColumns={`${columnWidth}px ${100 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1} pl={PADDING_SPACE}>
              2.
            </GridItem>
            <GridItem gridColumn={2}>LAST Name</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue value={caseObj.last_name} />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil colSpan={9}>
          <Grid templateColumns={`${columnWidth}px ${100 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1}>3.</GridItem>
            <GridItem gridColumn={2}>First Name</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue value={caseObj.first_name} />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil colSpan={5}>
          <Grid templateColumns={`${columnWidth}px ${80 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1}>4.</GridItem>
            <GridItem gridColumn={2}>Middle</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue value={caseObj.middle_name} />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil colSpan={4}>
          <Grid templateColumns={`${columnWidth}px ${60 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1}>5.</GridItem>
            <GridItem gridColumn={2}>Suffix</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue value={caseObj.suffix} />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>

        <CaseFormViewCeil colSpan={3}>
          <Grid templateColumns={`${columnWidth}px ${44 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1} pl={PADDING_SPACE}>
              6.
            </GridItem>
            <GridItem gridColumn={2}>RACE</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue value={caseObj.race} />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil colSpan={3}>
          <Grid templateColumns={`${columnWidth}px ${columnWidth}px 1fr`} w="100%">
            <GridItem gridColumn={1}>7.</GridItem>
            <GridItem gridColumn={2}>SEX</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue value={caseObj.sex} />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil colSpan={9}>
          <Grid templateColumns={`${columnWidth}px ${70 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1}>8.</GridItem>
            <GridItem gridColumn={2}>ETHNIC</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue value={caseObj.ethnic_origin} />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil colSpan={9}>
          <Grid templateColumns={`${columnWidth}px ${130 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1}>9.</GridItem>
            <GridItem gridColumn={2}>DATE OF BIRTH</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue value={caseObj.date_of_birth} />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>

        <CaseFormViewCeil colSpan={24}>
          <Grid templateColumns={`${columnWidth}px ${160 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1} pl={PADDING_SPACE}>
              10.
            </GridItem>
            <GridItem gridColumn={2}>OFFENSE/SENTENCE</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>

        <CaseFormViewCeil colSpan={24} h={34} />

        <CaseFormViewCeil colSpan={15}>
          <Grid templateColumns={`${columnWidth}px ${120 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1} pl={PADDING_SPACE}>
              11.
            </GridItem>
            <GridItem gridColumn={2}>FBI NUMBER</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue value={caseObj.fbi_number} />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil colSpan={9}>
          <Grid templateColumns={`${columnWidth}px ${120 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1}>12.</GridItem>
            <GridItem gridColumn={2}>SSN Number</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>

        <CaseFormViewCeil colSpan={8}>
          <Grid templateColumns={`${columnWidth}px ${130 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1} pl={PADDING_SPACE}>
              13.
            </GridItem>
            <GridItem gridColumn={2}>STATE OF BIRTH</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue value={caseObj.state_of_birth} />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil colSpan={8}>
          <Grid templateColumns={`${columnWidth}px ${200 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1}>14.</GridItem>
            <GridItem gridColumn={2}>OR COUNTRY OF BIRTH</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue value={caseObj.country_of_birth} />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil colSpan={8}>
          <Grid templateColumns={`${columnWidth}px ${100 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1}>15.</GridItem>
            <GridItem gridColumn={2}>CITIZENSHIP</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue value={caseObj.citizenship} />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>

        <CaseFormViewCeil colSpan={24}>
          <Grid templateColumns={`${columnWidth}px ${140 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1} pl={PADDING_SPACE}>
              16.
            </GridItem>
            <GridItem gridColumn={2}>ADDRESS STREET</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>

        <CaseFormViewCeil colSpan={6}>
          <Grid templateColumns={`${columnWidth}px ${50 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1} pl={PADDING_SPACE}>
              17.
            </GridItem>
            <GridItem gridColumn={2}>City</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue value={caseObj.city} />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil colSpan={4}>
          <Grid templateColumns={`${columnWidth}px ${50 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1}>18.</GridItem>
            <GridItem gridColumn={2}>State</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue value={caseObj.state} />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil colSpan={6}>
          <Grid templateColumns={`${columnWidth}px ${50 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1}>19.</GridItem>
            <GridItem gridColumn={2}>Zip</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue value={caseObj.zip} />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil colSpan={8}>
          <Grid templateColumns={`${columnWidth}px ${180 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1}>20.</GridItem>
            <GridItem gridColumn={2}>OR FOREIGN COUNTRY</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue value={caseObj?.foreign_country} />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>

        <CaseFormViewCeil colSpan={6}>
          <Grid templateColumns={`${columnWidth}px ${70 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1} pl={PADDING_SPACE}>
              21.
            </GridItem>
            <GridItem gridColumn={2}>Height</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue value={caseObj.height} />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil colSpan={4}>
          <Grid templateColumns={`${columnWidth}px ${70 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1}>22.</GridItem>
            <GridItem gridColumn={2}>Weight</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue value={caseObj.weight} />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil colSpan={6}>
          <Grid templateColumns={`${columnWidth}px ${100 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1}>23.</GridItem>
            <GridItem gridColumn={2}>Hair Color</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue value={caseObj.hair_color} />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil colSpan={8}>
          <Grid templateColumns={`${columnWidth}px ${100 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1}>24.</GridItem>
            <GridItem gridColumn={2}>Eye Color</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue value={caseObj.eyes_color} />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>

        <CaseFormViewCeil colSpan={24}>
          <Grid templateColumns={`${columnWidth}px ${150 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1} pl={PADDING_SPACE}>
              25.
            </GridItem>
            <GridItem gridColumn={2}>ARS ASSIGNMENT</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue value={caseObj.ars_assignment} />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>

        <CaseFormViewHead label="Security Designation Data" colSpan={24} />

        <CaseFormViewCeil colSpan={6}>
          <Grid templateColumns={`${columnWidth}px ${70 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1} pl={PADDING_SPACE}>
              1.
            </GridItem>
            <GridItem gridColumn={2}>JUDGE</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue value={caseObj.judge} />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil colSpan={6}>
          <Grid templateColumns={`${columnWidth}px ${100 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1}>2.</GridItem>
            <GridItem gridColumn={2}>Rec facility</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue value={caseObj.rec_facility} />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil colSpan={6}>
          <Grid templateColumns={`${columnWidth}px ${120 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1}>3.</GridItem>
            <GridItem gridColumn={2}>Rec program</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue value={caseObj.rec_program} />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil colSpan={6}>
          <Grid templateColumns={`${columnWidth}px ${100 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1}>4.</GridItem>
            <GridItem gridColumn={2}>USM office</GridItem>
            <GridItem gridColumn={3}>
              <CaseFormViewValue value={caseObj.usm_office} />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>

        <CaseFormViewCeil colSpan={22}>
          <Grid templateColumns={`${columnWidth}px ${370 / columnsRatio}px 1fr`} w="100%">
            <GridItem gridColumn={1} pl={PADDING_SPACE}>
              5.
            </GridItem>
            <GridItem gridColumn={2}>VOLUNTARY SURRENDER STATUS</GridItem>
            <GridItem gridColumn={3} display="flex">
              <BoldText index={0.3}>0</BoldText> = NO
            </GridItem>
            <GridItem gridColumn={4} display="flex">
              <BoldText index={0.3}>(-3)</BoldText> = YES
            </GridItem>
            <GridItem gridColumn="2 / span 3" rowSpan={4}>
              {` IF YES, MUST INDICATE: 5a. V/S DATE: ___${caseObj?.vs_date}__ 5b. V/S LOCATION: ${caseObj?.vs_location}`}
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil w="100%" justifyContent="center" colSpan={2}>
          <CaseFormViewValue value={caseObj.voluntary_surrender_status} textAlign="center" />
        </CaseFormViewCeil>

        <CaseFormViewCeil colSpan={24}>
          <Grid templateColumns={`${columnWidth}px 1fr`} w="100%">
            <GridItem gridColumn={1} pl={PADDING_SPACE}>
              6.
            </GridItem>
            <GridItem gridColumn={2}>{`MONTHS TO RELEASE ___${caseObj.month_to_release}`}</GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil colSpan={22}>
          <Grid
            templateColumns={`${columnWidth}px ${250 / columnsRatio}px 1fr 1fr 1fr`}
            w="100%"
            templateRows="repeat(2, 1fr)"
          >
            <GridItem gridColumn={1} rowSpan={2} pl={PADDING_SPACE}>
              7.
            </GridItem>
            <GridItem gridColumn={2} rowSpan={2} w="60%">
              SEVERITY OF CURRENT OFFENSE
            </GridItem>
            <GridItem gridColumn={3} display="flex">
              <BoldText index={0.3}>0</BoldText> = LOWEST
            </GridItem>
            <GridItem gridRow={2} gridColumn={3} display="flex">
              <BoldText index={0.3}>1</BoldText> = LOW MODERATE
            </GridItem>
            <GridItem gridColumn={4} display="flex">
              <BoldText index={0.3}>3</BoldText> = MODERATE
            </GridItem>
            <GridItem gridRow={2} gridColumn={4} display="flex">
              <BoldText index={0.3}>5</BoldText> = HIGH
            </GridItem>
            <GridItem gridColumn={5} display="flex">
              <BoldText index={0.3}>7</BoldText> = GREATEST
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil w="100%" justifyContent="center" colSpan={2}>
          <CaseFormViewValue textAlign="center" value={caseObj.level_of_severity} />
        </CaseFormViewCeil>
        <CaseFormViewCeil colSpan={22}>
          <Grid
            templateColumns={`${columnWidth}px ${350 / columnsRatio}px ${100 / columnsRatio}px ${
              100 / columnsRatio
            }px 1fr`}
            w="100%"
            templateRows="repeat(3, 1fr)"
          >
            <GridItem gridColumn={1} pl={PADDING_SPACE}>
              8.
            </GridItem>
            <GridItem gridColumn={2} rowSpan={2}>
              {`CRIMINAL HISTORY POINTS:___${caseObj.criminal_history_total_points}__`}
            </GridItem>
            <GridItem gridColumn={3} display="flex">
              <BoldText index={0.3}>0</BoldText> = 0-1
            </GridItem>
            <GridItem gridRow={2} gridColumn={3} display="flex">
              <BoldText index={0.3}>2</BoldText> = 2-3
            </GridItem>
            <GridItem gridColumn={4} display="flex">
              <BoldText index={0.3}>4</BoldText> = 4-6
            </GridItem>
            <GridItem gridRow={2} gridColumn={4} display="flex">
              <BoldText index={0.3}>6</BoldText> = 7-9
            </GridItem>
            <GridItem gridColumn={5} colSpan={9} display="flex">
              <BoldText index={0.3}>8</BoldText> = 10-12
            </GridItem>
            <GridItem gridRow={2} gridColumn={5} colSpan={9} display="flex">
              <BoldText index={0.3}>10</BoldText> = 13+
            </GridItem>
            <GridItem gridRow={3} gridColumn={1} pl={PADDING_SPACE}>
              8a.
            </GridItem>
            <GridItem gridRow={3} gridColumn="2 / span 4">
              {`Source Document Date: __${caseObj.source_doc_date}___8a. SOURCE OF DOCUMENTED __${caseObj.source_of_doc}__ - PSR or ____ - NCIC`}
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil w="100%" justifyContent="center" colSpan={2}>
          <CaseFormViewValue textAlign="center" value={caseObj.criminal_history_points} />
        </CaseFormViewCeil>

        <CaseFormViewCeil colSpan={22}>
          <Grid
            templateColumns={`${columnWidth}px ${110 / columnsRatio}px ${110 / columnsRatio}px ${
              110 / columnsRatio
            }px ${110 / columnsRatio}px ${110 / columnsRatio}px ${110 / columnsRatio}px 1fr`}
            w="100%"
            templateRows="repeat(3, 1fr)"
          >
            <GridItem gridColumn={1} rowSpan={3} pl={PADDING_SPACE}>
              9.
            </GridItem>
            <GridItem gridColumn={2} gridRow="1 / span 2">
              HISTORY OF VIOLENCE
            </GridItem>
            <GridItem mr={4} gridColumn={3} gridRow="2 / span 3" textAlign="right">
              MINOR SERIOUS
            </GridItem>

            <GridItem gridColumn={4}>NONE</GridItem>
            <GridItem gridRow={2} gridColumn={4}>
              <BoldText index={0.3}>0</BoldText>
            </GridItem>
            <GridItem gridRow={3} gridColumn={4}>
              <BoldText index={0.3}>0</BoldText>
            </GridItem>

            <GridItem gridColumn={5}>{`>15 YEARS`}</GridItem>
            <GridItem gridRow={2} gridColumn={5}>
              <BoldText index={0.3}>1</BoldText>
            </GridItem>
            <GridItem gridRow={3} gridColumn={5}>
              <BoldText index={0.3}>2</BoldText>
            </GridItem>

            <GridItem gridColumn={6}>10-15 YEARS</GridItem>
            <GridItem gridRow={2} gridColumn={6}>
              <BoldText index={0.3}>1</BoldText>
            </GridItem>
            <GridItem gridRow={3} gridColumn={6}>
              <BoldText index={0.3}>4</BoldText>
            </GridItem>

            <GridItem gridColumn={7}>5-10 YEARS</GridItem>
            <GridItem gridRow={2} gridColumn={7}>
              <BoldText index={0.3}>3</BoldText>
            </GridItem>
            <GridItem gridRow={3} gridColumn={7}>
              <BoldText index={0.3}>6</BoldText>
            </GridItem>
            <GridItem gridColumn={8}>{`<5 YEARS`}</GridItem>
            <GridItem gridRow={2} gridColumn={8}>
              <BoldText index={0.3}>5</BoldText>
            </GridItem>
            <GridItem gridRow={3} gridColumn={8}>
              <BoldText index={0.3}>7</BoldText>
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil w="100%" justifyContent="center" colSpan={2}>
          <CaseFormViewValue textAlign="center" value={caseObj.history_of_violence} />
        </CaseFormViewCeil>

        <CaseFormViewCeil colSpan={22}>
          <Grid
            templateColumns={`${columnWidth}px ${110 / columnsRatio}px ${110 / columnsRatio}px ${
              110 / columnsRatio
            }px ${110 / columnsRatio}px ${110 / columnsRatio}px ${110 / columnsRatio}px 1fr`}
            w="100%"
            templateRows="repeat(3, 1fr)"
          >
            <GridItem gridColumn={1} rowSpan={3} pl={PADDING_SPACE}>
              10.
            </GridItem>
            <GridItem gridColumn={2} rowSpan={3}>
              HISTORY OF ESCAPE OR ATTEMPTS
            </GridItem>
            <GridItem mr={4} gridColumn={3} gridRow="2 / span 3" textAlign="right">
              MINOR SERIOUS
            </GridItem>
            <GridItem gridColumn={4}>NONE</GridItem>
            <GridItem gridRow={2} gridColumn={4}>
              <BoldText index={0.3}>0</BoldText>
            </GridItem>
            <GridItem gridRow={3} gridColumn={4}>
              <BoldText index={0.3}>0</BoldText>
            </GridItem>

            <GridItem gridColumn={5}>{`>15 YEARS`}</GridItem>
            <GridItem gridRow={2} gridColumn={5}>
              <BoldText index={0.3}>1</BoldText>
            </GridItem>
            <GridItem gridRow={3} gridColumn={5}>
              <BoldText index={0.3}>3 (S)</BoldText>
            </GridItem>

            <GridItem gridColumn={6}>{`>10 YEARS`}</GridItem>
            <GridItem gridRow={2} gridColumn={6}>
              <BoldText index={0.3}>1</BoldText>
            </GridItem>
            <GridItem gridRow={3} gridColumn={6}>
              <BoldText index={0.3}>3 (S)</BoldText>
            </GridItem>

            <GridItem gridColumn={7}>5-10 YEARS</GridItem>
            <GridItem gridRow={2} gridColumn={7}>
              <BoldText index={0.3}>2</BoldText>
            </GridItem>
            <GridItem gridRow={3} gridColumn={7}>
              <BoldText index={0.3}>3 (S)</BoldText>
            </GridItem>
            <GridItem gridColumn={8}>{`<5 YEARS`}</GridItem>
            <GridItem gridRow={2} gridColumn={8}>
              <BoldText index={0.3}>3</BoldText>
            </GridItem>
            <GridItem gridRow={3} gridColumn={8}>
              <BoldText index={0.3}>3 (S)</BoldText>
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil w="100%" justifyContent="center" colSpan={2}>
          <CaseFormViewValue textAlign="center" value={caseObj.history_of_escape} />
        </CaseFormViewCeil>

        <CaseFormViewCeil colSpan={22}>
          <Grid
            templateColumns={`${columnWidth}px ${220 / columnsRatio}px 1fr 1fr 1fr`}
            w="100%"
            templateRows="repeat(2, 1fr)"
          >
            <GridItem gridColumn={1} rowSpan={3} pl={PADDING_SPACE}>
              11.
            </GridItem>
            <GridItem gridColumn={2} rowSpan={2}>
              TYPE OF DETAINER
            </GridItem>
            <GridItem gridColumn={3} display="flex">
              <BoldText index={0.3}>0</BoldText> = NONE
            </GridItem>
            <GridItem gridRow={2} gridColumn={3} display="flex">
              <BoldText index={0.3}>1</BoldText> = LOWEST/LOW MODERATE
            </GridItem>
            <GridItem gridColumn={4} display="flex">
              <BoldText index={0.3}>3</BoldText> = MODERATE
            </GridItem>
            <GridItem gridRow={2} gridColumn={4} display="flex">
              <BoldText index={0.3}>5</BoldText> = HIGH
            </GridItem>
            <GridItem gridColumn={5} display="flex">
              <BoldText index={0.3}>7</BoldText> = GREATEST
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil w="100%" justifyContent="center" colSpan={2}>
          <CaseFormViewValue textAlign="center" value={caseObj.type_of_detainer} />
        </CaseFormViewCeil>

        <CaseFormViewCeil colSpan={22}>
          <Grid
            templateColumns={`${columnWidth}px ${220 / columnsRatio}px ${200 / columnsRatio}px 1fr`}
            w="100%"
            templateRows="repeat(2, 1fr)"
          >
            <GridItem gridColumn={1} rowSpan={3} pl={PADDING_SPACE}>
              12.
            </GridItem>
            <GridItem gridColumn={2} rowSpan={2}>
              AGE
            </GridItem>
            <GridItem gridColumn={3} display="flex">
              <BoldText index={0.3}>0</BoldText> = 55 AND OVER
            </GridItem>
            <GridItem gridRow={2} gridColumn={3} display="flex">
              <BoldText index={0.3}>2</BoldText> = 36 THRU 54.
            </GridItem>
            <GridItem gridColumn={4} display="flex">
              <BoldText index={0.3}>4</BoldText> = 25 THRU 35
            </GridItem>
            <GridItem gridRow={2} gridColumn={4} display="flex">
              <BoldText index={0.3}>8</BoldText> = 24 OR LESS
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil w="100%" justifyContent="center" colSpan={2}>
          <CaseFormViewValue textAlign="center" value={caseObj?.age} />
        </CaseFormViewCeil>

        <CaseFormViewCeil colSpan={22}>
          <Grid templateColumns={`${columnWidth}px ${120 / columnsRatio}px 1fr`} w="100%" templateRows="repeat(4, 1fr)">
            <GridItem gridColumn={1} rowSpan={3} pl={PADDING_SPACE}>
              13.
            </GridItem>
            <GridItem gridColumn={2} rowSpan={3}>
              EDUCATIONAL LEVEL
            </GridItem>

            <GridItem gridColumn={3} display="flex">
              <BoldText index={0.3}>0</BoldText> = Verified High School Degree or GED
            </GridItem>
            <GridItem gridRow={2} gridColumn={3} display="flex">
              <BoldText index={0.3}>1</BoldText> = Enrolled in and making satisfactory progress in GED Program
            </GridItem>
            <GridItem gridRow={3} gridColumn={3} display="flex">
              <BoldText index={0.3}>2</BoldText> = No verified High School Degree/GED and not participating in GED
              Program
            </GridItem>

            <GridItem gridColumn={1} rowSpan={1} pl={PADDING_SPACE}>
              13a.
            </GridItem>

            <GridItem gridRow={4} gridColumn="2 / span 4">
              {`HIGHEST GRADE COMPLETED __${caseObj.highest_grade}__`}
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil w="100%" justifyContent="center" colSpan={2}>
          <CaseFormViewValue textAlign="center" value={caseObj.education_level} />
        </CaseFormViewCeil>

        <CaseFormViewCeil colSpan={22}>
          <Grid templateColumns={`${columnWidth}px 1fr 1fr 1fr 1fr`} w="100%">
            <GridItem gridColumn={1} pl={PADDING_SPACE}>
              14.
            </GridItem>
            <GridItem gridColumn={2}>DRUG/ALCOHOL ABUSE</GridItem>
            <GridItem gridColumn={3} display="flex">
              <BoldText index={0.3}>0</BoldText>
              {` = Never/>5 Years`}
            </GridItem>
            <GridItem gridColumn={4} display="flex">
              <BoldText index={0.3}>1</BoldText>
              {` = <5 Years `}
            </GridItem>
            <GridItem gridColumn={5}>U = Unknown</GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil w="100%" justifyContent="center" colSpan={2}>
          <CaseFormViewValue textAlign="center" value={caseObj.substance_abuse} />
        </CaseFormViewCeil>

        <CaseFormViewCeil colSpan={22}>
          <Grid templateColumns={`${columnWidth}px 1fr`} w="100%">
            <GridItem gridColumn={1} pl={PADDING_SPACE}>
              15.
            </GridItem>
            <GridItem gridColumn={2}>TOTAL</GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil w="100%" justifyContent="center" colSpan={2}>
          <CaseFormViewValue textAlign="center" value={caseObj.total} />
        </CaseFormViewCeil>

        <CaseFormViewCeil colSpan={22}>
          <Grid
            templateColumns={`${columnWidth}px ${100 / columnsRatio}px 1fr 1fr`}
            templateRows="repeat(6, 1fr)"
            w="100%"
          >
            <GridItem gridColumn={1} rowSpan={6} pl={PADDING_SPACE}>
              16.
            </GridItem>
            <GridItem gridColumn={2} rowSpan={6}>
              PUBLIC SAFETY FACTORS
            </GridItem>
            <GridItem gridColumn={3} display="flex">
              <BoldText index={0.3}>A</BoldText>-NONE
            </GridItem>
            <GridItem gridRow={2} gridColumn={3} display="flex">
              <BoldText index={0.3}>B</BoldText>-DISRUPTIVE GROUP (males only)
            </GridItem>
            <GridItem gridRow={3} gridColumn={3} display="flex">
              <BoldText index={0.3}>C</BoldText>-GREATEST SEVERITY OFFENSE (males only)
            </GridItem>
            <GridItem gridRow={4} gridColumn={3} display="flex">
              <BoldText index={0.3}>F</BoldText>-SEX OFFENDER
            </GridItem>
            <GridItem gridRow={5} gridColumn={3} display="flex">
              <BoldText index={0.3}>G</BoldText>-THREAT TO GOVERNMENT OFFICIALS
            </GridItem>
            <GridItem gridRow={6} gridColumn={3} display="flex">
              <BoldText index={0.3}>H</BoldText>-DEPORTABLE ALIEN
            </GridItem>

            <GridItem gridColumn={4} display="flex">
              <BoldText index={0.3}>I</BoldText>-SENTENCE LENGTH (males only)
            </GridItem>
            <GridItem gridRow={2} gridColumn={4} display="flex">
              <BoldText index={0.3}>K</BoldText>-VIOLENT BEHAVIOR (females only)
            </GridItem>
            <GridItem gridRow={3} gridColumn={4} display="flex">
              <BoldText index={0.3}>L</BoldText>-SERIOUS ESCAPE
            </GridItem>
            <GridItem gridRow={4} gridColumn={4} display="flex">
              <BoldText index={0.3}>M</BoldText>-PRISON DISTURBANCE
            </GridItem>
            <GridItem gridRow={5} gridColumn={4} display="flex">
              <BoldText index={0.3}>N</BoldText>-JUVENILE VIOLENCE
            </GridItem>
            <GridItem gridRow={6} gridColumn={4} display="flex">
              <BoldText index={0.3}>O</BoldText>-SERIOUS TELEPHONE ABUSE
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
        <CaseFormViewCeil w="100%" justifyContent="center" colSpan={2}>
          <CaseFormViewValue textAlign="center" value={caseObj.public_safety_factors} />
        </CaseFormViewCeil>

        <CaseFormViewCeil colSpan={24}>
          <Grid templateColumns={`${columnWidth}px 1fr`} w="100%">
            <GridItem gridColumn={1} pl={PADDING_SPACE}>
              17.
            </GridItem>
            <GridItem gridColumn={2}>REMARKS</GridItem>
            <GridItem colSpan={24} rowSpan={9} pl={PADDING_SPACE}>
              <CaseFormViewValue value={caseObj.remarks} />
            </GridItem>
          </Grid>
        </CaseFormViewCeil>

        <CaseFormViewCeil colSpan={24}>
          <Grid templateColumns={`${columnWidth}px 1fr`} w="100%">
            <GridItem gridColumn={1} pl={PADDING_SPACE}>
              18.
            </GridItem>
            <GridItem gridColumn={2}>
              {`OMDT REFERRAL:${caseObj.omdt_referral} (YES/NO) SCRN LEVEL: MD= MH=_____ SENT TO OMDT ON: Consider RDAP? Y or N/`}
            </GridItem>
          </Grid>
        </CaseFormViewCeil>
      </Grid>
    </>
  );
};
