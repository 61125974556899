import React, { FC } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { ReactComponent as SecurityDesignationLoadingIcon } from "@assets/files/images/icons/securityDesignationLoadingIcon.svg";

import "./index.scss";

const SecurityDesignationLoading: FC = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" width="100%" height="80%">
      <SecurityDesignationLoadingIcon />
      <Flex alignItems="flex-end">
        <Box textStyle="noMatchesTitle" mt="17px" fontSize="26px" color="blue.900">
          Data will appear soon
        </Box>
        <Box className="loading" fontSize="26px" color="blue.900">
          ...
        </Box>
      </Flex>
      <Box textStyle="noMatchesSubtitle" mt="17px" w="270px" textAlign="center">
        Please wait while we’re preparing the file. This will take a few minutes
      </Box>
    </Box>
  );
};

export default SecurityDesignationLoading;
