import React, { FC, useEffect, useState } from "react";
import { Flex } from "@chakra-ui/react";
import { Document, Page, pdfjs } from "react-pdf";

interface PdfViewProps {
  file: string;
}

const PdfView: FC<PdfViewProps> = ({ file }) => {
  const [numPages, setNumPages] = useState<number | null>(null);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  });

  return (
    <Flex justifyContent="center">
      <Document file={file} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
        {Array(numPages)
          .fill(0)
          .map((_, i) => (
            <Page key={i} pageNumber={i + 1} scale={1.4} renderTextLayer={false} />
          ))}
      </Document>
    </Flex>
  );
};

export default PdfView;
