import React, { FC, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Flex, CloseButton, Button, Box } from "@chakra-ui/react";
import { NameOfRoutes } from "@shared/constants";
import { Breadcrumb } from "@shared/components";
import { actions as SharedAction } from "@shared/store";
import { CASE_STATUS, CaseModel } from "@shared/models";
import { getFullName } from "@shared/utils";
import { getCaseFileSignedUrl } from "@containers/Cases/store/actions";
import { useNavigate } from "react-router-dom";

import CaseChangeStatus from "../../../../../shared/components/CaseChangeStatus/CaseChangeStatus";
import CaseViewHeaderMenu from "./CaseViewHeaderMenu";

interface CaseViewHeaderProps {
  caseObj?: CaseModel | null;
  handleFetchCaseInfo?: () => void;
  decorateCb: <U, T extends U[]>(cb: (...args: T) => void) => (...args: T) => void;
  decorateEventCb: <U, T extends U[]>(
    cb: (event: React.SyntheticEvent<Element>, ...args: T) => void,
  ) => (event: React.SyntheticEvent<Element>, ...args: T) => void;
}

const CaseViewHeader: FC<CaseViewHeaderProps> = ({ caseObj, handleFetchCaseInfo, decorateCb, decorateEventCb }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const breadcrunbItems = useMemo(
    () => [
      {
        name: "Cases",
        to: NameOfRoutes.CASES_LIST,
        onClick: decorateEventCb(() => navigate(NameOfRoutes.CASES_LIST)),
      },
      { name: getFullName<CaseModel>(caseObj, "New Case"), isCurrentPage: true },
    ],
    [caseObj, decorateEventCb, navigate],
  );

  const handleClose = decorateCb(() => {
    dispatch(SharedAction.navigate(NameOfRoutes.CASES_LIST));
  });

  const handleWatchDocument = useCallback(() => {
    caseObj?.id && dispatch(getCaseFileSignedUrl.request(caseObj.id));
  }, [caseObj?.id, dispatch]);

  return (
    <Flex w="100%" alignItems="center" justifyContent="space-between">
      <Flex alignItems="center" gap={4}>
        <Breadcrumb items={breadcrunbItems} />
        {caseObj && <CaseChangeStatus item={caseObj} onChangedStatus={handleFetchCaseInfo} />}
      </Flex>
      <Flex alignItems="center">
        <Box id="migration_analysis_controls" />
        {caseObj && (
          <>
            {caseObj.status === CASE_STATUS.SUCCESS ? (
              <CaseViewHeaderMenu caseObj={caseObj} />
            ) : (
              <Button variant="ghost" onClick={handleWatchDocument}>
                View PSR
              </Button>
            )}
          </>
        )}
        <CloseButton onClick={handleClose} />
      </Flex>
    </Flex>
  );
};

export default CaseViewHeader;
