import { FC } from "react";
import { Box, Grid, GridItem, Text } from "@chakra-ui/react";
import { FONT_WEIGHT } from "@assets/theme/foundations/textStyle";

import MachineAnalysisPreviewBlock from "./MachineAnalysisPreviewBlock";
import MachineAnalysisPreviewContent from "./MachineAnalysisPreviewContent";

type MachineAnalysisPreviewBlockSentencingInfoData = Partial<
  Record<
    | "publicSafetyFactors"
    | "typeOfPrison"
    | "generalRecidivism"
    | "firstStepActEligible"
    | "secondStepActEligible"
    | "riskOfViolenceRecidivism"
    | "initialPatternScore"
    | "recidivism",
    string | number | undefined
  >
>;

type MachineAnalysisPreviewBlockSentencingInfoProps = MachineAnalysisPreviewBlockSentencingInfoData & {
  title?: string;
};

const MachineAnalysisPreviewBlockSentencingInfo: FC<MachineAnalysisPreviewBlockSentencingInfoProps> = ({
  title,
  ...props
}) => {
  return (
    <MachineAnalysisPreviewBlock title={title}>
      <Grid templateColumns="repeat(4, 1fr)" gap={3}>
        {props.publicSafetyFactors && (
          <GridItem colSpan={4}>
            <MachineAnalysisPreviewContent title="1. Public Safety Factors" description={props.publicSafetyFactors} />
          </GridItem>
        )}
        {props.typeOfPrison && (
          <GridItem colSpan={4}>
            <MachineAnalysisPreviewContent
              title="2. Possible Designations Recommendations"
              description={props.typeOfPrison}
            />
            <Text textStyle="label" mt={2}>
              *If this individual has{" "}
              <Box as="span" fontWeight={FONT_WEIGHT.BOLD}>
                more than 10 years
              </Box>{" "}
              remaining, they are automatically a{" "}
              <Box as="span" fontWeight={FONT_WEIGHT.BOLD}>
                low
              </Box>
              .
            </Text>
            <Text textStyle="label">
              *If this individual has{" "}
              <Box as="span" fontWeight={FONT_WEIGHT.BOLD}>
                more than 20 years
              </Box>{" "}
              remaining, they are automatically a{" "}
              <Box as="span" fontWeight={FONT_WEIGHT.BOLD}>
                medium.
              </Box>
            </Text>
            <Text textStyle="label">
              *If this individual has{" "}
              <Box as="span" fontWeight={FONT_WEIGHT.BOLD}>
                more than 30 years
              </Box>{" "}
              remaining, they are automatically a{" "}
              <Box as="span" fontWeight={FONT_WEIGHT.BOLD}>
                high.
              </Box>
            </Text>
          </GridItem>
        )}
        {props.generalRecidivism && (
          <GridItem colSpan={2}>
            <MachineAnalysisPreviewContent title="3. General Recidivism:" description={props.generalRecidivism} />
          </GridItem>
        )}
        {props.firstStepActEligible && (
          <GridItem colSpan={2}>
            <MachineAnalysisPreviewContent
              title="4. First Step Act Eligible"
              description={props.firstStepActEligible}
            />
          </GridItem>
        )}
        {props.secondStepActEligible && (
          <GridItem colSpan={2}>
            <MachineAnalysisPreviewContent
              title="5. Second Step Act Eligible"
              description={props.secondStepActEligible}
            />
          </GridItem>
        )}
        {props.riskOfViolenceRecidivism && (
          <GridItem colSpan={2}>
            <MachineAnalysisPreviewContent
              title="6. Risk of Violence Recidivism:"
              description={props.riskOfViolenceRecidivism}
            />
          </GridItem>
        )}
        {props.initialPatternScore && (
          <GridItem colSpan={2}>
            <MachineAnalysisPreviewContent title="7. Initial PATTERN Score" description={props.initialPatternScore} />
          </GridItem>
        )}
        {props.recidivism && (
          <GridItem colSpan={2}>
            <MachineAnalysisPreviewContent title="8. Recidivism" description={props.recidivism} />
          </GridItem>
        )}
      </Grid>
    </MachineAnalysisPreviewBlock>
  );
};

export default MachineAnalysisPreviewBlockSentencingInfo;
