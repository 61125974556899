import { FC } from "react";
import { Grid, GridItem } from "@chakra-ui/react";

import MachineAnalysisPreviewBlock from "./MachineAnalysisPreviewBlock";
import MachineAnalysisPreviewContent from "./MachineAnalysisPreviewContent";

type MachineAnalysisPreviewBlockInmateDataData = Partial<
  Record<
    | "firstName"
    | "usmNumber"
    | "lastName"
    | "middleName"
    | "suffix"
    | "race"
    | "sex"
    | "ethnicOrigin"
    | "dateOfBirth"
    | "total_offense_level"
    | "fbiNumber"
    | "ssn"
    | "stateOfBirth"
    | "countryOfBirth"
    | "citizenship"
    | "street"
    | "city"
    | "state"
    | "zip"
    | "foreignCountry"
    | "height"
    | "weight"
    | "hairColor"
    | "eyesColor"
    | "arsAssignment"
    | "offenseSentence",
    string | number | undefined
  >
>;

type MachineAnalysisPreviewBlockInmateDataProps = MachineAnalysisPreviewBlockInmateDataData & {
  title?: string;
};

const MachineAnalysisPreviewBlockInmateData: FC<MachineAnalysisPreviewBlockInmateDataProps> = ({ title, ...props }) => {
  return (
    <MachineAnalysisPreviewBlock title={title}>
      <Grid templateColumns="repeat(4, 1fr)" gap={3}>
        {props.usmNumber && (
          <GridItem colSpan={1}>
            <MachineAnalysisPreviewContent title="1. Register No." description={props.usmNumber} />
          </GridItem>
        )}
        {props.lastName && (
          <GridItem colSpan={1}>
            <MachineAnalysisPreviewContent title="2. Last Name" description={props.lastName} />
          </GridItem>
        )}
        {props.firstName && (
          <GridItem colSpan={1}>
            <MachineAnalysisPreviewContent title="3. First Name" description={props.firstName} />
          </GridItem>
        )}
        {props.middleName && (
          <GridItem colSpan={1}>
            <MachineAnalysisPreviewContent title="4. Middle Name" description={props.middleName} />
          </GridItem>
        )}
        {props.suffix && (
          <GridItem colSpan={1}>
            <MachineAnalysisPreviewContent title="5. Suffix" description={props.suffix} />
          </GridItem>
        )}

        {props.race && (
          <GridItem colSpan={1}>
            <MachineAnalysisPreviewContent title="6. Race" description={props.race} />
          </GridItem>
        )}

        {props.sex && (
          <GridItem colSpan={1}>
            <MachineAnalysisPreviewContent title="7. Sex" description={props.sex} />
          </GridItem>
        )}

        {props.ethnicOrigin && (
          <GridItem colSpan={1}>
            <MachineAnalysisPreviewContent title="8. Ethnic Origin" description={props.ethnicOrigin} />
          </GridItem>
        )}

        {props.dateOfBirth && (
          <GridItem colSpan={1}>
            <MachineAnalysisPreviewContent title="9. Date of Birth" description={props.dateOfBirth} />
          </GridItem>
        )}

        {props.offenseSentence && (
          <GridItem colSpan={1}>
            <MachineAnalysisPreviewContent title="10. Offence/Sentence" description={props.offenseSentence} />
          </GridItem>
        )}

        {props.fbiNumber && (
          <GridItem colSpan={1}>
            <MachineAnalysisPreviewContent title="11. FBI Number" description={props.fbiNumber} />
          </GridItem>
        )}

        {props.ssn && (
          <GridItem colSpan={1}>
            <MachineAnalysisPreviewContent title="12. SSN Number" description={props.ssn} />
          </GridItem>
        )}

        {props.stateOfBirth && (
          <GridItem colSpan={1}>
            <MachineAnalysisPreviewContent title="13. State of Birth" description={props.stateOfBirth} />
          </GridItem>
        )}

        {props.countryOfBirth && (
          <GridItem colSpan={2}>
            <MachineAnalysisPreviewContent title="14. Country of Birth" description={props.countryOfBirth} />
          </GridItem>
        )}

        {props.citizenship && (
          <GridItem colSpan={1}>
            <MachineAnalysisPreviewContent title="15. Citizenship" description={props.citizenship} />
          </GridItem>
        )}

        {props.street && (
          <GridItem colSpan={4}>
            <MachineAnalysisPreviewContent title="16. Address Street" description={props.street} />
          </GridItem>
        )}

        {props.city && (
          <GridItem colSpan={2}>
            <MachineAnalysisPreviewContent title="17. City" description={props.city} />
          </GridItem>
        )}

        {props.state && (
          <GridItem colSpan={1}>
            <MachineAnalysisPreviewContent title="18. State" description={props.state} />
          </GridItem>
        )}

        {props.zip && (
          <GridItem colSpan={1}>
            <MachineAnalysisPreviewContent title="19. Zip" description={props.zip} />
          </GridItem>
        )}

        {props.foreignCountry && (
          <GridItem colSpan={2}>
            <MachineAnalysisPreviewContent title="20. Foreign Country" description={props.foreignCountry} />
          </GridItem>
        )}

        {props.height && (
          <GridItem colSpan={1}>
            <MachineAnalysisPreviewContent title="21. Height" description={props.height} />
          </GridItem>
        )}

        {props.weight && (
          <GridItem colSpan={1}>
            <MachineAnalysisPreviewContent title="22. Weight" description={props.weight} />
          </GridItem>
        )}

        {props.hairColor && (
          <GridItem colSpan={1}>
            <MachineAnalysisPreviewContent title="23. Hair Color" description={props.hairColor} />
          </GridItem>
        )}

        {props.eyesColor && (
          <GridItem colSpan={1}>
            <MachineAnalysisPreviewContent title="24. Eyes Color" description={props.eyesColor} />
          </GridItem>
        )}

        {props.arsAssignment && (
          <GridItem colSpan={1}>
            <MachineAnalysisPreviewContent title="25. ARS Assignment" description={props.arsAssignment} />
          </GridItem>
        )}
      </Grid>
    </MachineAnalysisPreviewBlock>
  );
};

export default MachineAnalysisPreviewBlockInmateData;
