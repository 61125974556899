import { FC, PropsWithChildren } from "react";
import { RoleName } from "@shared/models";
import { selectors } from "@containers/Auth/store";
import { useSelector } from "react-redux";

interface RolePermissionProps {
  allowedRoleNames: RoleName[];
}

const RolePermission: FC<PropsWithChildren<RolePermissionProps>> = ({ allowedRoleNames, children }) => {
  const userRoles = useSelector(selectors.getUserRoles());

  const hasAnyRole = allowedRoleNames.some((roleName) => userRoles?.some((userRole) => userRole.name === roleName));

  if (!userRoles || !hasAnyRole) {
    return null;
  }

  return <>{children}</>;
};

export default RolePermission;
