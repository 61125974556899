import React, { FC } from "react";
import { useSelector } from "react-redux";
import { selectors as sharedSelectors } from "@shared/store";
import { createPortal } from "react-dom";
import { Loader } from "@shared/components";
import { Box } from "@chakra-ui/react";

const element = document.getElementById("loader");

const LoaderWrapper: FC = () => {
  const isLoading = useSelector(sharedSelectors.getLoading());
  return (
    <>
      {element &&
        isLoading &&
        createPortal(
          <Box
            zIndex="999999"
            pos="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box position="absolute" top={0} bottom={0} left={0} right={0} bg="rgba(0, 0, 0, 0.5)" />
            <Loader />
          </Box>,
          element,
        )}
    </>
  );
};

export default LoaderWrapper;
