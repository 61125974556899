import { Button } from "./button";
import { Table } from "./table";
import { Badge } from "./badge";
import { Divider } from "./divider";
import { Checkbox } from "./checkbox";
import { Breadcrumb } from "./breadcrumb";
import { IconButton } from "./iconbutton";
import { Modal } from "./modal";

export default {
  Button,
  Table,
  Badge,
  Divider,
  Checkbox,
  Breadcrumb,
  IconButton,
  Modal,
};
