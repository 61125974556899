import React, { FC, useCallback, useState } from "react";
import { getFullName } from "@shared/utils";
import { Box, Stack } from "@chakra-ui/react";
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { useDebounce } from "@shared/hooks";
import { FONT_WEIGHT } from "@assets/theme/foundations/textStyle";

import { generateFormInitialValues } from "../MachineAnalysis.utils";
import MachineAnalysisPreviewModalHeader from "./MachineAnalysisPreviewModalHeader";
import MachineAnalysisPreviewModalFooter from "./MachineAnalysisPreviewModalFooter";
import MachineAnalysisPreviewModalPages from "./MachineAnalysisPreviewModalPages";

interface MachineAnalysisPreviewModalProps {
  onClose: () => void;
  caseObjUpdatedValues: ReturnType<typeof generateFormInitialValues>;
}

const MAX_PAGE = 5;
const MIN_PAGE = 1;

const zoomStyles = {
  ".zoom_wrapper": {
    width: "100%",
  },
  ".zoom_content": {
    width: "100%",
  },
  ".input_ma_preview": {
    p: 0,
    bg: "brand.bg",
    color: "brand.emptyStateText",
    border: "1px solid",
    borderColor: "grey.5",
    textAlign: "center",
    maxW: "38px",
    fontSize: 12,
    //disabled styles
    "&:disabled": {
      color: "black",
      fontWeight: FONT_WEIGHT.HEAVY,
      opacity: 1,
    },
  },
};

const MachineAnalysisPreviewModal: FC<MachineAnalysisPreviewModalProps> = ({ onClose, caseObjUpdatedValues }) => {
  const [page, setPage] = useState(MIN_PAGE);
  const [scale, setScale] = useState(100);
  const debouncedValue = useDebounce(scale, 150);

  const handleAddPage = useCallback(() => {
    setPage((prevPage) => (prevPage < MAX_PAGE ? prevPage + 1 : prevPage));
  }, []);

  const handleSubtractPage = useCallback(() => {
    setPage((prevPage) => (prevPage > MIN_PAGE ? prevPage - 1 : prevPage));
  }, []);

  const handleTransformed = useCallback(
    (scale: ReactZoomPanPinchRef) => setScale(Math.floor(scale.state.scale * 100)),
    [],
  );

  if (!caseObjUpdatedValues) return null;
  return (
    <Box sx={zoomStyles}>
      <TransformWrapper onTransformed={handleTransformed} disabled={scale === 100}>
        <MachineAnalysisPreviewModalHeader onClose={onClose} name={getFullName(caseObjUpdatedValues, "")} />
        <TransformComponent wrapperClass="zoom_wrapper" contentClass="zoom_content">
          <Stack bg="brand.bg" px={8} py={6} spacing={1} w="100%" h={796}>
            <MachineAnalysisPreviewModalPages caseObjUpdatedValues={caseObjUpdatedValues} page={page} />
          </Stack>
        </TransformComponent>
        <MachineAnalysisPreviewModalFooter
          onAddPage={handleAddPage}
          onSubtractPage={handleSubtractPage}
          maxPage={MAX_PAGE}
          minPage={MIN_PAGE}
          currentPage={page}
          scale={debouncedValue}
          onClose={onClose}
          caseObjUpdatedValues={caseObjUpdatedValues}
        />
      </TransformWrapper>
    </Box>
  );
};

export default MachineAnalysisPreviewModal;
