import React, { FC } from "react";
import { Flex, ModalBody, ModalCloseButton, ModalHeader, Text } from "@chakra-ui/react";
import { ReactComponent as SuccessPayedIcon } from "@assets/files/images/icons/successPayedIcon.svg";

const CasePaymentSuccessModal: FC = () => {
  return (
    <>
      <ModalHeader pt={5}>
        <Flex justify="center">
          <SuccessPayedIcon />
        </Flex>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody p={5}>
        <Text textStyle="subtitle" textAlign="center">
          Payment completed
        </Text>
        <Text color="brand.grey" mt={3} textAlign="center">
          <strong>Please be informed:</strong> your case is in progress. You'll be emailed the full report with answers
          reviewed by an expert in 72h.
        </Text>
      </ModalBody>
    </>
  );
};

export default CasePaymentSuccessModal;
