export const Badge = {
  variants: {
    readOnlyProp: {
      bg: "gray.60",
      padding: "4px 18px",
      borderRadius: "6px",
      fontSize: "16px",
      fontWeight: 400,
      color: "brand.black",
    },
    subtle: {
      display: "flex",
      flexDir: "column",
      variant: "subtle",
      bg: "grey.5",
      color: "blue.700",
      letterSpacing: "2px",
      fontSize: "12px",
      h: "24px",
      px: "8px",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "4px",
    },
    success: {
      bg: "green.100",
      color: "brand.activeColor",
      letterSpacing: "2px",
      fontSize: "12px",
      display: "flex",
      h: "24px",
      px: "8px",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "4px",
      w: "max-content",
    },
    danger: {
      bg: "red.100",
      color: "brand.primary",
      letterSpacing: "2px",
      fontSize: "12px",
      display: "flex",
      h: "24px",
      px: "8px",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "4px",
      w: "max-content",
    },
  },
};
