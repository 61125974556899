import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { BaseFilter } from "@shared/interfaces";

import * as actions from "./actions";
import { CasesStateType } from "../interface";

type Action = ActionType<typeof actions>;

export const defaultFilter: BaseFilter = {
  page: 0,
  limit: 50,
  search: "",
};

export const initialState: CasesStateType = {
  cases: [],
  case: null,
  casesTotal: null,
  filter: { ...defaultFilter },
};

const reducer = createReducer<CasesStateType, Action>(initialState)
  .handleAction(actions.setProcessingCaseId, (state, action) =>
    produce(state, (nextState) => {
      nextState.processingCaseId = action.payload;
    }),
  )
  .handleAction(actions.getCasesList.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, clear } = action.payload;
      nextState.cases = !clear ? [...nextState.cases, ...rows] : [...rows];
      nextState.casesTotal = count;
    }),
  )
  .handleAction(actions.getCase.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.case = action.payload;
    }),
  )
  .handleAction(actions.restartProcessingCase.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.case = action.payload.element;
    }),
  )
  .handleAction(actions.setFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter = action.payload || { ...defaultFilter };
    }),
  )
  .handleAction(actions.clearCasesList, (state) =>
    produce(state, (nextState) => {
      nextState.cases = [];
      nextState.casesTotal = 0;
    }),
  );

export { reducer as CasesReducer };
