import React, { FC } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { ReactComponent as ChatUserIcon } from "@assets/files/images/icons/chatUserIcon.svg";
import { ReactComponent as ChatAIIcon } from "@assets/files/images/icons/chatAIIcon.svg";
import { FONT_WEIGHT } from "@assets/theme/foundations/textStyle";
import { CaseMessageModel } from "@shared/models";

interface ChatProps {
  message: CaseMessageModel;
}

const ChatMessage: FC<ChatProps> = ({ message }) => {
  return (
    <>
      {message.system_instructions && (
        <Flex p="10px" borderRadius="8px" mb="15px">
          <Box w="45px">
            <ChatUserIcon />
          </Box>
          <Box
            w="100%"
            fontSize="14px"
            fontWeight={FONT_WEIGHT.HEAVY}
            color="brand.chatMessageText"
            whiteSpace="pre-wrap"
          >
            {message.system_instructions}
          </Box>
        </Flex>
      )}
      {message.user_question && (
        <Flex p="10px" borderRadius="8px" mb="15px">
          <Box w="45px">
            <ChatUserIcon />
          </Box>
          <Box
            w="100%"
            fontSize="14px"
            fontWeight={FONT_WEIGHT.HEAVY}
            color="brand.chatMessageText"
            whiteSpace="pre-wrap"
          >
            {message.user_question}
          </Box>
        </Flex>
      )}
      <Flex p="10px" borderRadius="8px" background="brand.chatMessageBg" mb="15px">
        <Box w="45px">
          <ChatAIIcon />
        </Box>
        <Box
          w="100%"
          fontSize="14px"
          fontWeight={FONT_WEIGHT.NORMAL}
          color="brand.chatMessageText"
          whiteSpace="pre-wrap"
        >
          {message.machine_answer}
        </Box>
      </Flex>
    </>
  );
};

export default ChatMessage;
