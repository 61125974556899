import { useCallback } from "react";
import { actions as CasesAction } from "@containers/Cases/store";
import { actions, actions as SharedAction } from "@shared/store";
import { useDispatch, useSelector } from "react-redux";
import { CASE_STATUS, CaseModel } from "@shared/models";
import { MODAL_TYPES } from "@shared/constants";
import { showModal } from "@shared/store/actions";
import { initialState } from "@containers/Cases/store/reducer";
import { selectors } from "@containers/Auth/store";

const useCreateCase = () => {
  const dispatch = useDispatch();
  const isUserAdmin = useSelector(selectors.isAdmin());

  const handleCreateCase = useCallback(
    (base64: string) => {
      dispatch(
        CasesAction.createCase.request({
          base_64: base64,
          cb: (model?: CaseModel) => {
            if (model) {
              dispatch(SharedAction.hideModal());
              dispatch(CasesAction.getCasesList.request({ ...initialState.filter }));
              if (model.status === CASE_STATUS.INVALID) {
                dispatch(
                  showModal({
                    type: MODAL_TYPES.INVALID_DOCUMENT,
                    props: {
                      caseId: model.id,
                    },
                  }),
                );
                return;
              }
              if (isUserAdmin) {
                return;
              }
              dispatch(
                actions.showModal({
                  type: MODAL_TYPES.UPLOAD_CASE_SUCCESS,
                  size: "popup",
                  props: {
                    onSubmit: () => {
                      dispatch(actions.hideModal());
                    },
                  },
                }),
              );
            }
          },
        }),
      );
    },
    [dispatch, isUserAdmin],
  );

  const handleOpenModalCreateCase = useCallback(() => {
    dispatch(
      showModal({
        type: MODAL_TYPES.CREATE_CASE,
        size: "uploadPopup",
        closeOnOverlayClick: true,
        props: {
          onSubmit: handleCreateCase,
        },
      }),
    );
  }, [dispatch, handleCreateCase]);

  return {
    handleOpenModalCreateCase,
  };
};

export default useCreateCase;
