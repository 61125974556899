import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { onCopyToClipboard } from "@shared/utils";
import { showNotification } from "@shared/store/actions";

const useCopyToClipboard = () => {
  const dispatch = useDispatch();

  return useCallback(
    (text: string, successMessage: string) => {
      onCopyToClipboard(text);
      dispatch(
        showNotification({
          message: successMessage,
          appearance: "success",
        }),
      );
    },
    [dispatch],
  );
};

export default useCopyToClipboard;
