import { Margin, usePDF } from "react-to-pdf";
import React, { useCallback, useMemo } from "react";
import { flushSync } from "react-dom";

const pageSettings = {
  margin: Margin.SMALL,
  format: "letter",
};

const downloadFormStyles = {
  "& .caseFormHeadContainer, & .caseFormCeilContainer": {
    p: 0,
    pb: 2,
    px: 1,
  },
  fontSize: "10px",
};

const useDownloadCaseForm = (filename = "new_document") => {
  const [isDownloading, setIsDownloading] = React.useState(false);
  const pdfConfig = useMemo(
    () => ({
      filename: `${filename}.pdf`,
      page: pageSettings,
    }),
    [filename],
  );

  const { toPDF, targetRef } = usePDF(pdfConfig);

  const handleDownloadPaf = useCallback(() => {
    flushSync(() => {
      setIsDownloading(true);
    });
    toPDF();
    setIsDownloading(false);
  }, [toPDF]);

  return { isDownloading, handleDownloadPaf, targetRef, formStyles: isDownloading ? downloadFormStyles : undefined };
};

export default useDownloadCaseForm;
