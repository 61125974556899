const colors = {
  brand: {
    bg: "#F8F9FA",
    authBg: "#2D3656",
    primary: "#E06E41",
    secondary: "#A71C27",
    disabledBtnBg: "#81879A",
    error: "#E53E3E",
    red: "#EB4335",
    grey: "#9b9591",

    emptyStateText: "#81879A",

    tableBodyText: "#404040",
    tableHeaderText: "#B6BAC9",

    modalHeaderText: "#2D3656",
    breadcrumbText: "#2D3656",

    chatMessageBg: "#F2F4F8",
    chatMessageText: "#404040",

    activeColor: "#61BB79",

    white: "#FEFEFF",
    cancel: "#F1F1F1",
    checkMark: "#46BA90",
    secondarySubtitle: "#7E7E7E",
    label: "#7E7E7E",
    sidebarBackground: "#3f3754",
    sidebarItemSelected: "#ACACAC",
    inputFocusBorder: "#7E7E7E",
    black: "#171717",
  },
  grey: {
    5: "#EDF1FF",
    10: "#B6BAC9",
    50: "#7f7f7f",
    100: "#464646",
  },
  blue: {
    300: "#64B5F6",
    700: "#81879A",
    900: "#2D3656",
  },
  gray: {
    40: "#F1F1F1",
    50: "#E7E7E7",
    60: "#EDEDED",
    100: "#F1F1F1",
  },
} as const;

export type Colors = typeof colors;
export default colors;
