import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { actions } from "@shared/store";
import { MODAL_TYPES } from "@shared/constants";
import {
  CaseModelFormValues,
  parseValuesToCaseModel,
} from "@containers/Cases/components/CaseViewContainer/MachineAnalysis/MachineAnalysis.utils";
import { getCase, updateCase } from "@containers/Cases/store/actions";

const useCheckIfFormUpdated = (id: number) => {
  const dispatch = useDispatch();
  const isFormWasChanged = useRef(false);
  const formValues = useRef<null | CaseModelFormValues>(null);
  const isFormErrors = useRef(false);

  const handleShowPopUp = useCallback(
    (cb: () => void) => {
      dispatch(
        actions.showModal({
          type: MODAL_TYPES.CONFIRM,
          props: {
            heading: "Save Changes Before Leaving",
            content: "Your changes will be lost if you leave now. Are you sure?",
            cancelBtnText: "Don't Save",
            successBtnText: "Save & Leave",
            onSuccess: () => {
              if (isFormErrors.current) return;
              cb();
              formValues.current &&
                dispatch(
                  updateCase.request({
                    ...parseValuesToCaseModel(formValues.current),
                    id,
                    cb: () => {
                      dispatch(getCase.request({ caseId: id }));
                    },
                  }),
                );
            },
            onClose: cb,
          },
        }),
      );
    },
    [dispatch, id],
  );

  const decorateCb = useCallback(
    <U, T extends U[]>(cb: (...args: T) => void) => {
      return (...args: T) => {
        if (isFormWasChanged.current) {
          handleShowPopUp(() => {
            isFormWasChanged.current = false;
            cb(...args);
          });
          return;
        }
        cb(...args);
      };
    },
    [handleShowPopUp],
  );

  const decorateEventCb = useCallback(
    <U, T extends U[]>(cb: (event: React.SyntheticEvent<Element>, ...args: T) => void) => {
      return (event: React.SyntheticEvent<Element>, ...args: T) => {
        if (isFormWasChanged.current) {
          event.preventDefault();
          event.stopPropagation();
          handleShowPopUp(() => {
            isFormWasChanged.current = false;
            cb(event, ...args);
          });
          return;
        }
        cb(event, ...args);
      };
    },
    [handleShowPopUp],
  );

  const setFormDirtyStatus = useCallback((value: boolean, values: CaseModelFormValues, isErrors: boolean) => {
    isFormWasChanged.current = value;
    formValues.current = values;
    isFormErrors.current = isErrors;
  }, []);

  useEffect(() => {
    const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
      if (isFormWasChanged.current) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", beforeUnloadHandler);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, []);

  return {
    setFormDirtyStatus,
    decorateCb,
    decorateEventCb,
  };
};

export default useCheckIfFormUpdated;
