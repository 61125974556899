import { useCallback, useEffect } from "react";
import { actions, selectors } from "@containers/Cases/store";
import { useDispatch, useSelector } from "react-redux";
import { CASE_STATUS } from "@shared/models";

const TIMEOUT = 5000; //5 sec

const useLoadCaseInfo = (caseId: number) => {
  const dispatch = useDispatch();
  const caseObj = useSelector(selectors.getCase());

  const handleFetchCaseInfo = useCallback(() => {
    dispatch(actions.getCase.request({ caseId: Number(caseId) }));
  }, [caseId, dispatch]);

  useEffect(() => {
    if (caseObj?.status !== CASE_STATUS.RUNNING) return;

    const intervalId = setInterval(() => {
      dispatch(actions.getCase.request({ caseId: Number(caseId), isLoadingBlocked: true }));
    }, TIMEOUT);

    return () => clearInterval(intervalId);
  }, [caseId, caseObj?.status, dispatch]);

  useEffect(() => {
    handleFetchCaseInfo();
  }, [handleFetchCaseInfo]);

  useEffect(() => {
    return () => {
      dispatch(actions.getCase.success(null));
    };
  }, [dispatch]);

  return {
    caseObj,
    handleFetchCaseInfo,
  };
};

export default useLoadCaseInfo;
