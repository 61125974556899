import React, { FC } from "react";
import { Button, Flex, ModalBody, ModalHeader, Text } from "@chakra-ui/react";

interface InvalidCaseModalProps {
  onClose: () => void;
}

const InvalidCaseModal: FC<InvalidCaseModalProps> = ({ onClose }) => {
  return (
    <>
      <ModalHeader>
        <Text textStyle="subtitle">Wrong File</Text>
      </ModalHeader>
      <ModalBody p={5} pt={4}>
        <Text fontSize="14px" color="#9b9591" textAlign="left">
          The system was not able to detect the necessary sections to score this PDF. Please double-check the file to be
          sure it's a PSR. Please be informed that the system can't process scanned PSR-s and District of Columbia
          Format files.
        </Text>
        <Flex mt={6} justify="flex-end" gap={3}>
          <Button variant="primary" onClick={onClose}>
            Got it
          </Button>
        </Flex>
      </ModalBody>
    </>
  );
};

export default InvalidCaseModal;
