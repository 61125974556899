import { createAction, createAsyncAction } from "typesafe-actions";
import { UserShortModel, UserSummaryModel } from "@shared/models";
import { UsersListFilter, PaginatedResponse, UserSummaryListFilter, WithLoaderFlag } from "@shared/interfaces";

import { UsersActionTypes } from "./constants";

export const getShortUsers = createAsyncAction(
  UsersActionTypes.GET_SHORT_USERS_LIST,
  UsersActionTypes.GET_SHORT_USERS_LIST_SUCCESS,
  UsersActionTypes.GET_SHORT_USERS_LIST_FAILURE,
)<WithLoaderFlag<UsersListFilter>, PaginatedResponse<UserShortModel>, Error>();

export const getSummaryUsers = createAsyncAction(
  UsersActionTypes.GET_SUMMARY_USERS_LIST,
  UsersActionTypes.GET_SUMMARY_USERS_LIST_SUCCESS,
  UsersActionTypes.GET_SUMMARY_USERS_LIST_FAILURE,
)<UserSummaryListFilter, PaginatedResponse<UserSummaryModel>, Error>();

export const setShortUsersListFilter = createAction(
  UsersActionTypes.SET_SHORT_USERS_LIST_FILTER,
)<UsersListFilter | null>();

export const setSummaryUsersListFilter = createAction(
  UsersActionTypes.SET_SUMMARY_USERS_LIST_FILTER,
)<UserSummaryListFilter | null>();
