import { FC } from "react";
import DatePickerLib, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useField } from "formik";
import { FormControl, FormLabel } from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import { getUSFormattedDate } from "@shared/utils";

interface DatePickerProps extends Omit<ReactDatePickerProps, "onChange" | "selected"> {
  name: string;
  label?: string;
}

const baseStyles = {
  ".react-datepicker-wrapper": {
    width: "100%",
    position: "relative",
  },
  ".date_picker_popper": {
    zIndex: 2,
    position: "fixed !important",
  },
  ".calendar_icon": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "brand.gray",
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
    bottom: 0,
    padding: "0 10px",
    zIndex: 1,
  },
  ".date_picker": {
    width: "100%",
    borderRadius: 8,
  },
};

const DatePicker: FC<DatePickerProps> = ({ name, label, className, ...restProps }) => {
  const [{ value, onChange }] = useField(name);

  return (
    <FormControl mt={4} className="input" w="100%" sx={baseStyles}>
      {label && <FormLabel>{label}</FormLabel>}
      <DatePickerLib
        name={name}
        selected={(value && new Date(value)) || null}
        onChange={(val) => {
          onChange({ target: { name, value: val ? getUSFormattedDate(val) : "" } });
        }}
        dateFormat="MM/dd/yyyy"
        className={`date_picker ${className}`}
        popperClassName="date_picker_popper"
        calendarIconClassname="calendar_icon"
        showIcon
        toggleCalendarOnIconClick
        icon={<CalendarIcon color="blue.700" />}
        {...restProps}
      />
    </FormControl>
  );
};

export default DatePicker;
