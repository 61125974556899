export enum AuthActionTypes {
  LOGIN = "@@AUTH/LOGIN",
  LOGIN_SUCCESS = "@@AUTH/LOGIN_SUCCESS",
  LOGIN_FAILURE = "@@AUTH/LOGIN_FAILURE",

  REGISTRATION = "@@AUTH/REGISTRATION",
  REGISTRATION_SUCCESS = "@@AUTH/REGISTRATION_SUCCESS",
  REGISTRATION_FAILURE = "@@AUTH/REGISTRATION_FAILURE",

  LOGOUT = "@@AUTH/LOGOUT",
  LOGOUT_SUCCESS = "@@AUTH/LOGOUT_SUCCESS",
  LOGOUT_FAILURE = "@@AUTH/LOGOUT_FAILURE",

  FORGOT_PASSWORD = "@@AUTH/FORGOT_PASSWORD",
  FORGOT_PASSWORD_SUCCESS = "@@AUTH/FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE = "@@AUTH/FORGOT_PASSWORD_FAILURE",

  RESET_PASSWORD = "@@AUTH/RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS = "@@AUTH/RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE = "@@AUTH/RESET_PASSWORD_FAILURE",

  GET_USER = "@@AUTH/GET_USER",
  GET_USER_SUCCESS = "@@AUTH/GET_USER_SUCCESS",
  GET_USER_FAILURE = "@@AUTH/GET_USER_FAILURE",

  CHECK_USER_PAYMENT_METHOD = "@@AUTH/CHECK_USER_PAYMENT_METHOD",
  CHECK_USER_PAYMENT_METHOD_SUCCESS = "@@AUTH/CHECK_USER_PAYMENT_METHOD_SUCCESS",
  CHECK_USER_PAYMENT_METHOD_FAILURE = "@@AUTH/CHECK_USER_PAYMENT_METHOD_FAILURE",

  GET_CHECKOUT_URL = "@@AUTH/GET_CHECKOUT_URL",
  GET_CHECKOUT_URL_SUCCESS = "@@AUTH/GET_CHECKOUT_URL_SUCCESS",
  GET_CHECKOUT_URL_FAILURE = "@@AUTH/GET_CHECKOUT_URL_FAILURE",

  GO_TO_BILLING_PORTAL = "@@ACCOUNT/GO_TO_BILLING_PORTAL",
}
