import { FC } from "react";
import { CopyIcon } from "@chakra-ui/icons";
import { Box, BoxProps } from "@chakra-ui/react";

import useCopyToClipboard from "../../hooks/CopyToClipboardHook";

interface CopyTextButtonProps extends BoxProps {
  text: string;
  successText: string;
}

const CopyTextButton: FC<CopyTextButtonProps> = ({ text, successText, onClick, ...restProps }) => {
  const onCopyToClipboard = useCopyToClipboard();

  const handleIconClick = (e: React.MouseEvent<HTMLDivElement>) => {
    onClick && onClick(e);
    onCopyToClipboard(text, successText);
  };

  return (
    <Box onClick={handleIconClick} {...restProps}>
      <CopyIcon color="blue.700" boxSize={4} />
    </Box>
  );
};

export default CopyTextButton;
