export const NamesOfParentRoutes = {
  AUTH: "/auth",
  CASES: "/cases",
  USERS: "/users",
};

export const NameOfRoutes = {
  SIGN_IN: "/",
  AUTH: "/auth/",
  AUTH_LOGIN: "/auth/login",
  AUTH_FORGOT_PASSWORD: "/auth/restore",
  AUTH_RESET_PASSWORD: "/auth/recovery/:hash",
  AUTH_SIGN_UP: "/auth/sign-up",
  USERS: "/users",
  CASES_LIST: "/cases",
  CASES_ITEM_VIEW: "/cases/:id",
};

export const NameOfChildRoutes = {
  AUTH: {
    LOGIN: "/login",
    SIGN_UP: "/sign-up",
    FORGOT_PASSWORD: "/restore",
    RESET_PASSWORD: "/recovery/:hash",
  },
  CASES: {
    LIST: "/",
    VIEW: "/:id",
  },
  USERS: {
    LIST: "/",
    VIEW: "/:id",
  },
  DEFAULT_REDIRECT: "/*",
};
