import React, { FC, useCallback, useRef } from "react";
import { CASE_STATUS, CaseModel, REPORT_STATUS } from "@shared/models";
import { Box, BoxProps } from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import { generateFormInitialValues } from "@containers/Cases/components/CaseViewContainer/MachineAnalysis/MachineAnalysis.utils";
import { useDownloadCaseForm } from "@shared/hooks";
import { getFullName } from "@shared/utils";
import { MachineAnalysisDownload } from "@containers/Cases/components/CaseViewContainer/MachineAnalysis/MachineAnalysisDownload";
import { actions } from "@containers/Cases/store";
import { useDispatch } from "react-redux";

interface DownloadCaseProps extends BoxProps {
  item: CaseModel;
}

const DownloadCase: FC<DownloadCaseProps> = ({ item, onClick, ...restProps }) => {
  const { handleDownloadPaf, isDownloading, targetRef } = useDownloadCaseForm(getFullName(item, ""));
  const scoreData = useRef<null | ReturnType<typeof generateFormInitialValues>>(null);
  const dispatch = useDispatch();

  const handleDownloadCase = useCallback(
    async (e: React.MouseEvent<HTMLDivElement>) => {
      onClick?.(e);

      dispatch(
        actions.getCaseData.request({
          caseId: Number(item.id),
          cb: (score) => {
            scoreData.current = generateFormInitialValues(score);
            handleDownloadPaf();
          },
        }),
      );
    },
    [dispatch, handleDownloadPaf, item.id, onClick],
  );

  if (item.status !== CASE_STATUS.SUCCESS || item.report_status !== REPORT_STATUS.COMPLETED) return null;

  return (
    <Box {...restProps} onClick={handleDownloadCase}>
      <DownloadIcon />
      {isDownloading && scoreData.current && (
        <Box px={4} py={2.5}>
          <MachineAnalysisDownload targetRef={targetRef} caseObjUpdatedValues={scoreData.current} />
        </Box>
      )}
    </Box>
  );
};

export default DownloadCase;
