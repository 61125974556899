import { call, put, takeLatest } from "redux-saga/effects";
import { showHttpErrorNotification, tokenHandler } from "@shared/utils";
import { navigate, showNotification, startLoading, stopLoading } from "@shared/store/actions";
import { ActionWithPayload, ResponseError, WithCallback } from "@shared/interfaces";
import { NameOfRoutes } from "@shared/constants";
import { UserModel } from "@shared/models";

import {
  logout,
  login,
  registration,
  forgotPassword,
  resetPassword,
  getUser,
  checkUserPaymentMethod,
  getCheckoutUrl,
  goToBillingPortal,
} from "./actions";
import api from "../api";
import { LoginShape, RegistrationShape, RestoreShape, ResetPasswordPayload } from "../interface";

function* loginUser(token: string) {
  yield tokenHandler.set(token);
  yield put(navigate("/"));
  yield put(login.success());
}

function* clearLocalStorage() {
  yield localStorage.clear();
}

function* logoutSaga() {
  yield clearLocalStorage();
  yield put(logout.success());
  yield put(navigate(NameOfRoutes.AUTH_LOGIN));
}

function* loginSaga({ payload }: ActionWithPayload<LoginShape>) {
  try {
    yield put(startLoading());
    yield clearLocalStorage();
    const { token }: { token: string } = yield call(api.login, payload);
    yield put(stopLoading());
    yield loginUser(token);
  } catch (error) {
    yield put(login.failure(error as Error));
    yield put(stopLoading());
  }
}

function* registrationSaga({ payload }: ActionWithPayload<RegistrationShape>) {
  try {
    yield put(startLoading());
    const { token }: { token: string } = yield call(api.registration, payload);
    yield loginUser(token);
  } catch (error) {
    yield put(registration.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* forgotPasswordSaga({ payload }: ActionWithPayload<RestoreShape>) {
  try {
    yield put(startLoading());
    const response: { email: string } = yield call(api.forgotPassword, payload);
    yield put(forgotPassword.success(response));
    yield put(
      showNotification({
        message: "Email sent successfully",
        appearance: "success",
      }),
    );
  } catch (error) {
    const err = error as ResponseError;
    yield put(forgotPassword.failure(error as Error));
    if (err.code === 404) {
      yield put(
        showNotification({
          message: "Email sent successfully",
          appearance: "success",
        }),
      );
      return;
    }
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* resetPasswordSaga({ payload }: ActionWithPayload<ResetPasswordPayload>) {
  try {
    yield put(startLoading());
    const { token }: { token: string } = yield call(api.resetPassword, payload);
    yield loginUser(token);
  } catch (error) {
    yield put(resetPassword.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* getUserSaga() {
  try {
    yield put(startLoading());
    const { user }: { user: UserModel } = yield call(api.getUser);
    yield put(getUser.success({ user }));
  } catch (error) {
    yield put(getUser.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* checkUserPaymentMethodSaga({ payload }: ActionWithPayload<WithCallback<{ id: number }, boolean>>) {
  try {
    yield put(startLoading());
    const response: { exists: boolean } = yield call(api.checkUserPaymentMethod, payload.id);
    yield put(checkUserPaymentMethod.success(response));
    payload.cb?.(response.exists);
  } catch (error) {
    yield put(checkUserPaymentMethod.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* getCheckoutUrlSaga({
  payload,
}: ActionWithPayload<{ id: number; successUrl: string; cancelUrl: string; caseId: number }>) {
  try {
    yield put(startLoading());
    const { url }: { url: string } = yield call(api.getCheckoutUrl, {
      id: payload.id,
      successUrl: payload.successUrl,
      cancelUrl: payload.cancelUrl,
      caseId: payload.caseId,
    });
    window.open(url, "_self")?.focus();
  } catch (error) {
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* goToBillingPortalSaga({ payload }: ReturnType<typeof goToBillingPortal>) {
  try {
    yield put(startLoading());
    const { url }: { url: string } = yield call(api.getBillingPortalUrl, payload);
    location.replace(url);
  } catch (error) {
    yield put(showHttpErrorNotification(error as ResponseError, "Can't open billing portal. Please try again later."));
  } finally {
    yield put(stopLoading());
  }
}

function* authSagas() {
  yield takeLatest(logout.request, logoutSaga);
  yield takeLatest(login.request, loginSaga);
  yield takeLatest(registration.request, registrationSaga);
  yield takeLatest(forgotPassword.request, forgotPasswordSaga);
  yield takeLatest(resetPassword.request, resetPasswordSaga);
  yield takeLatest(getUser.request, getUserSaga);
  yield takeLatest(goToBillingPortal, goToBillingPortalSaga);
  yield takeLatest(checkUserPaymentMethod.request, checkUserPaymentMethodSaga);
  yield takeLatest(getCheckoutUrl.request, getCheckoutUrlSaga);
}

export default authSagas;
