import React, { FC, ReactNode } from "react";
import { Box, Button } from "@chakra-ui/react";
import { ReactComponent as NoContentIcon } from "@assets/files/images/icons/noContentIcon.svg";

interface EmptyStatePropsInterface {
  title: string;
  subtitle: string | ReactNode;
  addNewItemText?: string;
  onAddNewItem?: () => void;
}

const EmptyState: FC<EmptyStatePropsInterface> = (props) => {
  const { title, subtitle, addNewItemText, onAddNewItem } = props;

  return (
    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" width="100%" height="80%">
      <NoContentIcon />
      <Box textStyle="noMatchesTitle" mt="17px" fontWeight={600} fontSize={26}>
        {title}
      </Box>
      <Box textStyle="noMatchesSubtitle" mt="17px" w="343px" textAlign="center">
        {subtitle}
      </Box>
      {!!onAddNewItem && (
        <Button variant="primary" w="176px" h="48px" mt="44px" onClick={onAddNewItem}>
          {addNewItemText}
        </Button>
      )}
    </Box>
  );
};

export default EmptyState;
