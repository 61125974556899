import React, { useCallback, useMemo } from "react";
import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "@shared/store";
import { MODAL_TYPES } from "@shared/constants";
import { CreateCaseModal } from "@containers/Cases/components/CasesListContainer/CreateCaseModal";
import {
  CasePaymentErrorModal,
  UploadCaseModalSuccessModal,
  CasePaymentSuccessModal,
  DeleteCaseModal,
} from "@containers/Cases/components";
import MachineAnalysisPreviewModal from "@containers/Cases/components/CaseViewContainer/MachineAnalysis/MachineAnalysisPreviewModal/MachineAnalysisPreviewModal";

import { ConfirmModal } from "./Modals";
import InvalidCaseModal from "../../../containers/Cases/components/CasesListContainer/InvalidCaseModal/InvalidCaseModal";

const ModalWrapper = () => {
  const dispatch = useDispatch();
  const modal = useSelector(selectors.getModal());

  const initialRef = React.useRef(null);

  const hideModalAction = useCallback(() => {
    dispatch(actions.hideModal());
    if (modal?.props.onClose) {
      modal.props.onClose();
    }
  }, [dispatch, modal]);

  const onClose = useCallback(() => {
    if (modal?.leaveConfirm) {
      dispatch(
        actions.showModal({
          type: MODAL_TYPES.CONFIRM,
          props: {
            heading: "You have unsaved changes",
            content: "Are you sure you want to leave? If you leave this page now your progress will be lost.",
            cancelBtnText: "Cancel",
            successBtnText: "Leave",
            onSuccess: hideModalAction,
          },
        }),
      );
      return;
    }

    hideModalAction();
  }, [modal, dispatch, hideModalAction]);

  const onSuccess = useCallback(
    (comment?: string) => {
      if (modal?.props.onSuccess) {
        modal.props.onSuccess(comment);
      }

      dispatch(actions.hideModal());
    },
    [modal, dispatch],
  );

  const Component = useMemo(() => {
    if (!modal) return;
    return modalStrategy(modal.type);
  }, [modal]);

  return modal ? (
    <Modal
      isOpen={Boolean(modal)}
      onClose={onClose}
      size={modal.size}
      isCentered
      closeOnOverlayClick={modal.closeOnOverlayClick}
      initialFocusRef={initialRef}
    >
      <ModalOverlay />
      <ModalContent ref={initialRef} {...modal.modalContentProps}>
        {Component ? <Component {...modal.props} onClose={onClose} onSuccess={onSuccess} /> : null}
      </ModalContent>
    </Modal>
  ) : null;
};

export default ModalWrapper;

const modalStrategy = (type: string) => {
  switch (type) {
    case MODAL_TYPES.CONFIRM: {
      return ConfirmModal;
    }
    case MODAL_TYPES.CREATE_CASE: {
      return CreateCaseModal;
    }
    case MODAL_TYPES.UPLOAD_CASE_SUCCESS: {
      return UploadCaseModalSuccessModal;
    }
    case MODAL_TYPES.PAYMENT_SUCCESS: {
      return CasePaymentSuccessModal;
    }
    case MODAL_TYPES.PAYMENT_ERROR: {
      return CasePaymentErrorModal;
    }
    case MODAL_TYPES.DELETE_CASE: {
      return DeleteCaseModal;
    }
    case MODAL_TYPES.INVALID_DOCUMENT: {
      return InvalidCaseModal;
    }
    case MODAL_TYPES.PREVIEW_CASE: {
      return MachineAnalysisPreviewModal;
    }
  }
};
