import { useMemo } from "react";

export enum CLASSIFICATION_LEVEL {
  minimum,
  low,
  medium,
  high,
}

export type SecurityClassification = Record<
  "m" | "f",
  Map<[number, number], { other: CLASSIFICATION_LEVEL; [key: string]: CLASSIFICATION_LEVEL }>
>;

export const securityClassification: SecurityClassification = {
  m: new Map<[number, number], { other: CLASSIFICATION_LEVEL; [key: string]: CLASSIFICATION_LEVEL }>([
    [
      [0, 11],
      {
        a: CLASSIFICATION_LEVEL.minimum,
        h: CLASSIFICATION_LEVEL.low,
        n: CLASSIFICATION_LEVEL.low,
        c: CLASSIFICATION_LEVEL.low,
        f: CLASSIFICATION_LEVEL.low,
        o: CLASSIFICATION_LEVEL.low,
        g: CLASSIFICATION_LEVEL.low,
        l: CLASSIFICATION_LEVEL.medium,
        b: CLASSIFICATION_LEVEL.high,
        m: CLASSIFICATION_LEVEL.high,
        other: CLASSIFICATION_LEVEL.minimum,
      },
    ],
    [
      [12, 15],
      {
        a: CLASSIFICATION_LEVEL.low,
        l: CLASSIFICATION_LEVEL.medium,
        b: CLASSIFICATION_LEVEL.high,
        m: CLASSIFICATION_LEVEL.high,
        other: CLASSIFICATION_LEVEL.low,
      },
    ],
    [
      [16, 23],
      {
        a: CLASSIFICATION_LEVEL.medium,
        b: CLASSIFICATION_LEVEL.high,
        m: CLASSIFICATION_LEVEL.high,
        other: CLASSIFICATION_LEVEL.medium,
      },
    ],
    [
      [24, Infinity],
      {
        other: CLASSIFICATION_LEVEL.high,
      },
    ],
  ]),
  f: new Map<[number, number], { other: CLASSIFICATION_LEVEL; [key: string]: CLASSIFICATION_LEVEL }>([
    [
      [0, 15],
      {
        a: CLASSIFICATION_LEVEL.minimum,
        h: CLASSIFICATION_LEVEL.low,
        n: CLASSIFICATION_LEVEL.low,
        o: CLASSIFICATION_LEVEL.low,
        f: CLASSIFICATION_LEVEL.low,
        g: CLASSIFICATION_LEVEL.low,
        k: CLASSIFICATION_LEVEL.low,
        m: CLASSIFICATION_LEVEL.high,
        l: CLASSIFICATION_LEVEL.high,
        other: CLASSIFICATION_LEVEL.minimum,
      },
    ],
    [
      [16, 30],
      {
        a: CLASSIFICATION_LEVEL.low,
        m: CLASSIFICATION_LEVEL.high,
        l: CLASSIFICATION_LEVEL.high,
        other: CLASSIFICATION_LEVEL.low,
      },
    ],
    [
      [31, Infinity],
      {
        other: CLASSIFICATION_LEVEL.high,
      },
    ],
  ]),
};

const defineSecurityClassification = (sex: "m" | "f", publicSafetyFactors: string, totalScore: number) => {
  const classificationDataForSex = securityClassification[sex];
  const publicSafetyFactorsList = publicSafetyFactors.split(",");

  const totalScoreRanges = classificationDataForSex.keys();

  //range based on total
  let matchingRange = totalScoreRanges.next().value;
  while (matchingRange) {
    if (totalScore >= matchingRange[0] && totalScore <= matchingRange[1]) {
      break;
    }
    matchingRange = totalScoreRanges.next().value;
  }

  const classificationsForRange = classificationDataForSex.get(matchingRange);

  const publicSafetyFactorsListValues: number[] = [];

  publicSafetyFactorsList.forEach((factor) => {
    if (classificationsForRange) {
      publicSafetyFactorsListValues.push(
        classificationsForRange[factor.toLowerCase()] || classificationsForRange.other,
      );
    }
  });

  const highestPublicSafetyFactorValue = Math.max(...publicSafetyFactorsListValues);

  return CLASSIFICATION_LEVEL[highestPublicSafetyFactorValue];
};

const useDefineMachineAnalysisSecurityClassification = (sex: string, public_safety_factors: string, total: number) => {
  return useMemo(() => {
    const sexLower = sex.toLowerCase();
    if (sexLower === "m" || sexLower === "f") {
      return defineSecurityClassification(sexLower, public_safety_factors, total);
    }
    return CLASSIFICATION_LEVEL.minimum;
  }, [public_safety_factors, sex, total]);
};

export default useDefineMachineAnalysisSecurityClassification;
