import { FC } from "react";
import { Badge, BoxProps } from "@chakra-ui/react";

export enum PaymentStatusTypes {
  PAID = "PAID",
  UNPAID = "UNPAID",
}

interface PaymentStatusPropsInterface extends BoxProps {
  status: PaymentStatusTypes;
}

const paymentStatusConfig = {
  [PaymentStatusTypes.PAID]: {
    title: "Paid",
    variant: "success",
  },
  [PaymentStatusTypes.UNPAID]: {
    title: "Not Paid",
    variant: "danger",
  },
};

const PaymentStatus: FC<PaymentStatusPropsInterface> = ({ status, ...restProps }) => {
  const { title, variant } = paymentStatusConfig[status];
  return (
    <Badge
      width="max-content"
      px={1.5}
      textAlign="center"
      lineHeight="24px"
      borderRadius="2px"
      textTransform="uppercase"
      {...restProps}
      variant={variant}
    >
      {title}
    </Badge>
  );
};

export default PaymentStatus;
