import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";

import {
  GoToBillingPortalShape,
  LoginShape,
  RegistrationShape,
  ResetPasswordPayload,
  RestoreShape,
} from "../interface";

export default {
  login: (payload: LoginShape) => request(METHODS.POST, API.AUTH.LOGIN)(payload),
  registration: (payload: RegistrationShape) => request(METHODS.POST, API.AUTH.REGISTRATION)(payload),
  forgotPassword: (payload: RestoreShape) => request(METHODS.POST, API.AUTH.FORGOT_PASSWORD)(payload),
  resetPassword: (payload: ResetPasswordPayload) => request(METHODS.PUT, API.AUTH.RESET_PASSWORD)(payload),
  getUser: () => request(METHODS.GET, API.USER.GET_USER_INFO)(),
  checkUserPaymentMethod: (id: number) => request(METHODS.GET, API.USER.CHECK_USER_PAYMENT_METHOD(id))(),
  getCheckoutUrl: ({ id, ...restParams }: { id: number; successUrl: string; cancelUrl: string; caseId: number }) =>
    request(METHODS.GET, API.USER.GET_CHECKOUT_URL(id))({ params: { ...restParams } }),
  getBillingPortalUrl: ({ id, ...params }: GoToBillingPortalShape) =>
    request(METHODS.GET, API.USER.GET_BILLING_PORTAL_URL(id))({ params }),
};
