import { CaseModel } from "@shared/models";
import { CaseModelFormValues } from "@containers/Cases/components/CaseViewContainer/MachineAnalysis/MachineAnalysis.utils";

export const getCaseTotalScore = (caseObj?: CaseModel) =>
  caseObj
    ? (caseObj.voluntary_surrender_status || 0) +
      (caseObj.level_of_severity || 0) +
      (caseObj.criminal_history_points || 0) +
      (caseObj.history_of_violence || 0) +
      (caseObj.history_of_escape || 0) +
      (caseObj.type_of_detainer || 0) +
      (caseObj.age || 0) +
      (caseObj?.case_person_details?.education_level || 0) +
      (caseObj.substance_abuse || 0)
    : 0;

export const getCaseFormTotalScore = (values: CaseModelFormValues) => {
  return (
    Number(values.voluntary_surrender_status) +
    Number(values.level_of_severity) +
    Number(values.criminal_history_points) +
    Number(values.history_of_violence) +
    Number(values.history_of_escape?.split("-")[0]) +
    Number(values.type_of_detainer) +
    Number(values.age) +
    Number(values.education_level) +
    Number(values.substance_abuse)
  );
};
