import React, { lazy, useEffect } from "react";
import { useRoutes, RouteObject, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@chakra-ui/react";
import { actions as sharedActions, selectors as sharedSelectors } from "@shared/store";
import { NamesOfParentRoutes } from "@shared/constants";
import useNotification from "@shared/hooks/NotificationHook";
import { Loadable, LoaderWrapper, ModalWrapper, OverlayWrapper } from "@shared/components";
import { getUser } from "@containers/Auth/store/actions";
import { authentificated } from "@containers/Auth/store/selectors";
import { useLocation } from "react-router";
import { setFilter } from "@containers/Cases/store/actions";
import { setShortUsersListFilter, setSummaryUsersListFilter } from "@containers/Users/store/actions";

import { NotFound } from "../../shared/components";
import { AuthGuard, GuestGuard } from "../../shared/guards";

const AuthContainer = Loadable(lazy(() => import("@containers/Auth/containers/AuthContainer/AuthContainer")));
const ApplicationContainer = Loadable(lazy(() => import("@containers/App/ApplicationContainer")));

const routes: RouteObject[] = [
  {
    path: `${NamesOfParentRoutes.AUTH}/*`,
    element: (
      <GuestGuard>
        <AuthContainer />
      </GuestGuard>
    ),
  },
  {
    path: `/*`,
    element: (
      <AuthGuard>
        <ApplicationContainer />
      </AuthGuard>
    ),
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

const App = () => {
  const isAuthenticated = useSelector(authentificated());
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const notification = useSelector(sharedSelectors.getNotification());
  const navigateURL = useSelector(sharedSelectors.getNavigateURL());

  useEffect(() => {
    if (navigateURL) {
      navigate(navigateURL);
      dispatch(sharedActions.navigate(""));
    }
  }, [navigateURL, dispatch, navigate]);

  useEffect(() => {
    isAuthenticated && dispatch(getUser.request());
  }, [dispatch, isAuthenticated]);

  const content = useRoutes(routes);
  useNotification(notification);

  const location = useLocation();

  //@TODO - temporary solution, need to refactor and remove filters from REDUX and ideally move to URL
  useEffect(() => {
    dispatch(setFilter(null));
    dispatch(setShortUsersListFilter(null));
    dispatch(setSummaryUsersListFilter(null));
  }, [dispatch, location]);

  return (
    <Box className="App">
      <Box h="100%">
        <LoaderWrapper />
        <ModalWrapper />
        <OverlayWrapper />
        {content}
      </Box>
    </Box>
  );
};

export default App;
