import React from "react";
import { DeleteIcon } from "@chakra-ui/icons";
import { Box, Flex } from "@chakra-ui/react";

interface FileUploadFileInfoProps {
  file: File;
  onDeleteFile: () => void;
}

const FileUploadFileInfo = ({ file, onDeleteFile }: FileUploadFileInfoProps) => {
  return (
    <Flex
      alignItems="center"
      w="360px"
      h="40px"
      border="1px solid #e7ebee"
      borderRadius="10px"
      justifyContent="space-between"
      background="white"
      p="0 10px"
    >
      <Box w="340px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" textAlign="left">
        {file.name}
      </Box>
      <DeleteIcon onClick={onDeleteFile} color="brand.error" />
    </Flex>
  );
};

export default FileUploadFileInfo;
