import { FC, useCallback } from "react";
import { CaseModel, PAID_STATUS, REPORT_STATUS } from "@shared/models";
import { Badge, BoxProps } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { selectors } from "@containers/Auth/store";
import { actions as CasesAction, selectors as CasesSelectors } from "@containers/Cases/store";
import { defaultFilter } from "@containers/Cases/store/reducer";

import CaseChangeStatus from "../../../../../shared/components/CaseChangeStatus/CaseChangeStatus";

interface CaseListSelectReportStatusProps extends BoxProps {
  item: CaseModel;
}

const CaseListSelectReportStatus: FC<CaseListSelectReportStatusProps> = ({ item, ...restProps }) => {
  const isUserAdmin = useSelector(selectors.isAdmin());
  const dispatch = useDispatch();
  const filter = useSelector(CasesSelectors.getFilter());

  const handleRefetchCases = useCallback(() => {
    dispatch(CasesAction.getCasesList.request({ ...filter, page: defaultFilter.page, limit: defaultFilter.limit }));
  }, [dispatch, filter]);

  if (isUserAdmin) {
    return <CaseChangeStatus item={item} onChangedStatus={handleRefetchCases} {...restProps} />;
  }

  const title = item.report_status === REPORT_STATUS.COMPLETED ? "Completed" : "In progress";
  const variant = item.report_status === REPORT_STATUS.COMPLETED ? "success" : "danger";

  if (item.paid_status !== PAID_STATUS.PAID) {
    return <>-</>;
  }

  return <Badge variant={variant}>{title}</Badge>;
};

export default CaseListSelectReportStatus;
