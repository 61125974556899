import { BaseFilter } from "@shared/interfaces";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import { useCallback } from "react";

interface UseGetPaginateHandlers<T extends BaseFilter> {
  changeFilterAction: (updatedFilter: T) => AnyAction;
  filter: T;
  total: number | null;
}
const useGetPaginateHandlers = <T extends BaseFilter>({
  filter,
  changeFilterAction,
  total,
}: UseGetPaginateHandlers<T>) => {
  const dispatch = useDispatch();

  const isFetched = total !== null;

  const changeFilter = useCallback(
    (updatedFilter: Partial<T>) =>
      dispatch(
        changeFilterAction({
          ...filter,
          ...updatedFilter,
        }),
      ),
    [changeFilterAction, dispatch, filter],
  );

  const handleNextPage = useCallback(() => {
    if (!isFetched) return;
    if (filter.limit * (filter.page + 1) < total) {
      changeFilter({ ...filter, page: filter.page + 1 });
    }
  }, [isFetched, filter, total, changeFilter]);

  const handleChangeSearch = useCallback(
    (search: string) => changeFilter({ ...filter, search, page: 0 }),
    [changeFilter, filter],
  );

  return {
    onChangeFilter: changeFilter,
    onNextPage: handleNextPage,
    onChangeSearch: handleChangeSearch,
    isFetched,
  };
};

export default useGetPaginateHandlers;
