import { FC, useCallback, useMemo } from "react";
import { Select } from "@shared/components";
import { CaseModel, REPORT_STATUS } from "@shared/models";
import { Box, BoxProps } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { actions } from "@containers/Cases/store";
import { actions as sharedActions } from "@shared/store";
import { Option } from "@shared/interfaces";
import { OPTIONS, COLOR_PALETTE, OPTIONS_DICTIONARY } from "@containers/Cases/constants";
import "./index.scss";
import { CasesReportStatusDtoInterface } from "@containers/Cases/interface/CasesReportStatusDto.interface";
import { MODAL_TYPES } from "@shared/constants";

interface CaseListSelectReportStatusProps extends BoxProps {
  item: CaseModel;
  onChangedStatus?: (item?: CasesReportStatusDtoInterface) => void;
}

const CaseChangeStatus: FC<CaseListSelectReportStatusProps> = ({ item, onChangedStatus, ...restProps }) => {
  const dispatch = useDispatch();

  const handleUpdateStatus = useCallback(
    (option: Option<string> | null) => {
      const handleUpdateStatus = () => {
        dispatch(
          actions.updateCaseReportStatus.request({
            id: item.id,
            report_status: (option?.value as REPORT_STATUS) || REPORT_STATUS.NEW,
            cb: onChangedStatus,
          }),
        );
      };

      if (option?.value === REPORT_STATUS.COMPLETED) {
        dispatch(
          sharedActions.showModal({
            type: MODAL_TYPES.CONFIRM,
            props: {
              heading: 'Are you ready to update the status to "Completed"?',
              content: "We'll inform the user about this change.",
              cancelBtnText: "Cancel",
              successBtnText: "Update Status",
              onSuccess: handleUpdateStatus,
            },
          }),
        );
        return;
      }
      handleUpdateStatus();
    },
    [dispatch, item.id, onChangedStatus],
  );

  const value = useMemo(() => {
    return {
      label: OPTIONS_DICTIONARY[item.report_status] || "New",
      value: item.report_status || REPORT_STATUS.NEW,
    };
  }, [item.report_status]);

  return (
    <Box {...restProps}>
      <Select
        className="cases-list-select"
        classNamePrefix="report-status"
        color={COLOR_PALETTE[item.report_status]}
        value={value}
        name="report_status"
        onChange={handleUpdateStatus}
        options={OPTIONS}
      />
    </Box>
  );
};

export default CaseChangeStatus;
