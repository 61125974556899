import React, { FC } from "react";
import { Box, Button, Flex } from "@chakra-ui/react";
import { ReactComponent as ErrorOccurredIcon } from "@assets/files/images/icons/errorOccurredIcon.svg";

interface ErrorOccurredViewProps {
  onCreateCase: () => void;
  onRestartProcessing: () => void;
}

const ErrorOccurredView: FC<ErrorOccurredViewProps> = ({ onCreateCase, onRestartProcessing }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" width="100%" height="80%">
      <ErrorOccurredIcon />
      <Box textStyle="noMatchesTitle" mt="17px" fontSize="26px" color="blue.900">
        Error occurred
      </Box>
      <Box textStyle="noMatchesSubtitle" mt="17px" w="350px" textAlign="center">
        The process was interrupted, we can’t proceed preparing the file, please start over
      </Box>
      <Flex textAlign="center" gap="15px" mt="35px">
        <Button variant="outline" w="200px" h="48px" onClick={onCreateCase}>
          Upload another file
        </Button>
        <Button variant="primary" w="200px" h="48px" onClick={onRestartProcessing}>
          Start Over
        </Button>
      </Flex>
    </Box>
  );
};

export default ErrorOccurredView;
