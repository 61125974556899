import React from "react";
import classnames from "classnames";
import { FieldItemType } from "@shared/interfaces";
import { ErrorMessage, Input } from "@shared/components";
import { InputProps as ChakraInputProps } from "@chakra-ui/input/dist/input";

import { RenderField, GenerateRenderField } from "../../interfaces";

export interface PasswordFieldItem extends RenderField {
  type: FieldItemType.PASSWORD;
  label: string;
  isShowPasswordIcon: boolean;
  inputLeftElement?: ChakraInputProps["children"];
  disabled?: boolean;
  placeholder?: string;
  isRequiredField?: boolean;
}

export const GeneratePasswordField: GenerateRenderField<PasswordFieldItem> = (props) => {
  const {
    formikProps: { errors, touched, handleBlur, handleChange, values },
    wrapperClass,
    name,
    placeholder,
    disabled,
    type,
    label,
    isShowPasswordIcon,
    inputLeftElement,
    isRequiredField,
  } = props;

  return (
    <div className={classnames(wrapperClass, { error: errors[name] && touched[name] })} id={name}>
      <Input
        isShowPasswordIcon={isShowPasswordIcon}
        inputLeftElement={inputLeftElement}
        type={type}
        label={label}
        name={name}
        placeholder={placeholder}
        isDisabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[name]}
        isRequiredField={isRequiredField}
      />
      <ErrorMessage isTouched={!!touched[name]} error={errors[name]?.toString()} />
    </div>
  );
};
