import React, { FC, useMemo } from "react";
import {
  MachineAnalysisPreviewBlockLegalInfo,
  MachineAnalysisPreviewBlockPersonalInfo,
  MachineAnalysisPreviewBlockSentencingInfo,
  MachineAnalysisPreviewBlockMitigatingFactors,
  MachineAnalysisPreviewBlockInmateData,
  MachineAnalysisPreviewBlockSecurityDesignation,
  MachineAnalysisPreviewFooter,
} from "@containers/Cases/components/CaseViewContainer/MachineAnalysis/MachineAnalysisPreview";
import { Box, Divider, Stack } from "@chakra-ui/react";
import { CaseFormView } from "@containers/Cases/components/CaseViewContainer";

import { generateFormInitialValues } from "../MachineAnalysis.utils";

interface MachineAnalysisPreviewModalPagesProps {
  caseObjUpdatedValues: ReturnType<typeof generateFormInitialValues>;
  page: number;
}

const MachineAnalysisPreviewModalPages: FC<MachineAnalysisPreviewModalPagesProps> = ({
  caseObjUpdatedValues,
  page = 1,
}) => {
  return useMemo(() => {
    switch (page) {
      case 1:
        return (
          <Box h="100%" overflowY="scroll">
            <MachineAnalysisPreviewBlockPersonalInfo
              title="01 Personal Info"
              date_of_birth={caseObjUpdatedValues.date_of_birth || "-"}
              age={caseObjUpdatedValues.age || "-"}
              sex={caseObjUpdatedValues.sex || "-"}
              race={caseObjUpdatedValues.race || "-"}
              educationLevel={caseObjUpdatedValues.education_level || "-"}
              city={caseObjUpdatedValues.city || "-"}
              state={caseObjUpdatedValues.state || "-"}
            />
            <MachineAnalysisPreviewBlockLegalInfo
              title="02 Legal Info"
              usm_number={caseObjUpdatedValues.usm_number || "-"}
              district={caseObjUpdatedValues.district || "-"}
              docket_number={caseObjUpdatedValues.docket_number || "-"}
              judge={caseObjUpdatedValues.judge || "-"}
              total_offense_level={caseObjUpdatedValues.total_offense_level || "-"}
              criminalHistoryPoints={caseObjUpdatedValues.criminal_history_points || "-"}
              criminalHistoryTotalPoints={caseObjUpdatedValues.criminal_history_total_points || "-"}
              criminalHistoryCategory={caseObjUpdatedValues.criminal_history_category || "-"}
              countsOfConviction={caseObjUpdatedValues.criminal_history_info.length || "-"}
              statusOfConviction={caseObjUpdatedValues.criminal_history_info || []}
              plea={caseObjUpdatedValues.plea || "-"}
              total={caseObjUpdatedValues.total || "-"}
              securityClassification={caseObjUpdatedValues.security_classification || "-"}
              remarks={caseObjUpdatedValues.remarks || "-"}
              {...{ "5k1": caseObjUpdatedValues["5k1"] || "-" }}
            />
          </Box>
        );
      case 2:
        return (
          <Box h="100%" overflowY="scroll">
            <MachineAnalysisPreviewBlockSentencingInfo
              title="03 Sentencing Info"
              publicSafetyFactors={caseObjUpdatedValues.public_safety_factors || "-"}
              typeOfPrison={caseObjUpdatedValues.type_of_prison || "-"}
              generalRecidivism={caseObjUpdatedValues.general_recidivism || "-"}
              firstStepActEligible={caseObjUpdatedValues.first_step_act_eligible || "-"}
              secondStepActEligible={caseObjUpdatedValues.second_step_act_eligible || "-"}
              riskOfViolenceRecidivism={caseObjUpdatedValues.risk_of_violence_recidivism || "-"}
              initialPatternScore={caseObjUpdatedValues.initial_pattern_score || "-"}
              recidivism={caseObjUpdatedValues.recidivism || "-"}
            />
            <MachineAnalysisPreviewBlockMitigatingFactors
              title="04 Mitigating Factors"
              mentalHealthNotes={caseObjUpdatedValues.mental_health_notes || "-"}
              programRecommendations={caseObjUpdatedValues.program_recommendations || "-"}
              drugProgram={caseObjUpdatedValues.drug_program || "-"}
              substanceAbuse={caseObjUpdatedValues.substance_abuse || "-"}
            />
            <MachineAnalysisPreviewBlockInmateData
              title="05 Inmate Load Data"
              firstName={caseObjUpdatedValues.first_name || "-"}
              lastName={caseObjUpdatedValues.last_name || "-"}
              middleName={caseObjUpdatedValues.middle_name || "-"}
              suffix={caseObjUpdatedValues.suffix || "-"}
              race={caseObjUpdatedValues.race || "-"}
              sex={caseObjUpdatedValues.sex || "-"}
              usmNumber={caseObjUpdatedValues.usm_number || "-"}
              fbiNumber={caseObjUpdatedValues.fbi_number || "-"}
              ssn={caseObjUpdatedValues.ssn || "-"}
              ethnicOrigin={caseObjUpdatedValues.ethnic_origin || "-"}
              dateOfBirth={caseObjUpdatedValues.date_of_birth || "-"}
              offenseSentence={caseObjUpdatedValues.offense_sentence || "-"}
            />
          </Box>
        );
      case 3:
        return (
          <Box h="100%" overflowY="scroll">
            <MachineAnalysisPreviewBlockInmateData
              stateOfBirth={caseObjUpdatedValues.state_of_birth || "-"}
              countryOfBirth={caseObjUpdatedValues.country_of_birth || "-"}
              citizenship={caseObjUpdatedValues.citizenship || "-"}
              street={caseObjUpdatedValues.street || "-"}
              city={caseObjUpdatedValues.city || "-"}
              state={caseObjUpdatedValues.state || "-"}
              zip={caseObjUpdatedValues.zip || "-"}
              foreignCountry={caseObjUpdatedValues.foreign_country || "-"}
              height={caseObjUpdatedValues.height || "-"}
              weight={caseObjUpdatedValues.weight || "-"}
              hairColor={caseObjUpdatedValues.hair_color || "-"}
              eyesColor={caseObjUpdatedValues.eyes_color || "-"}
              arsAssignment={caseObjUpdatedValues.ars_assignment || "-"}
            />
            <MachineAnalysisPreviewBlockSecurityDesignation
              title="06 Security Designation Data"
              judge={caseObjUpdatedValues.judge || "-"}
              recFacility={caseObjUpdatedValues.rec_facility || "-"}
              recProgram={caseObjUpdatedValues.rec_program || "-"}
              usmOffice={caseObjUpdatedValues.usm_office || "-"}
              voluntarySurrenderStatus={caseObjUpdatedValues.voluntary_surrender_status || "-"}
              VSDate={caseObjUpdatedValues.vs_date || "-"}
              VSLocation={caseObjUpdatedValues.vs_location || "-"}
              monthToRelease={caseObjUpdatedValues.month_to_release || "-"}
              levelOfSeverity={caseObjUpdatedValues.level_of_severity || "-"}
              criminalHistoryPoints={caseObjUpdatedValues.criminal_history_points || "-"}
              criminalHistoryTotalPoints={caseObjUpdatedValues.criminal_history_total_points || "-"}
              sourceOfDocDate={caseObjUpdatedValues.source_doc_date || "-"}
              sourceOfDoc={caseObjUpdatedValues.source_of_doc || "-"}
            />
          </Box>
        );
      case 4:
        return (
          <Stack divider={<Divider />} spacing={9} h="100%" overflowY="scroll">
            <MachineAnalysisPreviewBlockSecurityDesignation
              historyOfViolence={caseObjUpdatedValues.history_of_violence || "-"}
              historyOfEscape={caseObjUpdatedValues.history_of_escape || "-"}
              typeOfDetainer={caseObjUpdatedValues.type_of_detainer || "-"}
              age={caseObjUpdatedValues.age || "-"}
              educationLevel={caseObjUpdatedValues.education_level || "-"}
              highestGrade={caseObjUpdatedValues.highest_grade || "-"}
              substanceAbuse={caseObjUpdatedValues.substance_abuse || "-"}
              total={caseObjUpdatedValues.total || "-"}
              remarks={caseObjUpdatedValues.remarks || "-"}
              omdtReferral={caseObjUpdatedValues.omdt_referral || "-"}
              publicSafetyFactors={caseObjUpdatedValues.public_safety_factors || "-"}
            />
            <MachineAnalysisPreviewFooter />
          </Stack>
        );
      case 5:
        return (
          <Box
            h="100%"
            overflowY="scroll"
            sx={{
              ".caseFormHeadContainer": {
                fontSize: "12px",
              },
              ".caseFormCeilContainer": {
                fontSize: "8",
              },
            }}
          >
            <CaseFormView caseObj={caseObjUpdatedValues} columnsRatio={2} />
          </Box>
        );
      default:
        return null;
    }
  }, [caseObjUpdatedValues, page]);
};

export default MachineAnalysisPreviewModalPages;
