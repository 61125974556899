import { createAction, createAsyncAction } from "typesafe-actions";
import {
  CaseListFilter,
  CreateEditResponse,
  PaginatedResponse,
  ResponseError,
  WithCallback,
  WithLoaderFlag,
} from "@shared/interfaces";
import { CaseModel } from "@shared/models";

import { CasesActionTypes } from "./constants";
import { CreateCaseDto } from "../interface";
import { CasesReportStatusDtoInterface } from "../interface/CasesReportStatusDto.interface";

export const getCasesList = createAsyncAction(
  CasesActionTypes.GET_CASES_LIST,
  CasesActionTypes.GET_CASES_LIST_SUCCESS,
  CasesActionTypes.GET_CASES_LIST_FAILURE,
)<WithLoaderFlag<CaseListFilter>, PaginatedResponse<CaseModel>, ResponseError>();

export const createCase = createAsyncAction(
  CasesActionTypes.CREATE_CASE,
  CasesActionTypes.CREATE_CASE_SUCCESS,
  CasesActionTypes.CREATE_CASE_FAILURE,
)<WithCallback<CreateCaseDto, CaseModel>, CreateEditResponse<CaseModel>, ResponseError>();

export const deleteCase = createAsyncAction(
  CasesActionTypes.DELETE_CASE,
  CasesActionTypes.DELETE_CASE_SUCCESS,
  CasesActionTypes.DELETE_CASE_FAILURE,
)<WithCallback<{ caseId: number }, undefined>, undefined, ResponseError>();

export const getCase = createAsyncAction(
  CasesActionTypes.GET_CASE,
  CasesActionTypes.GET_CASE_SUCCESS,
  CasesActionTypes.GET_CASE_FAILURE,
)<WithLoaderFlag<{ caseId: number }>, CaseModel | null, ResponseError>();

export const getCaseData = createAsyncAction(
  CasesActionTypes.GET_CASE_DATA,
  CasesActionTypes.GET_CASE_DATA_SUCCESS,
  CasesActionTypes.GET_CASE_DATA_FAILURE,
)<WithCallback<{ caseId: number }, CaseModel>, CaseModel, ResponseError>();

export const restartProcessingCase = createAsyncAction(
  CasesActionTypes.RESTART_PROCESSING_CASE,
  CasesActionTypes.RESTART_PROCESSING_CASE_SUCCESS,
  CasesActionTypes.RESTART_PROCESSING_CASE_FAILURE,
)<number, CreateEditResponse<CaseModel>, ResponseError>();

export const updateCaseReportStatus = createAsyncAction(
  CasesActionTypes.UPDATE_CASE_REPORT_STATUS,
  CasesActionTypes.UPDATE_CASE_REPORT_STATUS_SUCCESS,
  CasesActionTypes.UPDATE_CASE_REPORT_STATUS_FAILURE,
)<
  WithCallback<CasesReportStatusDtoInterface, CasesReportStatusDtoInterface>,
  CasesReportStatusDtoInterface,
  ResponseError
>();

export const getCaseFileSignedUrl = createAsyncAction(
  CasesActionTypes.GET_CASE_FILE_SIGNED_URL,
  CasesActionTypes.GET_CASE_FILE_SIGNED_URL_SUCCESS,
  CasesActionTypes.GET_CASE_FILE_SIGNED_URL_FAILURE,
)<number, number, ResponseError>();

export const payForCase = createAsyncAction(
  CasesActionTypes.PAY_FOR_CASE,
  CasesActionTypes.PAY_FOR_CASE_SUCCESS,
  CasesActionTypes.PAY_FOR_CASE_FAILURE,
)<number, undefined, ResponseError>();

export const updateCase = createAsyncAction(
  CasesActionTypes.UPDATE_CASE,
  CasesActionTypes.UPDATE_CASE_SUCCESS,
  CasesActionTypes.UPDATE_CASE_FAILURE,
)<WithCallback<Partial<CaseModel>, CaseModel>, CreateEditResponse<CaseModel>, ResponseError>();

export const setFilter = createAction(CasesActionTypes.SET_FILTER)<CaseListFilter | null>();
export const clearCasesList = createAction(CasesActionTypes.CLEAR_CASES_LIST)();
export const setProcessingCaseId = createAction(CasesActionTypes.SET_PROCESSING_CASE_ID)<number | void>();
