import { MenuItem } from "@shared/interfaces";
import { ReactComponent as MainPageIcon } from "@assets/files/images/icons/mainPageIcon.svg";
import { ReactComponent as UsersIcon } from "@assets/files/images/icons/usersIcon.svg";
import { ReactComponent as BillingIcon } from "@assets/files/images/icons/billingIcon.svg";
import { NameOfRoutes } from "@shared/constants/routes";
import { goToBillingPortal } from "@containers/Auth/store/actions";

import { store } from "../../index";

const fieldKeysSecuredForAdmin = ["users"];

export const SIDEBAR_ITEMS_ADMIN: MenuItem[] = [
  { name: "home", path: NameOfRoutes.CASES_LIST, icon: <MainPageIcon /> },
  { name: "users", path: NameOfRoutes.USERS, icon: <UsersIcon /> },
];

export const SIDEBAR_ITEMS_USER: MenuItem[] = [
  ...SIDEBAR_ITEMS_ADMIN.filter((item) => !fieldKeysSecuredForAdmin.includes(item.name)),
  {
    name: "billing",
    path: "billing",
    icon: <BillingIcon />,
    onClick: (event) => {
      event.preventDefault();
      const {
        auth: { user },
      } = store.getState();
      if (user) {
        const { id } = user;
        store.dispatch(goToBillingPortal({ id, returnUrl: location.href }));
      }
    },
  },
];
