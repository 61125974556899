import { Box, Flex, Text } from "@chakra-ui/react";
import { CopyTextButton } from "@shared/components/CopyTextButton";
import { FC } from "react";

interface UserInfoProps {
  name: string;
  email: string;
}

const UserInfo: FC<UserInfoProps> = ({ name, email }) => {
  return (
    <Box>
      <Text>{name}</Text>
      <Flex alignItems="center" gap={2}>
        <Text fontSize={12} color="brand.emptyStateText">
          {email || "-"}
        </Text>
        <CopyTextButton text={email || ""} successText="The email was copied" onClick={(e) => e.stopPropagation()} />
      </Flex>
    </Box>
  );
};

export default UserInfo;
